<template>
  <div>
    <VueFaqAccordion
      :items="list"
      fontColor="silver"
      activeColor="#FFFFFF"
      borderColor="transparent"
      questionProperty="name"
      answerProperty="id"
      v-slot="itemData"
      :initialQuestionIndex="0"
      :class="customClasses"
      ref="accordionCategorized"
    >
      <AccordionSimple :list="itemData.list" :customClasses="childCustomClasses" />
    </VueFaqAccordion>
  </div>
</template>
<script>
/*
 * DOC:
 * >> Expected List Structure:
 *    - TBD - (Make it generic via dynamic props)
 */

import VueFaqAccordion from "vue-faq-accordion";
import AccordionSimple from "@/components/Accordion/Simple";

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    firstItemOpened: {
      type: Boolean,
      default: true
    },
    customClasses: {
      type: String,
      default: ""
    },
    childCustomClasses: {
      type: String,
      default: ""
    }
  },
  components: {
    AccordionSimple,
    VueFaqAccordion
  },
  mounted() {
    console.log(["LIST", this.list]);
  }
};
</script>
<style lang="scss">
#faq-search-container {
  .md-field label {
    font-style: inherit !important;
  }
}
</style>

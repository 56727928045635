<template>
  <keep-alive>
    <div class="n8-dialog-container" :class="dialogClassname">
      <div class="overlay" @click="closeDialog"></div>
      <div role="dialog" aria-modal="true" class="n8-dialog">
        <div class="dialog-header">
          <div class="dialog-header-icons">
            <button
              aria-label="close"
              type="button"
              tabindex="-1"
              class="dialog-header-icon dialog-header-close link"
              @click="closeDialog"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="dialog-content">
          <slot />
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogClassname() {
      return this.show ? "show" : "";
    },
  },
  methods: {
    closeDialog() {
      this.$store.dispatch("general/SET_MODALSHOW", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.n8-dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.4s;
  z-index: -1;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  &.show {
    opacity: 1;
    z-index: 1000;
  }

  &.video-modal-fullscreen {
    .n8-dialog {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      display: block;
    }
    .dialog-header {
      display: none;
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.n8-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  max-height: 90%;
  transform: scale(1);
  width: 800px;
  max-width: 90vw;
  z-index: 1001;
  .dialog-header {
    background: transparent;
    position: absolute;
    border: none;
    right: 0;
    z-index: 10;
  }
  .dialog-header-icons button {
    border: none;
    background: none;
    padding: 10px 15px;
    font-size: 20px;
  }
  .dialog-content {
    background: #ffffff;
    color: #333333;
    overflow-y: auto;
  }
}
</style>

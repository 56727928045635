<template>
  <md-tabs class="tab-container show-detail-tabs" md-alignment="centered" ref="showDetailMobileTab">
    <md-tab id="tab-detail-description" md-label="DESCRIPTION" :md-dynamic-height="true">
      <description-section
        :detail="detail"
        :is-payment-form-active="isPaymentFormActive"
        @closeFormSection="closeFormSection($event)" />
      <div v-if="similarShows.length" class="similar-show-container">
        <div class="row">
          <div class="col-12">
            <h5 class="sm-text-center text-white">Similar Shows</h5>
            <Slider :list="similarShows" :type="'similar-shows'" />
          </div>
        </div>
      </div>
    </md-tab>
    <md-tab id="tab-detail-venues" md-label="VENUES" v-if="nowShowingItem">
      <div class="row px-4 py-4">
        <div class="col-12">
          <now-showing :item="nowShowingItem" v-if="nowShowingItem" />
        </div>
        <div class="underline-dash-gold my-4"></div>
        <div class="col-lg-8">
          <next-showing
            :items="nextShowingItems"
            :videoId="detail.id"
            item-classname="col-12"
            v-if="nextShowingItems"
          />
        </div>
      </div>
    </md-tab>
  </md-tabs>
</template>

<script>
import DescriptionSection from "./DescriptionSection";
import NowShowing from "@/components/NowShowing";
import NextShowing from "@/components/NextShowing";
import Slider from "@/components/slider";

export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    nextShowingItems: {
      type: Array,
      default: () => []
    },
    nowShowingItem: {
      type: Object,
      default: () => {}
    },
    isPaymentFormActive: {
      type: Boolean,
      default: false
    },
    similarShows: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DescriptionSection,
    NowShowing,
    NextShowing,
    Slider
  },
  methods: {
    async closeFormSection(arg1) {
      await this.$emit("closeFormSection", arg1);
    }
  }
};
</script>

import axios from "@/axios-instance";

const videoServiceReqHeaders = {
  "X-Algolia-Application-Id": process.env.VUE_APP_ALGOLIA_APP_ID,
  "X-Algolia-API-Key": process.env.VUE_APP_ALGOLIA_SEARCH_KEY
};

const search = {
  namespaced: true,
  state: {
    result: [],
    movies: [],
    venues: [],
    searchKey: "",
    searchFilters: {},
    searchFiltersVenue: {}
  },
  actions: {
    SEARCH({ dispatch, getters }) {
      const searchKey = getters.getSearchKey;
      //const searchFilters = getters.getSearchFilters;
      //const searchFiltersVenue = getters.getSearchFiltersVenue;
      
      const url = "https://" + process.env.VUE_APP_ALGOLIA_APP_ID + "-dsn.algolia.net/1/indexes/" + process.env.VUE_APP_ALGOLIA_MAIN_INDEX_NAME + "/query";
      const body = {
        "params": "query=" + encodeURIComponent(searchKey)
      };
      dispatch("general/SET_DATALOADING", true, { root: true });
      
      axios
        .post(url, body, { headers: videoServiceReqHeaders })
        .then((resp) => {
          dispatch("SET_ALL_RESULT", resp);
          dispatch("general/SET_DATALOADING", false, { root: true });
        })
        .catch((error) => {
          dispatch("general/SET_DATALOADING", false, { root: true });
          console.log(["JN","search/SEARCH","err",error]);
        });
    },
    GET_ALL_VIDEOS({ dispatch }) {
      dispatch("showDetails/GET_SHOWS");
      /*
      return new Promise((resolve, reject) => {
        axios.get("/shows.json").then((resp) => {
          const res = resp.data;
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })*/
    },
    SET_ALL_RESULT({ commit }, params) {
      commit("setAllResult", params.data);
    },
    SET_ALL_VIDEOS({ commit }, params) {
      commit("setAllVideos", params.data);
    },
    GET_ALL_VENUES() {
      /*
      return new Promise((resolve, reject) => {
        axios.get("/venues.json").then((resp) => {
          const res = resp.data;
          resolve(res);
        }).catch(err => {
          reject(err);
        })
      })
      */
    },
    SET_ALL_VENUES({ commit }, params) {
      commit("setVenues", params.data);
    },
    SET_SEARCH_KEY({ commit }, params) {
      commit("setSearchKey", params.searchKey);
    },
    SET_SEARCH_FILTERS({ commit }, searchFilters) {
      commit("setSearchFilters", searchFilters);
    },
    SET_SEARCH_FILTERS_VENUE({ commit }, searchFiltersVenue) {
      commit("setSearchFiltersVenue", searchFiltersVenue);
    }
  },
  mutations: {
    setAllResult(state, payload) {
      state.result = payload;
    },
    setAllVideos(state, payload) {
      state.movies = payload;
    },
    setVenues(state, payload) {
      state.venues = payload;
    },
    setSearchKey(state, payload) {
      state.searchKey = payload;
    },
    setSearchFilters(state, payload) {
      state.searchFilters = payload;
    },
    setSearchFiltersVenue(state, payload) {
      state.searchFiltersVenue = payload;
    }
  },
  getters: {
    getAllResult(state) {
      return state.result;
    },
    getAllVideos(state) {
      return state.movies;
    },
    getAllVenues(state) {
      return state.venues;
    },
    getSearchKey(state) {
      return state.searchKey;
    },
    getSearchFilters(state) {
      return state.searchFilters;
    },
    getSearchFiltersVenue(state) {
      return state.searchFiltersVenue;
    }
  }
}

export default search;
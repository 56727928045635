var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"deleteaccount-container"},[_vm._m(0),_c('validation-observer',{ref:"deleteAccForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:invalid && dirty & changed ? 'md-invalid' : ''},[_c('label',[_vm._v("Email address *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"text","placeholder":"Email address","autocomplete":"off"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:invalid && dirty & changed ? 'md-invalid' : '',attrs:{"md-toggle-password":false}},[_c('label',[_vm._v("Password *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"password","placeholder":"Password","autocomplete":"new-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('MdButton',{staticClass:"n8-btn light-theme md-raised",class:invalid ? "disabled" : "",on:{"click":_vm.submit}},[_c('span',{staticClass:"btn-label"},[_vm._v("Delete account")])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"description"},[_vm._v("Please enter your email address and password to continue deleting your account. "),_c('br'),_vm._v("Warning: "),_c('b',[_vm._v("This action is not reversible. Your purchase history will be lost.")])])}]

export { render, staticRenderFns }
<template>
  <div class="signup-container" :class="fromSignupPage ? 'bg-signup' : ''">
    <div class="panel-switch-signup">
      <h1 class="mb-4 font-weight-bold" v-if="fromSignupPage">Sign Up</h1>
      <div v-else class="text-left info-title font-weight-bold">
        <span>Sign Up</span>
      </div>
      <div>
        <validation-observer ref="signupFrm" #default="{ invalid }">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <ValidationProvider
                  name="First name"
                  rules="required"
                  v-slot="{ errors, invalid, dirty, changed }"
                >
                  <md-field :class="errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''">
                    <label for="input-firstName">First Name*</label>
                    <md-input
                      type="text"
                      class="form-control n8-text"
                      name="input-firstName"
                      placeholder="First name"
                      v-model="firstName"
                    />
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <ValidationProvider
                  name="Last name"
                  rules="required"
                  v-slot="{ errors, invalid, dirty, changed }"
                >
                  <md-field :class="errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''">
                    <label for="input-lastName">Last Name*</label>
                    <md-input
                      type="text"
                      class="form-control n8-text"
                      name="input-lastName"
                      placeholder="Last name"
                      v-model="lastName"
                    />
                    <span class="md-error">{{ errors[0] }}</span>
                  </md-field>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="form-group">
            <ValidationProvider
              name="Email address"
              rules="required|email"
              v-slot="{ errors, invalid, dirty, changed }"
            >
              <md-field :class="errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''">
                <label for="input-email">Email address*</label>
                <md-input
                  type="email"
                  class="form-control n8-text"
                  name="input-email"
                  aria-describedby="emailHelp"
                  placeholder="Email address"
                  v-model="email"
                />
                <span class="md-error">{{ errors[0] }}</span>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <ValidationProvider
              name="Password"
              rules="required|minPassword:8"
              v-slot="{ errors, invalid, dirty, changed }"
            >
              <md-field
                :md-toggle-password="false"
                :class="errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''"
              >
                <label for="input-password">Password*</label>
                <md-input
                  type="password"
                  class="form-control n8-text"
                  name="input-password"
                  aria-describedby="emailHelp"
                  placeholder="Password"
                  v-model="password"
                />
                <span class="md-error">{{ errors[0] }}</span>
              </md-field>
            </ValidationProvider>
          </div>
          <!-- TODO: REMOVE 
          <div class="form-group">
            <ValidationProvider name="Country" rules="required" v-slot="{ errors, invalid }">
              <md-field :class="invalid ? 'md-invalid' : ''">
                <label>Country*</label>
                <md-select
                  v-model="country"
                  placeholder="Select your country"
                  @md-selected="getSelectedCountryCode"
                >
                  <md-option disabled value="">Please Select</md-option>
                  <md-option
                    v-for="(item, index) in countries"
                    v-bind:key="index"
                    :value="item.code"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
                <span class="md-error">{{ errors[0] }}</span>
              </md-field>
            </ValidationProvider>
          </div>
          -->
          <MdButton
            class="n8-btn gold-theme n8-btn-black md-raised mt-4"
            :class="invalid || loading ? `disabled` : ``"
            @click="signup"
            :disabled="loading"
          >
            <span class="btn-label">{{ loading ? "loading..." : "Sign Up" }}</span>
          </MdButton>
        </validation-observer>
        <span class="md-error error" v-if="errorMsg">{{ errorMsg }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "@/axios-instance";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { min } from "vee-validate/dist/rules";
import { mapState } from "vuex";

extend("minPassword", {
  ...min,
  message: "Minimum password length is 8",
});
export default {
  name: "SignupForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      password: null,
      email: null,
      errorMsg: null,
      message: null,
      notificationType: null,
      country: null,
    };
  },
  props: {
    fromSignupPage: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      loading: state => state.general.dataLoading,
    }),
  },
  methods: {
    async signup() {
      try {
        const success = await this.$refs.signupFrm.validate();
        if (success) {
          let data = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
            role: "members",
            country: this.countryName,
          };
          console.log(data);
          const resSignup = await this.$store.dispatch("auth/signUp", data);
          if (resSignup.response) {
            await this.createActivecampaingUser(resSignup.response.cognitoUserId);
            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "success",
              message: "Success. Please check your email.",
            });
          }
          this.$emit("closePopup", true);
        }
      } catch (err) {
        console.log("error: ", err);
        this.errorMsg = err.response.message;
        this.$store.dispatch("general/SET_DATALOADING", false, {
          root: true,
        });
        setTimeout(() => {
          this.errorMsg = null;
        }, 5000);
        this.$emit("closePopup", true);
      }
    },
    async createActivecampaingUser(userId) {
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        country: this.countryName,
      };
      // create AC Contact
      this.$store
        .dispatch("activeCampaign/CREATE_ACTIVE_CAMPAIGN_CONTACT", payload)
        .then(r => {
          console.log(r);
          const data = r.data;
          const contactId = data[0] === undefined ? data.subscriber_id.toString() : data[0].id;
          this.saveUserToDynamo(userId, contactId);
        })
        .catch(err => {
          this.saveUserToDynamo(userId);
          console.log(err);
        });
    },
    async saveUserToDynamo(userId, contactId = null) {
      let payload = {
        id: userId,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        status: "UNCONFIRMED",
        role: "members",
        fullName: `${this.firstName} ${this.lastName}`,
        // country: this.countryJsonStringify,
        contactId: contactId,
      };
      await this.$store.dispatch("auth/saveUserToDynamoDb", payload).then(res => {
        if (res.data) {
          this.$store.dispatch({
            type: "homepage/SET_MEMBERSHIP_DISPLAY",
            data: "",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";
.error {
  color: #ff6161;
  font-size: 12px;
  opacity: 1 !important;
  transform: translateZ(0) !important;
}
h1 {
  font-family: "Opensans-bold";
  font-size: 22px;
}
.bg-signup {
  border-radius: 25px;
  padding: 30px;
  max-width: 410px;
  background-color: #24211f;
  border: 1px #ae8138 solid;
  margin: 0 auto;
}
.info-title {
  font-size: 22px;
}
</style>

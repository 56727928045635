<template>
  <div class="venues-detail-container">
    <small-loader class="d-block mx-auto" v-if="dataLoading" />
    <div class="video-detail" v-else>
      <div v-if="isVenueDataSet">
        <div class="full-width-mobile-container mb-5">
          <hero-slider
            :items="heroSlider"
            :name="title"
            :location="location"
            @hook:mounted="updateTopDarkBgCalc"
          ></hero-slider>
          <div class="desktop" ref="detailVenueContainer">
            <venue-description :data="venueData" />

            <div class="n8-row">
              <div class="col-12 mb-2">
                <now-showing-slider v-if="sliderReady" :list="nowShowings"></now-showing-slider>
              </div>
            </div>
          </div>

          <div class="mobile">
            <md-tabs class="tab-container mb-4" md-alignment="centered">
              <md-tab id="tab-description" md-label="Description">
                <venue-description :data="venueData" :isMobileView="true" />

                <div class="description-container text-white">
                  <div data-v-78582e00="" class="underline-dash-gold my-4"></div>
                  <Similar title="Venues" :items="relatedVenues" v-if="relatedVenues.length" />
                </div>
              </md-tab>
              <md-tab id="tab-now-showing" md-label="Now Showing">
                <div class="row">
                  <div class="col-12 mb-2">
                    <now-showing-slider
                      v-if="sliderReady"
                      :list="nowShowings"
                      :isMobileView="true"
                    ></now-showing-slider>
                  </div>
                </div>
              </md-tab>
            </md-tabs>
          </div>
        </div>

        <div class="desktop now-showing-container py-0">
          <div class="row">
            <div class="col-12 mb-4">
              <Similar title="Venues" :items="relatedVenues" v-if="relatedVenues.length" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import MetaInfo from "@/mixins/MetaInfo";
import HeroSlider from "@/components/Hero";
import SmallLoader from "@/components/SmallLoader";
import Similar from "@/components/Similar";
import VenueDescription from "./partials/description";
import NowShowingSlider from "@/components/NowShowingSlider";

import UrlHelper from "@/helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  mixins: [MetaInfo],
  components: {
    HeroSlider,
    SmallLoader,
    Similar,
    VenueDescription,
    NowShowingSlider
  },
  data() {
    return {
      id: null,
      venueData: {}
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.$store
      .dispatch("venues/GET_VENUE", this.id)
      .then(resp => {
        this.venueData = resp;
        this.setMetaTags({
          pageTitle: this.venueData.name,
          metaTitle: this.venueData.metaTitle,
          metaDescription: this.venueData.metaDescription
        });
      })
      .catch(err => {
        const error = err.response.data;
        // console.log("error", error.errors[0]);
        if (error.errors[0].status === 404) this.$router.push("/notfound");
      });
    this.$store.dispatch("venues/GET_NOW_SHOWING", this.id);
  },
  computed: {
    ...mapState({
      relatedVenues: state =>
        state.venues.relatedVenues.map(venue => {
          const img =
            venue.mediaFiles && venue.mediaFiles.find(o => o.key === "thumbnail_small")
              ? venue.mediaFiles.find(o => o.key === "thumbnail_small").value
              : null;
          return {
            id: venue.id,
            img: img,
            title: venue.name,
            subtitle: `${venue.city}, ${venue.country}`,
            bottomText: null,
            routeToDetail: "venuesDetail"
          };
        }),
      dataLoading: state => state.general.dataLoading,
      nowShowings: state =>
        state.venues.nowShowings.map(nowShowing => {
          return {
            id: nowShowing.id,
            artworks: nowShowing.artworks,
            name: nowShowing.title,
            img: nowShowing.img,
            price: nowShowing.price,
            type: "now-showing",
            slug: nowShowing.slug
          };
        })
    }),
    ...mapGetters(["venues/getVenues"]),
    isVenueDataSet() {
      return Object.keys(this.venueData).length !== 0;
    },
    heroSlider: function() {
      if (Object.keys(this.venueData).length === 0 && this.venueData.constructor === Object) return;
      return this.venueData.mediaFiles
        ? this.venueData.mediaFiles.filter(o => o.key === "hero")
        : [];
    },
    title() {
      return this.venueData.name;
    },
    description() {
      return this.venueData.description;
    },
    metaDescription() {
      return this.venueData.metaDescription || "";
    },
    metaTitle() {
      return this.venueData.metaTitle || "";
    },
    location: function() {
      return `${this.venueData.city}, ${this.venueData.country}`;
    },
    sliderReady: function() {
      return Object.keys(this.nowShowings).length !== 0;
    }
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    }),
    updateTopDarkBgCalc() {
      this.$store.dispatch(
        "ui/SET_TOP_DARK_BG_HEIGHT",
        this.$refs.detailVenueContainer.getBoundingClientRect().top + 150
      );
    },
    getImage(path) {
      return urlHelper.resolveCdnPath(path);
    }
  },
  destroyed() {
    this.$store.dispatch("ui/RESET_TOP_DARK_BG_HEIGHT");
    this.$store.dispatch("venues/SET_VENUE", {});
  }
};
</script>

<style lang="scss" scoped>
h4 {
  font-family: "Playfair-bold", Georgia;
}
.subheading {
  margin-bottom: 40px;
}
.description-container {
  p {
    font-size: 14px;
    line-height: 19.6px;
    white-space: pre-line;
  }
}
</style>

<template>
  <div class="maintenance">
    <div class="row">
      <div class="col-12">
        <maintenance-logo></maintenance-logo>
      </div>
    </div>
  </div>
</template>

<script>
import MaintenanceLogo from "@/components/maintenance/MaintenanceLogo";
import MetaInfo from "@/mixins/MetaInfo";
export default {
  mixins: [MetaInfo],
  data() {
    return {
      meta: [{ description: "" }],
    };
  },
  components: {
    MaintenanceLogo,
  },
  computed: {
    title() {
      return "maintenance";
    },
    description() {
      return "maintenance";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/sass/base/variables";
.maintenance {
  h1 {
    font-family: "Opensans-regular";
    font-size: 18px;
    color: $gold;
    margin-top: 60px;
  }
  p {
  }
  .maintenance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 80vh;
  }
}
</style>

<template>
  <div class="homepage">
    <div v-if="pageLoading">
      <small-loader class="d-block mx-auto" />
    </div>
    <div v-else>
      <div class="row features">
        <div class="col-12">
          <horizontal-collapse
            :highlight-list="highlightList"
            @hook:mounted="updateTopDarkBgCalc"
          />
        </div>
      </div>
      <md-tabs
        class="tab-container"
        md-alignment="centered"
        ref="homeTabContainer"
        @md-changed="tabChanged"
      >
        <md-tab id="tab-shows" ref="tabShows" md-label="NOW SHOWING">
          <div class="row" v-if="nowShowingList.length > 1">
            <div
              class="col-12 mb-4 category-container"
              v-for="section in nowShowingList"
              :key="section.name"
            >
              <h2 class="subheading text-center gold mb-3">
                {{ section.name }}
              </h2>
              <div class="underline-dash-gold mb-4"></div>
              <Slider :list="section.items" />

              <div class="text-center">
                <router-link to="/search" class="notify-btn btn mb-5">
                  <i class="icon fas fa-eye mr-2"></i> View All
                </router-link>
              </div>
            </div>
          </div>

          <div class="row" v-else>
            <div
              class="col-12 mb-4 category-container"
              v-for="section in skeletonData"
              :key="section.name"
            >
              <h2 class="subheading text-center gold mb-3">
                {{ section.name }}
              </h2>
              <div class="underline-dash-gold mb-4"></div>
              <Slider :list="section.items" />

              <div class="text-center">
                <router-link to="/search" class="notify-btn btn mb-5">
                  <i class="icon fas fa-eye mr-2"></i> View All
                </router-link>
              </div>
            </div>
          </div>
        </md-tab>
        <md-tab id="tab-venues" ref="tabVenues" md-label="VENUES">
          <div class="row">
            <div class="col-12 mb-4 category-container">
              <h2 class="subheading text-center gold mb-3">Latest Venues</h2>
              <div class="underline-dash-gold mb-4"></div>
              <venues-slider :list="latestVenues" />

              <div class="text-center venue-viewall">
                <router-link to="/venues" class="notify-btn btn mb-5">
                  <i class="icon fas fa-eye mr-2"></i> View All
                </router-link>
              </div>
            </div>
            <div class="col-12 mb-4 category-container">
              <h2 class="subheading text-center gold mb-3">Nearest Venues</h2>
              <div class="underline-dash-gold mb-4"></div>
              <venues-slider :list="nearestVenues" v-if="nearestVenues.length" />
            </div>
          </div>
        </md-tab>
      </md-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import HorizontalCollapse from "@/components/horizontalCollapse";
import Slider from "@/components/slider";
import VenuesSlider from "@/components/slider/VenuesSlider";
import MetaInfo from "@/mixins/MetaInfo";
import SmallLoader from "@/components/SmallLoader";

export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "Welcome to Homepage",
      meta: [{ description: "this is meta description" }],
      skeletonData: [{ name: "...loading...", items: [] }],
      currentActiveTabId: "tab-shows"
    };
  },
  created() {
    this.$store.dispatch("homepage/GET_NOW_SHOWING");
    this.$store.dispatch("homepage/GET_HIGHLIGHT");
    this.$store.dispatch("venues/GET_VENUES");
  },
  mounted() {
    this.$store.dispatch("showDetails/SET_MAINVIDEO", null);

    this.$getLocation()
      .then(coordinates => {
        this.$store.dispatch("venues/GET_NEAREST_VENUES", coordinates);
      })
      .catch(() => {
        console.log("geolocation disabled");
      });

    this.addEventListeners();
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    }),
    updateTopDarkBgCalc() {
      this.$store.dispatch("ui/SET_TOP_DARK_BG_HEIGHT", 900);
    },
    tabChanged(tabId) {
      this.currentActiveTabId = tabId;
      this.resizeTabHeight(tabId);
    },
    resizeTabHeight(tabId) {
      let activeTabHeight = 0;

      if (this.$refs.tabShows && tabId === "tab-shows") {
        activeTabHeight = this.$refs.tabShows.$el.clientHeight;
      } else if (this.$refs.tabVenues && tabId === "tab-venues") {
        activeTabHeight = this.$refs.tabVenues.$el.clientHeight;
      }

      if (activeTabHeight > 0 && this.$refs.homeTabContainer)
        this.$refs.homeTabContainer.$el.style = `height: ${activeTabHeight}px!important;`;
    },
    onScreenResize() {
      this.resizeTabHeight(this.currentActiveTabId);
    },
    addEventListeners() {
      window.addEventListener("resize", this.onScreenResize);
    }
  },
  destroyed() {
    this.$store.dispatch("ui/RESET_TOP_DARK_BG_HEIGHT");
    window.removeEventListener("resize", this.resizeTabHeight);
  },
  computed: {
    ...mapState({
      nowShowingList: state => state.homepage.nowShowingList,
      highlightList: state => state.homepage.highlightList,
      latestVenues: state => state.venues.venues,
      nearestVenues: state => state.venues.nearestVenues,
      dataLoading: state => state.general.dataLoading
    }),
    ...mapGetters(["homepage/getNowShowing", "homepage/getHighlight"]),
    pageLoading() {
      return (
        //this.highlightList.length == 0 ||
        this.nowShowingList.length == 1 && this.latestVenues.length == 0
      );
    }
  },
  watch: {
    nowShowingList() {
      setTimeout(() => {
        this.resizeTabHeight("tab-shows");
      }, 300);
    }
  },
  components: {
    HorizontalCollapse,
    Slider,
    VenuesSlider,
    SmallLoader
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";
.features {
  @include breakpoint("large") {
    background-color: $black;
  }
}
h4 {
  font-family: "Playfair-bold";
  text-align: center;
  color: $gold;
}
.category-container {
  &:first-of-type {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .venue-viewall {
    display: none;
  }
}
</style>

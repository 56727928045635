<template>
  <div class="contactinformation-container">
    <h2 class="title pb-3">Contact Information</h2>
    <div class="mt-4">
      <div class="form-group">
        <ValidationProvider
          name="First Name"
          rules="required"
          v-slot="{ errors, invalid, dirty, changed }"
        >
          <md-field :class="invalid && dirty & changed ? 'md-invalid' : ''">
            <label>First Name</label>
            <md-input
              type="text"
              class="form-control n8-text"
              v-model="firstName"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider
          name="Last Name"
          rules="required"
          v-slot="{ errors, invalid, dirty, changed }"
        >
          <md-field :class="invalid && dirty & changed ? 'md-invalid' : ''">
            <label>Last Name</label>
            <md-input
              type="text"
              class="form-control n8-text"
              v-model="lastName"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="{ errors, invalid, dirty, changed }"
        >
          <md-field :class="invalid && dirty & changed ? 'md-invalid' : ''">
            <label>Email Address</label>
            <md-input
              type="text"
              class="form-control n8-text"
              v-model="email"
              disabled
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="form-group contact-info-datepicker">
        <md-datepicker
          v-model="dateOfBirth"
          :md-immediately="true"
          :md-override-native="true"
          placeholder="DOB"
          :md-disabled-dates="disabledDates"
          ><label>Date of birth</label></md-datepicker
        >
      </div>
      <div class="btn-container pt-4">
        <MdButton
          class="n8-btn n8-btn-gray light-theme md-raised"
          @click="changePassword"
        >
          <span class="btn-label">Change password</span>
        </MdButton>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
export default {
  name: "ContactInformation",
  components: {
    ValidationProvider,
  },
  data() {
    // let dateFormat = this.$material.locale.dateFormat || "yyyy-MM-dd";
    //let now = new moment();
    return {
      firstName: null,
      lastName: null,
      email: null,
      dateOfBirth: null,
      dateFormat: "dd/MM/yyyy",
      invalidMandatoryField: false,
      disabledDates: (date) => {
        if (moment(date).isAfter(moment())) return date;
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "auth/getUserDetails",
    }),
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.mapValue(newVal);
        }
      },
    },
    firstName(newVal) {
      this.invalidMandatoryField = !newVal ? true : false;
    },
    lastName(newVal) {
      this.invalidMandatoryField = !newVal ? true : false;
    },
    email(newVal) {
      this.invalidMandatoryField = !newVal ? true : false;
    },
    // dateOfBirth(newVal) {
    //   if (newVal) {
    //     console.log("date selected", newval);
    //     return moment(newVal, "dd/MM/yyyy");
    //   }
    // },
  },
  mounted() {
    this.$material.locale.dateFormat = this.dateFormat;
  },
  methods: {
    changePassword() {
      this.$router.push("/change-password");
    },
    mapValue(data) {
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.email = data.email;
      this.dateOfBirth = data.dateOfBirth
        ? moment(data.dateOfBirth).toDate()
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

h2 {
  color: $gold;
  font-size: 32px;
  font-weight: bold;

  &.title {
    border-bottom: 0.5px solid #aaa4a1;
  }

  @include breakpoint("small") {
    font-family: "Opensans-regular";
    font-size: 22px;
    color: white;
  }
}
.contactinformation-container {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 40px;
  flex: 50%;
  margin-right: 2rem;

  label {
    letter-spacing: 0.996px;
    text-transform: uppercase;
  }

  @include breakpoint("small") {
    background: transparent;
    padding: 40px 22px;
  }
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .n8-btn {
    margin-top: 24px;
  }
  @media only screen and (min-width: 768px) {
    button {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .contactinformation-container {
    flex: 100%;
    margin-right: unset;
  }
}
</style>

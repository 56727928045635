<template>
  <div class="venue-page">
    <div class="row">
      <div class="col-12">
        <div class="heading-container">
          <h1 class="text-center">Venues</h1>
        </div>
        <div class="venue-container mt-5" ref="venueContainer">
          <small-loader class="d-block mx-auto" v-if="dataLoading" />
          <venues-component v-else :venues="listOfVenues"></venues-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MetaInfo from "@/mixins/MetaInfo";
import { mapState, mapActions } from "vuex";
import SmallLoader from "@/components/SmallLoader";
import venuesComponent from "@/components/venues";

export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "Venues",
      venues: []
    };
  },
  mounted() {
    this.$store.dispatch(
      "ui/SET_TOP_DARK_BG_HEIGHT",
      this.$refs.venueContainer.getBoundingClientRect().top - 25
    );
    this.$store.dispatch("venues/GET_VENUES");
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  computed: {
    ...mapState({
      listOfVenues: state => state.venues.venues,
      dataLoading: state => state.general.dataLoading
    })
  },
  destroyed() {
    this.$store.dispatch("ui/RESET_TOP_DARK_BG_HEIGHT");
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    })
  },
  components: {
    SmallLoader,
    venuesComponent
  }
};
</script>

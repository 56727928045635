var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"membership-container"},[_c('span',{staticClass:"text-left title font-weight-bold hide"},[_vm._v(_vm._s(_vm._f("membershipTitle")(_vm.membershipDisplay)))]),_c('div',{staticClass:"default-container",class:{
      hide: _vm.membershipDisplay != ''
    }},[_c('LoginOrSignup')],1),_c('div',{staticClass:"login-form-container",class:{
      hide: _vm.membershipDisplay != 'LOGIN'
    }},[_c('LoginForm',{attrs:{"from-login-page":false}}),_c('MdButton',{staticClass:"n8-btn n8-btn-transparent light-theme md-raised mt-3",on:{"click":function($event){return _vm.toggleDisplayState('')}}},[_c('span',{staticClass:"btn-label"},[_vm._v("Back")])])],1),_c('div',{staticClass:"signup-form-container",class:{
      hide: _vm.membershipDisplay != 'SIGNUP'
    }},[_c('SignupForm',{attrs:{"from-signup-page":false},on:{"closePopup":_vm.closeFormSection}}),_c('MdButton',{staticClass:"n8-btn n8-btn-transparent light-theme md-raised mt-3",on:{"click":function($event){return _vm.toggleDisplayState('')}}},[_c('span',{staticClass:"btn-label"},[_vm._v("Back")])])],1),_c('div',{staticClass:"forget-form-container",class:{
      hide: _vm.membershipDisplay != 'FORGET'
    }},[_c('ForgotPassword',{attrs:{"from-signup-page":false}})],1),_c('div',{staticClass:"payment-form-container",class:{
      hide: _vm.membershipDisplay != 'PAYMENT'
    }},[(_vm.isActive)?_c('PaymentForm',{attrs:{"hl-item":_vm.hlItem,"isFromDetailPage":_vm.isFromDetailPage},on:{"closeFormSection":function($event){return _vm.closeFormSection($event)}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
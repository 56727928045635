<template>
  <div class="movie-container">
    <div class="text-center" v-if="!shows"><small-loader></small-loader></div>

    <div v-if="origData.length > 0" class="filter-container">
      <br />
      <center>
        <div>
          <div :class="'search-btn' + (isOpen ? ' active' : '')">
            <button class="n8-btn" v-on:click="open">Sort and Filter</button>
            <a v-on:click="close" :class="'search-close' + (isOpen ? ' active' : '')">
              <i class="fa fa-times" style="font-size: 1.4em"></i>
            </a>
          </div>
          <div :class="'search-filter' + (isOpen ? ' active' : '')">
            <div class="search-filter-options row">
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="search-filter-option">
                  <div class="search-filter-option-title">SORT BY</div>
                  <div class="btn-group">
                    <button
                      v-for="(opt, index) in filter_options.sort_by"
                      v-bind:key="index"
                      type="button"
                      :class="checkSelected('sort_by', opt.value)"
                      v-on:click="setFilterSingle('sort_by', 'sort_by_' + opt.value, opt.value)"
                    >
                      {{ opt.label }}
                    </button>
                  </div>
                </div>
                <div class="search-filter-option date" v-if="filters.sort_by === 'date'">
                  <div class="search-filter-option-title">DATE</div>
                  <div class="btn-group">
                    <button
                      v-for="(opt, index) in filter_options.date"
                      v-bind:key="index"
                      type="button"
                      :class="
                        typeof filters['date'] != 'undefined' && filters['date'] == opt.value
                          ? 'btn n8-btn filter-active'
                          : 'btn n8-btn'
                      "
                      v-on:click="setFilterSingle('date', 'date_' + opt.value, opt.value)"
                    >
                      {{ opt.label }}
                    </button>
                  </div>
                </div>
                <div
                  class="search-filter-option popularity"
                  v-if="filters.sort_by === 'popularity'"
                >
                  <div class="search-filter-option-title">POPULARITY</div>
                  <div class="btn-group">
                    <button
                      v-for="(opt, index) in filter_options.popularity"
                      v-bind:key="index"
                      type="button"
                      :class="
                        typeof filters['popularity'] != 'undefined' &&
                        filters['popularity'] == opt.value
                          ? 'btn n8-btn filter-active'
                          : 'btn n8-btn'
                      "
                      v-on:click="
                        setFilterSingle('popularity', 'popularity_' + opt.value, opt.value)
                      "
                    >
                      {{ opt.label }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="search-filter-option">
                  <div class="search-filter-option-title">DURATION</div>
                  <div class="btn-group btn-durations-3cols">
                    <button
                      v-for="(opt, index) in filter_options.duration"
                      v-bind:key="index"
                      type="button"
                      :class="
                        typeof filters['duration'] != 'undefined' &&
                        filters['duration'] == opt.value
                          ? 'btn n8-btn filter-active'
                          : 'btn n8-btn'
                      "
                      v-on:click="setFilterSingle('duration', 'duration_' + opt.value, opt.value)"
                    >
                      {{ opt.label }}
                    </button>
                  </div>
                  <div class="btn-durations-1col">
                    <button
                      v-for="(opt, index) in filter_options.duration"
                      v-bind:key="index"
                      type="button"
                      :class="
                        typeof filters['duration'] != 'undefined' &&
                        filters['duration'] == opt.value
                          ? 'btn n8-btn filter-active'
                          : 'btn n8-btn'
                      "
                      v-on:click="setFilterSingle('duration', 'duration_' + opt.value, opt.value)"
                    >
                      {{ opt.label }}
                    </button>
                  </div>
                </div>
                <div class="search-filter-option">
                  <div class="search-filter-option-title">PRICE</div>
                  <div class="btn-group">
                    <button
                      v-for="(opt, index) in filter_options.price"
                      v-bind:key="index"
                      type="button"
                      :class="
                        typeof filters['price'] != 'undefined' && filters['price'] == opt.value
                          ? 'btn n8-btn filter-active'
                          : 'btn n8-btn'
                      "
                      v-on:click="setFilterSingle('price', 'price_' + opt.value, opt.value)"
                    >
                      {{ opt.label }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="search-filter-option">
                  <div class="search-filter-option-title">GENRE</div>
                  <div class="btn-grids">
                    <div ref="genreOptions" class="btn-grid">
                      <button
                        v-for="(genre, index) in filter_options.genres"
                        v-bind:key="index"
                        v-on:click="setFilterMultiple('genre', genre.value)"
                        type="button"
                        :class="checkGenreClass(genre.extra_class, 'genre', genre.value)"
                      >
                        {{ genre.label }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr style="background-color: silver; margin-bottom: 30px" />
            <div class="search-filter-buttons">
              <button class="n8-btn search-filter-button-undo" v-on:click="undo">
                <i class="fa fa-undo"></i> Undo Changes
              </button>
              <button class="n8-btn search-filter-button-reset" v-on:click="reset">
                <i class="fa fa-sync"></i> Reset All
              </button>
              <button class="n8-btn search-filter-button-apply" v-on:click="apply">
                <i class="fa fa-arrow-down"></i> Apply
              </button>
            </div>
          </div>
        </div>
      </center>
      <br />
    </div>

    <div class="movie-list row" v-if="filteredData.length > 0">
      <div
        v-for="(item, index) in filteredData"
        :key="index"
        v-on:mouseover="itemHover(index)"
        v-on:mouseleave="itemLeave()"
        class="slider-container col-md-3 col-xs-12 m-0 mb-2"
      >
        <router-link :to="'/shows/' + item.slug" class="info">
          <div
            class="slider-item"
            :class="selected == index ? `active` : ``"
            :style="{ backgroundImage: 'url(' + makeImage(item) + ')' }"
          >
            <!--
            <img
              v-if="
                item.artworks &&
                item.artworks.slider_p &&
                item.artworks.slider_p[0]
              "
              :src="getImage(item.artworks.slider_p[0])"
              class="slider-img"
            />
            <img
              v-else
              src="@/assets/images/slider_default.png"
              class="slider-img"
            />
            -->
            <div class="slider-info">
              <div class="price-info">
                <span class="currency">{{ currency }}</span>
                <span class="price">{{ includeTax(item.price) | priceFormat }}</span>
              </div>

              <span class="info"> <i class="icon fas fa-info-circle"></i> Info </span>
            </div>
          </div>
        </router-link>
        <div class="separator-container">
          <div class="separator"><div class="solid"></div></div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 class="text-center p-5">
        <span class="not-found">No search results found.</span>
      </h3>
    </div>
  </div>
</template>

<script>
import { ResizeObserver } from "resize-observer";
import { mapState } from "vuex";
import smallLoader from "@/components/SmallLoader.vue";
import UrlHelper from "../../helper/url-helper";
import throttle from "lodash.throttle";
import getSymbolFromCurrency from "currency-symbol-map";
import * as _ from "lodash";

const urlHelper = new UrlHelper();

const resizeObserver = new ResizeObserver(
  throttle(entries => {
    for (let entry of entries) {
      const entryWidth = entry.contentBoxSize
        ? entry.contentBoxSize[0].inlineSize
        : entry.contentRect.width;

      if (entryWidth <= 250) {
        entry.target.classList.remove("two-cols");
        entry.target.classList.remove("three-cols");
        entry.target.classList.add("one-col");
      } else if (entryWidth <= 356) {
        entry.target.classList.remove("three-cols");
        entry.target.classList.remove("one-col");
        entry.target.classList.add("two-cols");
      } else {
        entry.target.classList.remove("one-col");
        entry.target.classList.remove("two-cols");
        entry.target.classList.add("three-cols");
      }
    }
  }),
  200
);

export default {
  props: {
    shows: Array,
    searchBy: String
  },
  components: {
    smallLoader
  },
  data() {
    const genreList = [
      { value: "Ballet", label: "Ballet" },
      { value: "Cabaret", label: "Cabaret" },
      { value: "Comedy", label: "Comedy" },
      { value: "Concert", label: "Concert" },
      { value: "Dance", label: "Dance" },
      { value: "Contemporary Dance", label: "Contemporary Dance" },
      { value: "Fringe", label: "Fringe" },
      { value: "Hip Hop Theatre", label: "Hip Hop Theatre" },
      { value: "Musical", label: "Musical" },
      { value: "Opera", label: "Opera" },
      { value: "Pantomime", label: "Pantomime" },
      { value: "Physical Theatre", label: "Physical Theatre" },
      { value: "Play", label: "Play" },
      { value: "Puppet", label: "Puppet" },
      { value: "Festival", label: "Festival" },
      { value: "Dance Theatre", label: "Dance Theatre" },
      { value: "Family Theatre", label: "Family Theatre" }
    ];
    var data = {
      isOpen: false,
      selected: -1,
      origData: [],
      showsCopy: [],
      filters: {
        sort_by: "",
        date: "latest",
        duration: "",
        popularity: "most",
        price: "",
        genre: {}
      },
      filter_options: {
        sort_by: [
          { value: "date", label: "Date" },
          { value: "popularity", label: "Popularity" }
        ],
        date: [
          { value: "oldest", label: "Oldest" },
          { value: "latest", label: "Latest" }
        ],
        popularity: [
          { value: "most", label: "Most Popular" },
          { value: "least", label: "Least Popular" }
        ],
        duration: [
          { value: "under_30", label: "Under 30min" },
          { value: "30_to_60", label: "30min to 1h" },
          { value: "above_60", label: "Above 1hr" }
        ],
        price: [
          { value: "paid", label: "Paid" },
          { value: "free", label: "Free" }
        ],
        genres: genreList
      }
    };

    genreList.map((genre, index) => {
      console.log(["genre", index, genre.value]);
      data["filters"]["genre"][genre.value] = false;
    });

    return data;
  },
  computed: {
    ...mapState({
      searchKey: state => state.search.searchKey,
      searchFilters: state => state.search.searchFilters
    }),
    filteredData() {
      const filtered = this.showsCopy.filter(
        item =>
          typeof item.meta == "undefined" ||
          (typeof item.meta != "undefined" && !(item.meta.toLowerCase() == "venuedetails"))
      );
      console.log("filteredData MOVIES", filtered);
      return filtered;
    },
    currency() {
      return getSymbolFromCurrency("GBP");
    }
  },
  mounted() {
    this.origData = this.shows;
    this.showsCopy = this.shows;
  },
  updated() {
    // if(this.$refs.genreOptions instanceof Element)
    if (this.$refs.genreOptions) {
      resizeObserver.observe(this.$refs.genreOptions);
    }
  },
  methods: {
    getImgUrl(id) {
      var images = require.context("@/assets/images/mock/", false, /\.png$/);
      return images("./" + id + ".png");
    },
    getImage(path) {
      return urlHelper.resolveCdnPath(path);
    },
    itemHover(index) {
      console.log("item click", index);
      this.selected = index;
    },
    itemLeave() {
      this.selected = -1;
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    makeImage(item) {
      if (item.artworks && item.artworks.slider_p && item.artworks.slider_p[0]) {
        return urlHelper.resolveCdnPath(item.artworks.slider_p[0]);
      }
      // return "@/assets/images/slider_default.png";
      return "/img/thumbnail-default.png";
    },
    apply() {
      this.$store.dispatch("search/SET_SEARCH_FILTERS", this.filters).then(() => {
        var temp = JSON.parse(JSON.stringify(this.origData));

        // filter by genre
        var genreFilter = [];
        Object.keys(this.searchFilters.genre).forEach(key => {
          var value = this.searchFilters.genre[key];
          console.log("checkGenreFilter", key, value);
          if (value) {
            genreFilter.push(key);
          }
        });
        if (genreFilter.length > 0) {
          temp = temp.filter(item => genreFilter.includes(item.genre));
        }

        // filter by price
        if (this.searchFilters.price != "") {
          if (this.searchFilters.price == "free") {
            temp = temp.filter(item => parseFloat(item.price) <= 0);
          } else {
            temp = temp.filter(item => parseFloat(item.price) > 0);
          }
        }

        // filter by duration
        if (this.searchFilters.duration != "") {
          switch (this.searchFilters.duration) {
            case "under_30":
              temp = temp.filter(item => parseFloat(item.duration) < 30);
              break;
            case "30_to_60":
              temp = temp.filter(
                item => parseFloat(item.duration) >= 30 && parseFloat(item.duration) <= 60
              );
              break;
            case "above_60":
              temp = temp.filter(item => {
                console.log(["duration", item.duration]);
                return parseFloat(item.duration) > 60;
              });
              break;
          }
        }

        /*
          // filter by date
          if (this.searchFilters.date != "") {
            var thresholdDate = new Date();
            thresholdDate.setMonth(thresholdDate.getMonth() - 1);
            console.log(["threshold", thresholdDate]);
            if (this.searchFilters.date == "oldest") {
              temp = temp.filter((item) => {
                var dt = new Date(Date.parse(item.createdDate));
                return dt <= thresholdDate;
              });
            } else {
              temp = temp.filter((item) => {
                var dt = new Date(Date.parse(item.createdDate));
                return dt >= thresholdDate;
              });
            }
          }
          */

        // sort
        if (this.searchFilters.sort_by != "") {
          switch (this.searchFilters.sort_by) {
            case "date":
              temp = temp.sort((a, b) => {
                var a_dt = new Date(Date.parse(a.createdDate));
                var b_dt = new Date(Date.parse(b.createdDate));
                if (a_dt > b_dt) {
                  return this.searchFilters.date == "latest" ? -1 : 1;
                } else {
                  return this.searchFilters.date == "latest" ? 1 : -1;
                }
              });
              break;
            case "popularity":
              temp = temp.sort((a, b) => {
                if (a.totalViews > b.totalViews) {
                  return this.searchFilters.popularity == "least" ? 1 : -1;
                } else {
                  return this.searchFilters.popularity == "least" ? -1 : 1;
                }
              });
              break;
            case "distance":
              // TBD
              break;
          }
        }

        this.showsCopy = temp;
      });
    },
    reset() {
      this.filters = {
        sort_by: "",
        date: "latest",
        duration: "",
        popularity: "most",
        price: "",
        genre: {}
      };
      this.showsCopy = this.origData;
    },
    undo() {
      this.filters = {
        sort_by: "",
        date: "latest",
        duration: "",
        popularity: "most",
        price: "",
        genre: {}
      };
      this.showsCopy = this.origData;
    },
    setFilterSingle(key_filter, key_ui, value) {
      this.filters[key_filter] = value;
    },
    setFilterMultiple(key_filter, value) {
      let newObject = _.assign({}, this.filters[key_filter]);
      newObject[value] = !newObject[value];
      this.filters[key_filter] = newObject;
    },
    checkSelected(key_filter, value) {
      if (typeof this.filters[key_filter] != "undefined" && this.filters[key_filter] == value) {
        return "btn n8-btn filter-active";
      } else {
        return "btn n8-btn";
      }
    },
    checkGenreClass(extra_class, key_filter, value) {
      var strClass = "btn n8-btn";
      if (typeof extra_class != "undefined") {
        strClass += " " + extra_class;
      }
      if (
        typeof this.filters[key_filter][value] != "undefined" &&
        this.filters[key_filter][value]
      ) {
        strClass += " filter-active";
      }
      return strClass;
    }
  },
  watch: {
    shows: function(newdata) {
      this.origData = newdata;
      this.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.not-found {
  font-family: "Playfair-bold";
  color: $gold;
}
.slider-img {
  height: 450px !important;
  background-color: #000 !important;
  margin-bottom: 20px;
}

.filter-container {
  @include breakpoint("large") {
    padding: 40px;
  }
}

.searchTxt {
  font-family: "Playfair-bold";
  color: $gold;
}
.search-btn {
  padding: 20px 0;
  margin: 0 20px;
  position: relative;
  &.active {
    background-color: #000;
    border-left: 1px solid $gold;
    border-right: 1px solid $gold;
    border-top: 1px solid $gold;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom: 25px;
    > button {
      border: 0 !important;
    }
  }
  > .search-close {
    position: absolute;
    top: 20px;
    right: 30px;
    display: none;
    &.active {
      display: block;
    }
  }
}
.search-filter {
  background-color: #000;
  padding: 10px 20px;
  margin: 0 20px;
  border-left: 1px solid $gold;
  border-right: 1px solid $gold;
  border-bottom: 1px solid $gold;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: none;
  &.active {
    display: block;
  }
}
.search-filter-buttons {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  > button {
    float: right;
    font-size: 1em;
    font-weight: bold;
    width: 200px;
    margin-left: 30px;

    @include breakpoint("small") {
      width: 100% !important;
      margin-bottom: 10px;
      font-size: 0.8em !important;
    }
  }
}
.search-filter-button-apply {
  background-color: $gold;
  width: 170px !important;

  @include breakpoint("large") {
    width: 300px !important;
  }
}
.search-filter-button-reset {
  background-color: silver;
  border: 0;
  color: #000;

  @include breakpoint("medium") {
    width: 170px !important;
  }
}
.search-filter-button-undo {
  @include breakpoint("medium") {
    width: 170px !important;
  }
}
.search-filter-option {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  color: $gold;
  > .btn-group {
    width: 100%;
    > button {
      font-size: 0.8em !important;
      padding: 10px 5px !important;
    }
    > button:active,
    > button:focus {
      background-color: transparent !important;
    }
    > button.filter-active {
      background-color: $gold !important;
    }
  }
  > .search-filter-option-title {
    text-align: left;
    font-size: 0.7em;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
.btn-grids > .btn-grid {
  margin-top: -1px;
}
.btn-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;

  > button {
    margin-left: -1px;
    width: 120px;
    min-width: 120px;
    font-size: 11px;
    padding: 10px;
    flex-grow: 1;
    flex: 1 0 33%;
    border-radius: 0;
  }

  > button:active,
  > button:focus {
    background-color: transparent !important;
  }

  > button.filter-active {
    background-color: $gold !important;
  }
}

.one-col {
  > button:first-child {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }
  > button:last-child {
    border-bottom-right-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
}

.two-cols {
  > button:first-child {
    border-top-left-radius: 30px !important;
  }
  > button:nth-child(2) {
    border-top-right-radius: 30px !important;
  }
  > button:last-child {
    border-bottom-right-radius: 30px !important;
  }
  > button:nth-last-child(1) {
    border-bottom-left-radius: 30px !important;
  }
}

.three-cols {
  > button:first-child {
    border-top-left-radius: 30px !important;
  }
  > button:nth-child(3) {
    border-top-right-radius: 30px !important;
  }
  > button:last-child {
    border-bottom-right-radius: 30px !important;
  }
  > button:nth-last-child(2) {
    border-bottom-left-radius: 30px !important;
  }
}

button.filter-active {
  background-color: $gold !important;
}

.slider-container {
  .slider-item {
    height: 360px;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    position: relative;

    .price {
      font-size: 39px;
      font-family: "Playfair-regular", Georgia;
      flex-direction: column;
      text-align: center;
      height: 60px;
      white-space: nowrap;
    }

    .currency {
      font-size: 27px;
      margin-right: 5px;
      margin-left: -5px; // center balancing
      color: #fff;
    }

    .slider-info {
      opacity: 1;

      .info {
        transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        will-change: opacity;
        opacity: 0;
        font-size: 1em;
      }
    }

    &.active {
      .slider-info {
        .info {
          opacity: 1;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .separator-container {
      margin: 20px 0;
    }
  }
}

@media (max-width: 375px) {
  .btn-durations-1col {
    display: block !important;

    > button {
      width: 100%;
      margin-bottom: 10px;
      font-size: 0.8em !important;
      padding: 10px 5px !important;
    }
  }

  .btn-group.btn-durations-3cols {
    display: none;
  }
}

@media only screen and (min-width: 376px) {
  .btn-durations-1col {
    display: none !important;
  }
}
</style>

import axios from "@/axios-instance";

const play = {
  namespaced: true,
  state: {
    eligibleSessions: [],
    watchingSessions: [],
    watchingPingerInterval: null,
    currVideoIdBeingPlayed: null,
    isPinging: false
  },
  mutations: {
    setEligibleSession(state, payload) {
      // console.debug("*** set eligible sessions...", JSON.stringify(payload));
      if (state.eligibleSessions.length === 0 || payload === null) {
        state.eligibleSessions = payload || [];
      } else {
        payload.forEach(session => {
          const idx = state.eligibleSessions.findIndex(
            v => v.videoId === session.videoId && v.venueId === session.venueId
          );

          if (idx > -1) {
            state.eligibleSessions[idx] = session;
          } else {
            state.eligibleSessions.push(session);
          }
        });
      }

      // console.debug("*** eligible sessions updated", JSON.stringify(state.eligibleSessions));
    },
    updateWatchingSessions(state, session) {
      let idx = state.watchingSessions.findIndex(v => v.videoId === session.videoId);

      if (idx > -1) {
        Object.assign(state.watchingSessions[idx], session);
      } else {
        state.watchingSessions.unshift(session);
      }

      idx = state.eligibleSessions.findIndex(v => v.videoId === session.videoId);

      if (idx > -1) {
        state.eligibleSessions[idx].expiryTimestamp = session.watchExpiryTimestamp;
      } else {
        state.eligibleSessions.push({
          expiryTimestamp: session.watchExpiryTimestamp - 60000,
          venueId: session.venueId,
          videoId: session.videoId,
          showExpiration: session.showExpiration
        });
      }
    },
    removeWatchingSession(state, videoId) {
      if (videoId === "none") {
        state.watchingSessions = [];
      } else {
        const idx = state.watchingSessions.findIndex(x => x.videoId === videoId);
        state.watchingSessions.splice(idx, 1);
      }
    },
    setPingerInterval(state, interval) {
      state.watchingPingerInterval = interval;
      state.isPinging = true;
    },
    clearPingerInterval(state) {
      clearInterval(state.watchingPingerInterval);
    },
    setCurrVideoIdBeingPlayed(state, videoId) {
      state.currVideoIdBeingPlayed = videoId;
    },
    resetState(state) {
      state.eligibleSessions = [];
      state.watchingSessions = [];
      state.watchingPingerInterval = null;
      state.currVideoIdBeingPlayed = null;
      state.isPinging = false;
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async GET_ELIGIBLE_SESSIONS({ commit, rootGetters }) {
      try {
        if (!rootGetters["auth/isLoggedIn"]) return;

        console.debug("*** getting eligible sessions...");
        const resp = await axios.get("/user/play/eligible-sessions");
        if (resp.status === 200) {
          commit("setEligibleSession", resp.data);
        }
      } catch (e) {
        commit("setEligibleSession", null);
        // console.error(e);
      }
    },
    async UPDATE_ELIGIBLE_SESSIONS({ commit, rootGetters }, payload) {
      try {
        if (!rootGetters["auth/isLoggedIn"]) return;

        console.debug("*** update eligible sessions...");

        commit("setEligibleSession", [payload]);

        const resp = await axios.get("/user/play/eligible-sessions");
        if (resp.status === 200) {
          commit("setEligibleSession", resp.data);
        }
      } catch (e) {
        commit("setEligibleSession", null);
        // console.error(e);
      }
    },
    async GET_WATCHING_SESSION({ commit, dispatch, rootGetters }, payload) {
      try {
        if (!rootGetters["auth/isLoggedIn"]) return;

        const { videoId } = payload;
        const resp = await axios.get(`/user/play/ws/${videoId}`);

        if (resp.status === 200) {
          const wsResp = resp.data;

          if (wsResp.status === "v") {
            commit("updateWatchingSessions", wsResp.session);
            await dispatch("showDetails/UPDATE_MAIN_VIDEO_URLS", wsResp.session, {
              root: true
            });
            commit("setCurrVideoIdBeingPlayed", videoId);
          } else if (wsResp.status === "asia") {
            dispatch(
              "general/SET_NOTIFICATION",
              {
                showNotif: true,
                type: "info",
                message:
                  "You are watching from another device or from Android/iOS app. Please stop the show on that device before you can play the show from this device."
              },
              { root: true }
            );
          } else {
            dispatch(
              "general/SET_NOTIFICATION",
              {
                showNotif: true,
                type: "info",
                message: "Sorry, your watching session has been expired"
              },
              { root: true }
            );
          }
          return wsResp;
        } else {
          return null;
        }
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    STOP_WATCHING_SESSSION({ commit, rootGetters }, payload) {
      try {
        if (!rootGetters["auth/isLoggedIn"]) return;

        const { videoId = "none" } = payload;
        axios.post(`/user/play/sws/${videoId}`);
        commit("removeWatchingSession", videoId);
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    STOP_PINGER({ commit, dispatch }, payload) {
      commit("clearPingerInterval");
      console.log("Stopping pinger, clearing pinger interval");
      dispatch("PLAY_WS_SEND_MESSAGE", payload, { root: true });
    },
    START_PINGER({ commit, dispatch, state, rootGetters }) {
      if (state.currVideoIdBeingPlayed === null) return;

      const pingPayload = {
        data: {
          dk: rootGetters["auth/getDeviceKey"],
          vid: state.currVideoIdBeingPlayed,
          uid: rootGetters["auth/getUserId"]
        },
        action: "ping"
      };

      if (state.isPinging) commit("clearPingerInterval");

      dispatch("PLAY_WS_SEND_MESSAGE", pingPayload, { root: true });

      const interval = setInterval(
        () => dispatch("PLAY_WS_SEND_MESSAGE", pingPayload, { root: true }),
        120 * 1000
      );

      commit("setPingerInterval", interval);
    },
    RESET_STATE({ commit }) {
      commit("resetState");
    }
  },
  getters: {
    checkEligibility: state => videoId => {
      return state.eligibleSessions.find(session =>
        session.videoId === videoId || videoId !== undefined
          ? session.videoId.substr(session.videoId.length - 4) ===
            videoId.substr(videoId.length - 4)
          : false
      );
    },
    isEligible: state => videoId => {
      // console.log("sessions", JSON.stringify(state.eligibleSessions));
      let session = state.eligibleSessions.find(session => session.videoId === videoId);

      if (!session) {
        const videoIdlast4Digits = videoId.substr(videoId.length - 4);
        session = state.eligibleSessions.find(
          session => session.videoId.substr(session.videoId.length - 4) === videoIdlast4Digits
        );
      }

      // console.log("session found", JSON.stringify(session));

      if (session) {
        return session && session.expiryTimestamp > Date.now();
      }

      return false;
    },
    eligibleSessions(state) {
      return state.eligibleSessions;
    }
  }
};

export default play;

<template>
  <section class="membership-container">
    <span class="text-left title font-weight-bold hide">{{
      membershipDisplay | membershipTitle
    }}</span>
    <div
      class="default-container"
      :class="{
        hide: membershipDisplay != ''
      }"
    >
      <LoginOrSignup></LoginOrSignup>
    </div>
    <div
      class="login-form-container"
      :class="{
        hide: membershipDisplay != 'LOGIN'
      }"
    >
      <LoginForm :from-login-page="false"></LoginForm>
      <MdButton
        class="n8-btn n8-btn-transparent light-theme md-raised mt-3"
        @click="toggleDisplayState('')"
      >
        <span class="btn-label">Back</span>
      </MdButton>
    </div>
    <div
      class="signup-form-container"
      :class="{
        hide: membershipDisplay != 'SIGNUP'
      }"
    >
      <SignupForm :from-signup-page="false" @closePopup="closeFormSection"></SignupForm>
      <MdButton
        class="n8-btn n8-btn-transparent light-theme md-raised mt-3"
        @click="toggleDisplayState('')"
      >
        <span class="btn-label">Back</span>
      </MdButton>
    </div>
    <div
      class="forget-form-container"
      :class="{
        hide: membershipDisplay != 'FORGET'
      }"
    >
      <ForgotPassword :from-signup-page="false"></ForgotPassword>
    </div>
    <div
      class="payment-form-container"
      :class="{
        hide: membershipDisplay != 'PAYMENT'
      }"
    >
      <PaymentForm
        :hl-item="hlItem"
        :isFromDetailPage="isFromDetailPage"
        v-if="isActive"
        @closeFormSection="closeFormSection($event)"
      ></PaymentForm>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import LoginForm from "./LoginForm";
import LoginOrSignup from "./LoginOrSignup";
import SignupForm from "./SignupForm";
import PaymentForm from "./PaymentForm";
import ForgotPassword from "./ForgotPassword";

export default {
  name: "MembershipForm",
  components: {
    LoginForm,
    LoginOrSignup,
    SignupForm,
    PaymentForm,
    ForgotPassword
  },
  data() {
    return {};
  },
  methods: {
    toggleDisplayState(selectedName) {
      this.$store.dispatch({
        type: "homepage/SET_MEMBERSHIP_DISPLAY",
        data: selectedName
      });
    },
    async closeFormSection(arg) {
      await this.$emit("closeFormSection", arg);
    }
  },
  computed: {
    ...mapState({
      membershipDisplay: state => state.homepage.membershipDisplay
    }),
    ...mapGetters(["auth/isLoggedIn"])
  },
  mounted() {
    console.log("membership mounted2", this["auth/isLoggedIn"]);
    if (this["auth/isLoggedIn"]) {
      this.toggleDisplayState("PAYMENT");
    } else {
      this.toggleDisplayState("");
    }
  },
  beforeMount() {},
  filters: {
    membershipTitle(val) {
      if (val === "LOGIN") return "Log In";
      else if (val === "SIGNUP") return "Sign Up";
    }
  },
  props: {
    hlItem: {
      type: Object,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isFromDetailPage: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>

import http from "@/axios-instance";
import orderByDistance from "geolib/es/orderByDistance";
import UrlHelper from "../../../helper/url-helper";

const videoServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_VIDEO_API_KEY,
};

const venueServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_VENUE_API_KEY
}

const venues = {
  namespaced: true,
  state: {
    venue: {},
    venues: [],
    relatedVenues: [],
    nowShowings: [],
    nearestVenues: [],
  },
  actions: {
    GET_VENUES({ commit, dispatch }) {
      // TODO: GET SHOW
      // FOR NOW, USING MOCK DATA
      // will change to this URL after the data is ready
      // if want to user local data change url to http://localhost:{your port}
      const url = "/venue";
      // /data/videos.json
      dispatch("general/SET_DATALOADING", true, { root: true });
      http
        .get(url, { headers: venueServiceReqHeaders })
        .then((resp) => {
          commit("setVenues", resp.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() =>
          dispatch("general/SET_DATALOADING", false, { root: true })
        );
    },
    GET_VENUE({ commit, dispatch }, id) {
      const url = `/venue/${id}`;
      console.log('venue data', url);
      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });
        http
          .get(url, { headers: venueServiceReqHeaders })
          .then((resp) => {
            let data = resp.data;
            const similarVenuesId = data.similarVenues
              ? data.similarVenues.slice(0, 3)
              : [];

            commit("setVenue", data);

            dispatch("SET_RELATED_VENUES", similarVenuesId);
            dispatch("general/SET_DATALOADING", false, { root: true });

            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          })
          .finally(() => {
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      });
    },
    GET_NEAREST_VENUES({ state, dispatch, commit }, coordinatesObj) {
      if (state.venues.length == 0) {
        dispatch("GET_VENUES");
      }

      const filteredVenues = state.venues.filter(
        (item) => item.latitude != null && item.longitude != null
      );

      const orderByNearest = orderByDistance(coordinatesObj, filteredVenues);
      console.log("NEAREST VENUES", orderByNearest);
      commit("setNearestVenues", orderByNearest);
    },
    // eslint-disable-next-line no-unused-vars
    async GET_VENUE_DETAIL({ commit }, id) {
      const url = `/venue/${id}`;
      let data;
      try {
        data = await http.get(url, { headers: venueServiceReqHeaders });
        return data.data;
      } catch (error) {
        console.log(error);
      }
    },
    SET_RELATED_VENUES({ commit }, relatedVenuesId) {
      let i = 0;
      const url = "/venue/";
      const relatedVenues = [];
      commit("setRelatedVenues", relatedVenues);
      let promises = [];
      for (i = 0; i < relatedVenuesId.length; i++) {
        promises.push(
          http
            .get(`${url}${relatedVenuesId[i]}`, { headers: venueServiceReqHeaders })
            .then((res) => {
              relatedVenues.push(res.data);
            })
            .catch((error) => {
              console.log(error);
            })
        );
      }

      Promise.all(promises).then(() => {
        const activeRelatedVenues = relatedVenues.filter(
          (item) => item.status == "active"
        );

        commit("setRelatedVenues", activeRelatedVenues);
      });
    },

    SET_VENUE({ commit }, payload) {
      commit("setVenue", payload);
    },
    SET_VENUES({ commit }, payload) {
      commit("setVenues", payload);
    },
    GET_NOW_SHOWING({ dispatch }, id) {
      const url = `/venue/now-showing/${id}`;
      http
        .get(url, { headers: venueServiceReqHeaders })
        .then((r) => {
          const data = r.data;
          const videoIds = data.map((id) => {
            return id.videoId;
          });
          dispatch("SET_NOW_SHOWING", videoIds);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SET_NOW_SHOWING({ commit }, videoIds) {
      console.log("video", videoIds);
      let i = 0;
      const url = "/video/";
      const nowShowing = [];
      let promises = [];
      for (i = 0; i < videoIds.length; i++) {
        promises.push(
          http
            .get(`${url}${videoIds[i]}`, { headers: videoServiceReqHeaders })
            .then((res) => {
              nowShowing.push(res.data);
            })
            .catch((error) => {
              console.log(error);
            })
        );
      }

      Promise.all(promises).then(() => {
        commit("setNowShowing", nowShowing);
      });
    },
  },
  mutations: {
    setVenue(state, payload) {
      if (payload && payload.mediaFiles) {
        const helper = new UrlHelper();
        for (let i = 0; i < payload.mediaFiles.length; i += 1) {
          payload.mediaFiles[i].value = helper.resolveCdnPath(
            payload.mediaFiles[i].value
          );
        }
      }
      state.venue = payload;
    },
    setVenues(state, payload) {
      const data = [];
      for (let i = 0; i < payload.length; i += 1) {
        if (payload[i].mediaFiles) {
          let findImage = payload[i].mediaFiles.find(
            (o) => o.key === "thumbnail_small"
          );
          payload[i].img = findImage ? findImage.value : "";
        }
        data.push(payload[i]);
      }
      state.venues = data;
    },
    setNearestVenues(state, payload) {
      state.nearestVenues = payload;
    },
    setRelatedVenues(state, payload) {
      state.relatedVenues = payload;
    },
    setNowShowing(state, payload) {
      state.nowShowings = payload;
    },
  },
  getters: {
    getVenue(state) {
      return state.venue;
    },
    getVenues(state) {
      return state.venues;
    },
    getRelatedVenues(state) {
      return state.relatedVenues;
    },
    getNowShowings(state) {
      return state.nowShowings;
    },
  },
};

export default venues;

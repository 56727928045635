<template>
  <div class="faq-page col-lg-8 col-md-10 col-sm-12 mx-auto">
    <div class="heading-container">
      <h1 class="gold">Frequently Asked Questions</h1>
      <div class="line"></div>
    </div>

    <br />
    <div id="faq-search-container" class="input-group mb-3">
      <md-field>
        <label>Search FAQ</label>
        <md-input class="form-control" v-on:change="keywordChange"></md-input>
        <i class="fas fa-search"></i>
      </md-field>
    </div>
    <br />
    <AccordionCategorized
      :list="this.faqsList"
      customClasses="accordion-big accordion-faq accordion-title-rounded accordion-content-no-indentation accordion-content-bold"
      childCustomClasses="accordion-title-underlined"
    />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AccordionCategorized from "@/components/Accordion/Categorized";
import MetaInfo from "@/mixins/MetaInfo";

export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "Frequently Asked Questions",
      meta: [{ description: "this is meta description" }],
      faqsListAll: [],
      faqsList: []
    };
  },
  mounted() {
    this.$store.dispatch("faq/GET_FAQS").then(() => {
      this.faqsListAll = this["faq/getFaqs"];
      this.faqsList = this["faq/getFaqs"];
    });
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    }),
    keywordChange(evt) {
      let keywords = evt.target.value;
      if (keywords.length > 1) {
        let result = [];
        for (var i = 0; i < this.faqsListAll.length; i++) {
          let listContent = this.faqsListAll[i].list.filter(
            x =>
              x.content.toLowerCase().includes(keywords.toLowerCase()) ||
              x.title.toLowerCase().includes(keywords.toLowerCase())
          );
          if (listContent.length > 0) {
            result.push({
              name: this.faqsListAll[i].name,
              list: listContent
            });
          }
        }
        this.faqsList = result;
      } else {
        this.faqsList = this.faqsListAll;
      }
      // console.log("result is", this.faqsList);
    }
  },
  computed: {
    ...mapState({
      dataLoading: state => state.general.dataLoading
    }),
    ...mapGetters(["faq/getFaqs"])
  },
  components: {
    AccordionCategorized
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.heading-container {
  position: relative;
  h1 {
    background-color: $dark-brown;
    text-transform: capitalize;
    position: relative;
    z-index: 2;
    @include breakpoint("large") {
      text-transform: uppercase;
      width: 320px;
    }
  }
  .line {
    width: 135px;
    position: relative;
    top: 5px;
    border-top: 1px solid $gold;
    z-index: 1;
    @include breakpoint("large") {
      top: -36px;
      width: 100%;
    }
  }
}
#faq-search-container {
  min-width: 250px;
  width: 30%;
  margin: 0 auto;
}
#faq-search-input,
#faq-search-input-icon {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gold;
  border-radius: 0;
  color: white;
  font-family: "Playfair-regular";
}
#faq-search-input-icon {
  color: $gold;
}
</style>

<template>
  <div class="listing-container">
    <md-table class="n8-table dekstop">
      <md-table-toolbar>
        <h2 class="title pb-3">Payment History</h2>
      </md-table-toolbar>

      <md-table-row>
        <md-table-head>Order ID</md-table-head>
        <md-table-head>Content Title</md-table-head>
        <md-table-head>Date of Purchase</md-table-head>
        <md-table-head>Purchase Expiry</md-table-head>
        <md-table-head>Watch Expiry</md-table-head>
        <md-table-head>Price</md-table-head>
        <md-table-head class="center">Action</md-table-head>
      </md-table-row>

      <md-table-row v-for="orderItem in orderHistory" :key="orderItem.id">
        <md-table-cell :data-id="orderItem.id">{{
          `#${orderItem.id.slice(-8)}`
        }}</md-table-cell>
        <md-table-cell>{{ orderItem.meta.product.name }}</md-table-cell>
        <md-table-cell>{{
          formatDate(orderItem.createdDateTime)
        }}</md-table-cell>
        <md-table-cell>{{
          formatDate(orderItem.validUntilDateTime, true)
        }}</md-table-cell>
        <md-table-cell>{{
          formatDate(orderItem.watchingExpiry || orderItem.validUntilDateTime, true)
        }}</md-table-cell>
        <md-table-cell>{{
          formatPrice(
            orderItem.meta.payment.currency,
            orderItem.meta.payment.totalPrice
          )
        }}</md-table-cell>
        <md-table-cell>
          <div class="btn-container">
            <MdButton
              class="n8-btn n8-btn-gray light-theme md-raised"
              @click="sendReceipt(orderItem.id)"
            >
              <span class="icon btn-mail">Send to email</span>
            </MdButton>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <div class="listing-mobile mobile">
      <h2 class="title pb-3">Payment History</h2>
      <div
        class="n8-flex-table"
        v-for="orderItem in orderHistory"
        :key="orderItem.id"
      >
        <div class="column details">
          <span>{{ `Order #${orderItem.id.slice(-8)}` }}</span>
          <span>
            {{ `Order Date: ${formatDate(orderItem.createdDateTime)}` }}</span
          >
          <span>
            {{
              `Total:
              ${formatPrice(
                orderItem.meta.payment.currency,
                orderItem.meta.payment.totalPrice
              )}`
            }}</span
          >
        </div>
        <div class="column">
          <MdButton
            class="n8-btn n8-btn-gray light-theme md-raised"
            @click="sendReceipt(orderItem.id)"
          >
            <span class="icon btn-mail">Send to email</span>
          </MdButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
  name: "PaymentHistory",
  data() {
    return {};
  },
  methods: {
    formatDate(date, includingTime = false) {
      if(includingTime) {
        return moment(date).format("DD/MMM/YYYY h:mm a");
      }

      return moment(date).format("DD/MMM/YYYY");
    },
    formatPrice(currency, price) {
      if (!currency) {
        currency = "GBP";
      }
      return `${getSymbolFromCurrency(currency)} ${price}`;
    },
    sendReceipt(orderId) {
      this.$store.dispatch("myDetails/SEND_ORDER_RECEIPT", orderId);
      // var win = window.open(url, "_blank");
      // win.focus();
    },
  },
  watch: {},
  props: {
    orderHistory: Array,
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
.title {
  border-bottom: 0.5px solid #aaa4a1;
  width: 100%;
}
h2 {
  color: $gold;
  font-size: 32px;
  font-weight: bold;
}
.n8-table {
  .n8-btn {
    .btn-mail {
      color: $vulcan;
    }
  }
}

.listing-mobile {
  .n8-flex-table {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      line-height: 1.3;
    }
  }
  .title {
    color: white;
  }
  .btn-mail {
    color: $vulcan;
  }
}

// Show hide Mobile - desktop
@media only screen and (max-width: 991px) {
  .listing-container {
    .dekstop {
      display: none;
    }
    .mobile {
      display: inherit;
    }
  }
}
.mobile {
  display: none;
}
</style>

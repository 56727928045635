<template>
  <div>
    <N8Dialog class="video-modal-fullscreen" :show="isDeferred" v-if="isDeferred">
      <DeferredPage @onPlayVideo="playDeferredVideo" />
    </N8Dialog>
    <!-- IF NOT, VUEX TELLS PLAYER TO BE LOADED -->
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import N8Dialog from "@/components/modal/ModalDialog";
import DeferredPage from "./partials/DeferredPage.vue";
import MetaInfo from "@/mixins/MetaInfo";

export default {
  mixins: [MetaInfo],
  components: {
    N8Dialog,
    DeferredPage
  },
  data() {
    return {
      meta: [{ description: "" }],
      id: null,
      isCheckEligibleFailed: false,
      epochNow: Date.now(),
      deeplinkUrl: null
    };
  },
  async created() {
    if (!this.$route.params.videoId) {
      this.$router.push({
        name: "showdetails",
        params: { id: new URL(window.location.href).pathname.split('/').pop() }
      });
      return;
    }

    this.id = this.$route.params.videoId;
    this.deeplinkUrl = this.$route.params.deeplinkUrl;

    if (this.$route.query.deferred && this.deeplinkUrl) {
      this.setVideoDeferred(true);
      window.open(this.deeplinkUrl, "_blank");
    } else {
      this.checkMainVideoJson();
    }
  },
  computed: {
    ...mapState({
      detail: state => state.showDetails.showData,
      dataLoading: state => state.general.dataLoading,
      isPlayerOpen: state => state.showDetails.playerOpen,
      similarShows: state =>
        state.showDetails.similarShows.slice(0, 4).map(detail => {
          return {
            id: detail.id,
            title: detail.title,
            artworks: detail.artworks,
            type: "similar_shows",
            price: detail.price,
            slug: detail.slug
          };
        }),
      mainVideoJson: state => state.showDetails.mainVideoJson,
      displayFormLogin: state => state.showDetails.displayFormLogin,
      isDeferred: state => state.showDetails.isDeferred,
    }),
    ...mapGetters({
      getShow: "showDetails/getShow",
      isLoggedIn: "auth/isLoggedIn",
      user: "auth/getUser",
      eligibleSession: "play/eligibleSessions"
    }),
    eligibleToWatch() {
      return this.isLoggedIn && this.isEligible;
    },
    session() {
      const session = this.$store.getters["play/checkEligibility"](
        this.id || this.$route.params.id
      );
      return session;
    },
    expiryTimestamp() {
      if (this.session) {
        return this.session.expiryTimestamp;
      }

      return 0;
    },
    isEligible() {
      const _isEligible = this.$store.getters["play/isEligible"](this.id || this.$route.params.id);
      // console.log("IsEligible", this.id || this.$route.params.id, _isEligible);
      return _isEligible;
    }
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS",
      setVideoDeferred: "showDetails/SET_VIDEO_DEFERRED"
    }),
    updateTopDarkBgCalc() {
      this.$store.dispatch(
        "ui/SET_TOP_DARK_BG_HEIGHT",
        this.$refs.sliderBackground ? this.$refs.sliderBackground.clientHeight + 270 : 500
      );
    },
    updateBottomDarkBgCalc() {
      //Show bottom dark bg
      if (this.similarShows && this.similarShows.length > 0)
        this.$store.dispatch(
          "ui/SET_BOTTOM_DARK_BG_HEIGHT",
          this.$refs.similarShowContainer ? this.$refs.similarShowContainer.clientHeight + 328 : 0
        );
    },
    checkEligibility: function(data) {
      this.$store.getters["play/checkEligibility"](data.id);
    },
    checkMainVideoJson() {
      if (
        this.mainVideoJson === null ||
        (this.mainVideoJson && this.mainVideoJson.threeDee == "undefined")
      ) {
        this.$router.push({
          name: "showdetails",
          params: { id: new URL(window.location.href).pathname.split('/').pop() }
        });
        return;
      } else {
        this.loadVideoData();
      }
    },
    playDeferredVideo() {
      // load ws
      this.loadVideoData();
    },
    async loadVideoData() {
      const wsPayload = {
        videoId: this.id
      };
      const session = await this.$store.dispatch("play/GET_WATCHING_SESSION", wsPayload, {
        root: true
      });

      if (session.status === "v") {
        this.setVideoDeferred(false);
        await this.$store.dispatch("showDetails/SET_OPENPLAYER", true, { root: true });
        await this.$store.dispatch("general/SET_MODALSHOW", true, { root: true });
        // player opened when WATCHING_SESSION all set
        console.log("VIDEO BEFORE PLAYER OPENED", this.mainVideoJson);
      } else if (session.status === "asia") {
        this.$store.dispatch(
          "general/SET_NOTIFICATION",
          {
            showNotif: true,
            type: "info",
            message:
              "You are watching from another device or from Android/iOS app. Please stop the show on that device before you can play the show from this device."
          },
          { root: true }
        );
      } else {
        this.$store.dispatch(
          "general/SET_NOTIFICATION",
          {
            showNotif: true,
            type: "info",
            message: "Sorry, your watching session has been expired or you have not purchased this show."
          },
          { root: true }
        );
      }
    }
  },
  beforeDestroy() {
    this.$store.dispatch(
      "play/STOP_WATCHING_SESSSION",
      { videoId: this.detail.id },
      { root: true }
    );
  },
  destroyed() {
    this.$store.dispatch("ui/RESET_TOP_DARK_BG_HEIGHT");
    this.$store.dispatch("ui/RESET_BOTTOM_DARK_BG_HEIGHT");
  }
};
</script>

<style lang="scss" scoped>
.defered-layout {
  background: white;
}
.watch-page {
  display: none;
}

.spinner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 991px) {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>

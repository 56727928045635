<template>
  <li
    @click.self="toggleActive(currentIndex, slug)"
    class="js-horizontal-collapse-item horizontal-collapse__item"
    :class="{
      'is-active cursor-pointer': highlightIndex == currentIndex,
      [className]: true
    }"
    tabindex="0"
    role="button"
    :style="{ backgroundImage: 'url(' + imageBackground + ')' }"
  >
    <div class="js-horizontal-collapse-item-inner horizontal-collapse__item-inner">
      <slot></slot>
    </div>
  </li>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "HorizontalCollapseItem",
  data() {
    return {};
  },
  mounted() {
    //console.log("mounted Index", this.currentIndex);
  },
  methods: {
    toggleActive(selectedIndex, slug) {
      if (this.highlightIndex == selectedIndex) {
        this.$router.push({
          name: "showdetails",
          params: { id: slug }
        });
      } else {
        this.$store.dispatch({
          type: "homepage/SET_HIGHLIGHT_INDEX",
          data: selectedIndex
        });
      }

      // console.log("toggle click", this["homepage/getHighlightIndex"]);
    }
  },
  computed: {
    ...mapState({
      highlightIndex: state => state.homepage.highlightIndex
    }),
    ...mapGetters(["homepage/getHighlightIndex"])
  },
  props: {
    className: {
      type: String,
      default: ""
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    imageBackground: {
      type: String,
      default: ""
    },
    slug: {
      type: String,
      default: ""
    },
    totalItem: {
      type: Number,
      default: 4
    }
  }
};
</script>

<style lang="scss" scoped>
li.js-horizontal-collapse-item {
  -webkit-border-radius: 25px;
  width: 16%;
  border-radius: 25px;
  overflow: hidden;
  &.is-active {
    width: 50%;
    &.xlarge-width {
      width: 85%;
    }
    &.full-width {
      width: 100%;
    }
  }
  &.medium-width {
    width: 25%;
  }
  &.large-width {
    width: 35%;
  }
}
</style>

<template>
  <div class="drawer-container">
    <div class="drawer-navbar">
      <div class="header-section">
        <div class="mb-4 d-flex">
          <router-link to="/" @click.native="closeDrawer">
            <img
              src="/img/logo/boxoffice.svg"
              alt="Box Office VR"
              class="logo"
            />
          </router-link>

          <button-hamburger-group
            :is-in-drawer="true"
            class="flex-grow-1 justify-content-end align-items-start position-static"
          />
        </div>
        <div class="mb-3 name-section" v-if="user && isLoggedIn">
          Hello, <br />
          {{ user["custom:firstName"] }}!
        </div>
      </div>
      <nav-list />
    </div>
    <div class="overlay" @click="closeDrawer" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonHamburgerGroup from "./ButtonHamburgerGroup.vue";
import NavList from "@/components/navbar/NavList";

export default {
  components: {
    ButtonHamburgerGroup,
    NavList,
  },
  watch: {
    $route: {
      handler() {
        this.closeDrawer();
      },
      immediate: true,
    },
    isLoggedIn: {
      handler() {
        this.closeDrawer();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({ user: "auth/getUser", isLoggedIn: "auth/isLoggedIn" }),
  },
  methods: {
    closeDrawer() {
      this.$store.dispatch("general/SET_MOBILEMENUSTATUS", false);
    },
  },
};
</script>

<style scoped>
.logo {
  height: 85px;
  width: auto;
}
.name-section {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.4em;
}
</style>
import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";

import router from "./router";
import store from "./store";
import VueNativeSock from "vue-native-websocket";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import VueMaterial from "vue-material";
import Vue2TouchEvents from "vue2-touch-events";
import VueGeolocation from "vue-browser-geolocation";
import taxRateMixin from "./mixins/TaxRate";

// Material
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "primevue/resources/themes/nova/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import VueMeta from "vue-meta";

require("./assets/sass/app.scss");

Vue.config.productionTip = false;
Vue.config.errorHandler = err => {
  // Show any error but this one
  if (err.message !== "Cannot read property 'badInput' of undefined") {
    console.error(err);
  }
};

Vue.use(VueMaterial);
Vue.use(Vue2TouchEvents);
Vue.use(VueGeolocation);
Vue.use(VueMeta, {
  tagIDKeyName: "id",
  refreshOnceOnNavigation: true
});
Vue.mixin(taxRateMixin);

Vue.filter("priceFormat", function(value) {
  return Number.parseFloat(value).toFixed(2);
});

Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_ENDPOINT, {
  format: "json",
  store: store,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000),
  connectManually: true
});

/***** Global error handlers
 * These handler can be used to capture error and send it to analytics
 * and also to avoid unhandled errors from components, promise rejection and outside of Vue scope
 * bubbling up and captured by UnityLoader.js error handler (alert)
 * *****/

Vue.config.errorHandler = function(err, vm, info) {
  if (err.message === "401") {
    // window.location.href = "/login";
    router.push({ name: "login" });
    console.log("refresh_token is expired, please relogin");
  } else {
    console.error(err, vm, info);
  }
};

window.alert = function(a) {
  console.error(a);
};

window.onerror = function(msg, url, line, col, error) {
  console.error(msg, url, line, col, error);
};

window.addEventListener("unhandledrejection", function(event) {
  console.error("unhandled rejection", event);
});
/***** End of global error handlers *****/

// GOOGLE ANALYTICS
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
});

const vue = new Vue({
  router,
  store,
  render: h => h(App),
  watch: {
    $route(to) {
      if (to.meta.reload) {
        window.location.reload();
      }
    }
  }
});
vue.$mount("#app");

// console.log("******* INIT VUE ******");

vue.$store.dispatch("CONNECT_WS");

//Rules for vee validate
extend("email", email);
extend("required", {
  ...required,
  message: "This field is required"
});

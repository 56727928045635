<template>
  <div class="button-mobile-container d-flex">
    <div>
      <search
        ref="searchbar"
        v-if="renderSearchField"
        v-on-clickaway="hideSearchField"
        :class="isSearchbarActive ? 'active' : ''"
      />
      <button
        class="btn btn-gold"
        @click="showSearchField"
        v-if="!isSearchbarActive"
      >
        <i class="fa fa-search"></i>
      </button>
    </div>
    <button
      class="btn btn-gold"
      @click="toggleMobileMenu"
      :class="buttonClassname"
    >
      <i class="fa fa-bars"></i>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin as clickaway } from "vue-clickaway";
import Search from "@/components/search/search";

export default {
  mixins: [clickaway],
  components: {
    Search,
  },
  props: {
    isInDrawer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      isMobileMenuOpened: (state) => state.general.isMobileMenuOpened,
      isSearchbarActive: (state) => state.general.isSearchbarActive,
    }),
    buttonClassname() {
      return this.isMobileMenuOpened ? "active" : "";
    },
    renderSearchField() {
      return !this.isInDrawer && this.isSearchbarActive;
    },
  },
  methods: {
    toggleMobileMenu() {
      this.$store.dispatch("general/TOGGLE_MOBILEMENUSTATUS");
    },
    showSearchField() {
      // close drawer first
      this.$store.dispatch("general/SET_MOBILEMENUSTATUS", false);
      this.$store.dispatch("general/SET_SEARCHBARACTIVE", true);
      this.$nextTick(() => {
        let searchForm;
        if (this.isInDrawer) {
          // searchForm = this.$parent.$parent.$parent.$refs.searchbar.$refs.searchfield;
          // TODO: how to get the search field from drawer
        } else {
          searchForm = this.$refs.searchbar.$refs.searchfield;
          searchForm.focus();
        }
        // searchForm.focus();
      });
    },
    hideSearchField() {
      this.$store.dispatch("general/SET_SEARCHBARACTIVE", false);
    },
  },
};
</script>

<template>
  <div class="comingsoon">
    <img src="@/assets/images/comingsoon.png" alt="comingsoon" />
    <div class="_form_1"></div>
  </div>
</template>

<script>
import MetaInfo from "@/mixins/MetaInfo";
import { mapActions } from "vuex";

export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "Coming soon",
      meta: [{ description: "" }]
    };
  },
  mounted() {
    let activeCampaignScript = document.createElement("script");
    activeCampaignScript.setAttribute("charset", "utf-8");
    activeCampaignScript.setAttribute("type", "text/javascript");
    activeCampaignScript.setAttribute(
      "src",
      "https://boxofficevr.activehosted.com/f/embed.php?id=1"
    );
    document.head.appendChild(activeCampaignScript);
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    })
  }
};
</script>

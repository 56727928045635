<template>
  <footer id="footer">
    <div class="row mb-4">
      <div class="col-4 col-sm-3 col-md-2">
        <div class="navbar-brand">
          <router-link to="/">
            <img src="/img/logo/boxoffice.svg" alt="Box Office VR" />
          </router-link>
        </div>
      </div>
      <div class="col-8 col-md-10">
        <div class="row">
          <div class="col-md-4 col-lg-2">
            <ul class="list-unstyled nav mb-0">
              <li>
                <router-link to="/emailenquiry"> Enquiries </router-link>
              </li>
              <li>
                <router-link to="/privacy-policy"> Privacy Policy </router-link>
              </li>
              <li>
                <router-link to="/terms-conditions">
                  Terms &amp; Conditions
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-4 col-lg-2">
            <ul class="list-unstyled nav mb-0">
              <li>
                <router-link to="/faq"> FAQ / Help </router-link>
              </li>
              <li>
                <a href="https://news.boxofficevr.com"> News </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="row">
          <div class="col-12">&copy; {{ year }} Box Office VR</div>
          <div class="col-12 build-number">Build# {{ buildNumber }}</div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
    ...mapState({
      buildNumber: state => state.general.buildNumber
    })
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";

.navbar-brand {
  img {
    width: 95px;
    height: auto;
  }
}
.build-number {
  font-size: 0.7em;
  color: $dark-brown;
}
@media only screen and (min-width: 992px) {
  #footer {
    z-index: 1;
  }
}
@media only screen and (max-width: 992px) {
  .myDetails {
    #footer {
      margin-bottom: 40px;
    }
  }
}
</style>

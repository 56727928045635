const cookiesManagement = {
  namespaced: true,
  state: {
    isUserAcceptCookie: localStorage.getItem("n8_isuseracceptcookie") || null
  },
  actions: {
    SET_USER_ACCEPT_COOKIE({ commit }, payload) {
      commit("setUserAcceptance", payload);
    }
  },
  mutations: {
    setUserAcceptance(state, payload) {
      if (payload == false) {
        // destroy all existing n8 localstorage
        // set persistedstate false
      } else {
        localStorage.setItem("n8_isuseracceptcookie", payload);
      }

      state.isUserAcceptCookie = payload;
    }
  },
  getters: {}
};

export default cookiesManagement;

// import axios from "@/axios-instance";
import http from "@/axios-instance";
import { buildVideoJson } from "@/helper/videoDataHelper.js";

const videoServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_VIDEO_API_KEY,
};

const homepage = {
  namespaced: true,
  state: {
    nowShowingList: [],
    highlightList: [],
    highlightVideoData: [],
    featuresEligibilityList: [],
    highlightIndex: 0,
    membershipDisplay: "", // between ['', 'LOGIN', 'SIGNUP', 'PAYMENT', 'FORGET'],
    isShowWatchPanel: false,
    collapsibleAnimation: false,
    justBuyVideo: [],
  },
  actions: {
    GET_NOW_SHOWING({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });

        http
          .get("/video/now-showing", { headers: videoServiceReqHeaders })
          .then((resp) => {
            const ret = resp.data;
            // console.log(["RESPONSE", ret]);
            commit("setNowShowing", ret);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(ret);
          })
          .catch((error) => {
            //console.log(["API-ERROR",error]);
            reject(error);
          })
          .then(() =>
            dispatch("general/SET_DATALOADING", false, { root: true })
          );
      });
    },
    SET_NOW_SHOWING({ commit }, params) {
      commit("setNowShowing", params.data);
    },
    GET_HIGHLIGHT({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });
        http
          .get("/video/featured", { headers: videoServiceReqHeaders })
          .then((resp) => {
            //const ret = resp.data.slice(0, 4);
            const ret = resp.data.filter(
              (item) => item.venue != undefined && item.venue != null
            );
            commit("setHighlight", ret);
            if (ret && ret.length > 0) dispatch("SET_ELIGIBILITYLIST", ret);

            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(ret);
          })
          .catch((error) => {
            //console.log(["API-ERROR",error]);
            reject(error);
          })
          .then(() =>
            dispatch("general/SET_DATALOADING", false, { root: true })
          );
      });
    },
    SET_HIGHLIGHT({ commit }, params) {
      commit("setHighlight", params.data);
    },
    SET_HIGHLIGHT_INDEX({ commit }, params) {
      commit("setHighlightIndex", params.data);
    },
    SET_ELIGIBILITYLIST({ rootGetters, dispatch, commit }, highlightList) {
      let eligibilityList = []; // array of productId
      const user = rootGetters["auth/getUser"];

      if (user) {
        highlightList.forEach((item) => {
          const session = rootGetters["play/checkEligibility"](item.id);

          if (session && session.expiryTimestamp > Date.now()) {
            eligibilityList.push(item.id);
          }
        });

        commit("setFeaturesEligibilityList", eligibilityList);
        // set the video of all eligible features
        dispatch("SET_HIGHLIGHT_VIDEO_DATA", eligibilityList);
      } else {
        commit("setFeaturesEligibilityList", []);
      }
    },
    SET_HIGHLIGHT_VIDEO_DATA({ dispatch, commit }, eligibilityList) {
      let promises = [];
      console.log(eligibilityList);
      eligibilityList.forEach((productId) => {
        promises.push(
          dispatch("showDetails/GET_SHOW", productId, { root: true })
        );
      });

      Promise.all(promises).then((res) => {
        const finalData = res.map((item) => {
          const videoJson = buildVideoJson(item);
          return {
            id: item.id,
            videoJson: videoJson,
          };
        });

        commit("setHighlightVideoJsonList", finalData);
      });
    },
    SET_MEMBERSHIP_DISPLAY({ commit }, params) {
      commit("setMembershipDisplay", params.data);
    },
    SET_WATCH_PANEL_DISPLAY({ commit }, params) {
      commit("setWatchPanelDisplay", params.data);
    },
    SET_COLLAPSE_ANIMATION({ commit }, params) {
      commit("setCollapseAnimation", params);
    },
    CLEAR_ELIGIBLE_DATA({ commit }) {
      commit("clearAllEligibleData");
    },
    SET_JUST_BUY_VIDEO({ commit }, params) {
      commit("setJustBuyVideo", params.data);
    },
    GET_JUST_BUY_VIDEO({ commit }, params) {
      commit("setJustBuyVideo", params.data);
    },
  },
  mutations: {
    setCollapseAnimation(state, payload) {
      state.collapsibleAnimation = payload;
    },
    setNowShowing(state, payload) {
      state.nowShowingList = payload;
    },
    setHighlight(state, payload) {
      state.highlightList = payload;
    },
    setHighlightIndex(state, newIndex) {
      state.highlightIndex = newIndex;
    },
    setMembershipDisplay(state, displayName) {
      state.membershipDisplay = displayName;
    },
    setWatchPanelDisplay(state, displayWatchPanel) {
      state.isShowWatchPanel = displayWatchPanel;
    },
    setFeaturesEligibilityList(state, payload) {
      state.featuresEligibilityList = payload;
    },
    setHighlightVideoJsonList(state, payload) {
      state.highlightVideoData = payload;
    },
    clearAllEligibleData(state) {
      state.highlightVideoData = [];
      state.featuresEligibilityList = [];
    },
    setJustBuyVideo(state, payload) {
      state.justBuyVideo = payload;
    },
  },
  getters: {
    getNowShowing(state) {
      return state.nowShowingList;
    },
    getHighlight(state) {
      return state.highlightList;
    },
    getHighlightIndex(state) {
      return state.highlightIndex;
    },
    getMembershipDisplayName(state) {
      console.log("getMembershipDisplayName", state.membershipDisplay);
      return state.membershipDisplay;
    },
    getWatchPanelDisplay(state) {
      return state.isShowWatchPanel;
    },
    getJustBuyVideo(state) {
      return state.justBuyVideo;
    },
  },
};

export default homepage;

<template>
  <div class="forgot-password-main-container">
    <div class="inner">
      <div class="forgot-password-container background-forgot-password">
        <ForgotPassword></ForgotPassword>
      </div>
    </div>
  </div>
</template>
<script>
import ForgotPassword from "@/components/membership/ForgotPassword";

export default {
  data() {
    return {};
  },
  components: {
    ForgotPassword,
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/mixins";
@import "~@/assets/sass/base/variables";
.forgot-password-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
}
.background-forgot-password {
  border-radius: 25px;
  padding: 40px;
  max-width: 410px;
  background-color: #24211f;
  border: 1px #ae8138 solid;

  @include breakpoint("medium") {
    width: 410px;
  }
}
</style>

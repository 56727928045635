<template>
  <section class="notify-me non-login mb-3">
    <div class="text-center d-lg-flex" v-if="!isLoggedIn">
      <div class="notify-btn btn mb-3" v-on:click="showLoginDialog(true)">
        <i class="icon fas fa-bell"></i>&nbsp;&nbsp; Notify Me
      </div>
    </div>

    <div class="text-center d-lg-flex" v-if="isLoggedIn">
      <div
        class="notify-btn btn mb-3"
        v-on:click="showLoginDialog(true)"
        :class="isNotify ? 'active' : ''"
      >
        <md-switch
          v-model="isNotify"
          v-on:change="notifyMeChange(isNotify)"
          toggled-off-color="#fff000"
          >{{ isNotify ? "We'll Notify You" : "Notify Me" }}</md-switch
        >
      </div>
    </div>

    <md-dialog :md-active="showLogin && !isLoggedIn" class="registration-container">
      <md-dialog-actions class="login-popup-action">
        <span class="icon-close fa fa-times" @click="showLoginDialog(false)"></span>
      </md-dialog-actions>
      <md-dialog-content class="login-popup-content">
        <MembershipForm :hl-item="{}" :isActive="true"></MembershipForm>
      </md-dialog-content>
    </md-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import * as _ from "lodash";
import MembershipForm from "@/components/membership/index";

export default {
  name: "NotifyMe",
  components: {
    MembershipForm
  },
  data() {
    return {
      showLogin: false,
      isLoggedIn: false,
      isNotify: false,
      originalNotifyValue: false
    };
  },
  methods: {
    showLoginDialog(status) {
      // console.log("notify for next showing item", status);
      // console.log('showdetails_getNotifiers"]', this["showdetails/getNotifiers"]);
      this.showLogin = status;
    },

    notifyMeChange: _.debounce(function(value) {
      if (value !== this.originalNotifyValue) {
        // console.log("call the API", value);

        this.$store
          .dispatch({
            type: "showDetails/POST_NOTIFIERS",
            data: {
              isNotify: value,
              venueId: this.venueId,
              videoId: this.videoId
            }
          })
          .then(() => {
            this.$store.dispatch("showDetails/SET_NOTIFIERS", {
              isNotify: value,
              venueId: this.venueId,
              videoId: this.videoId
            });

            this.originalNotifyValue = value;
          });
      }
    }, 400)
  },
  computed: {
    ...mapGetters(["auth/isLoggedIn", "showDetails/getNotifiers", "auth/getUser"])
  },
  async mounted() {
    // onsole.log("showdetails_getNotifiers", JSON.stringify(this["showDetails/getNotifiers"]));
    if (this["auth/isLoggedIn"] && this["auth/getUser"]) {
      this.isLoggedIn = true;
      if (this.videoId && this.venueId && this["auth/getUser"].sub) {
        if (!this["showDetails/getNotifiers"]) {
          console.log("masuk ke null get notifier");
          await this.$store
            .dispatch({
              type: "showDetails/GET_NOTIFIERS"
            })
            .then(() => {
              const notifyItem = this["showDetails/getNotifiers"].find(
                notify => notify.venueId === this.venueId && notify.videoId === this.videoId
              );

              if (notifyItem) {
                this.isNotify = notifyItem.isNotify;
                this.originalNotifyValue = notifyItem.isNotify;
              }
            });
        } else {
          const notifyItem = this["showDetails/getNotifiers"].find(
            notify => notify.venueId === this.venueId && notify.videoId === this.videoId
          );

          if (notifyItem) {
            this.isNotify = notifyItem.isNotify;
            this.originalNotifyValue = notifyItem.isNotify;
          }
        }
      }
      // console.log("membership ada", this["auth/isLoggedIn"]);
    } else {
      this.isLoggedIn = false;
      // console.log("membership gak ada", this["auth/isLoggedIn"]);
    }
  },
  beforeMount() {},
  filters: {},
  props: {
    videoId: {
      type: String,
      default: ""
    },
    venueId: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/base/variables";
.md-dialog {
  &.registration-container {
    position: fixed;
    background-color: unset;
  }
  .md-dialog-container {
    .login-popup-action {
      .icon-close {
        color: white;
        cursor: pointer;
      }
    }
    .login-popup-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .md-dialog-actions {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
    .md-dialog-content {
      padding: 0;
    }
  }
}

.notify-btn {
  label {
    margin: 0;
  }
  &.active {
    background-color: $gold;
    color: white;
    transition: 0.3s background-color;
    label {
      color: white;
    }
  }
}
</style>

<template>
  <div class="next-showing" v-if="items.length">
    <h3 class="mb-4">Next Showing</h3>
    <div class="row">
      <div v-for="(item, index) in mappedItems" :key="index" :class="itemClassname">
        <!-- MOBILE -->
        <!-- <div class="d-flex align-items-center mb-4 d-lg-none">
          <div class="bottom-text">{{ item.bottomText }}</div>
        </div> -->

        <InfoBox
          :id="item.id"
          :routeToDetail="routeToDetail"
          :img="item.img"
          :title="item.title"
          :subtitle="item.subtitle"
          :bottomText="item.bottomText"
        />
        <NotifyMe :videoId="videoId" :venueId="item.id" />
        <div class="underline-dash-gold my-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoBox from "@/components/shared/InfoBox";
import NotifyMe from "@/components/notifyMe/index";

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    videoId: {
      type: String,
      default: ""
    },
    itemClassname: {
      type: String,
      default: "col-md-3"
    }
  },
  components: {
    InfoBox,
    NotifyMe
  },
  data() {
    return {
      routeToDetail: "venuesDetail"
    };
  },
  computed: {
    mappedItems() {
      return this.items.map(item => {
        const img =
          item.selectedVenue &&
          item.selectedVenue.mediaFiles &&
          item.selectedVenue.mediaFiles.find(item => item.key == "thumbnail_small")
            ? item.selectedVenue.mediaFiles.find(item => item.key == "thumbnail_small").value
            : null;
        const bottomText = item.description;

        return {
          id: item.selectedVenue.id,
          title: item.selectedVenue.name,
          subtitle: `${item.selectedVenue.city}, ${item.selectedVenue.country}`,
          img: img,
          bottomText: bottomText
        };
      });
    }
  },
  methods: {
    onNotifyButtonClick() {
      // console.log(["notify for next showing item", item]);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
h3,
h5 {
  font-family: "Playfair-bold";
}
</style>

<template>
  <div class="mydetails-container">
    <div class="text-center" v-if="loading"><small-loader></small-loader></div>
    <div class="user-detail-container" v-if="!loading">
      <h1 class="title">My Details</h1>
      <div class="form">
        <ContactInformation ref="contactInfo"></ContactInformation>
        <Address ref="addressInfo"></Address>
      </div>

      <!-- Dialog Popup for Global Signout -->
      <md-dialog class="global-signout-popup" :md-active.sync="confirmGlobalSignout">
        <h3 class="dialog-title mb-4">
          <span class="float-left"
            >Are you sure to signout from all of your signed in devices?</span
          >
          <span class="float-right closeIco" @click="confirmGlobalSignout = false"></span>
          <MdButton class="n8-btn md-raised mr-4 mt-4" @click="globalSignout">
            <i class="fa mr-2"></i>
            <span>Yes</span>
          </MdButton>
          <MdButton class="n8-btn md-raised mr-4 mt-3" @click="confirmGlobalSignout = false">
            <i class="fa mr-2"></i>
            <span>No</span>
          </MdButton>
        </h3>
      </md-dialog>
      <!-- End of Dialog -->

      <md-dialog class="delete-account-popup" :md-active.sync="showDeleteAccountForm">
        <h3 class="dialog-title mb-3">
          Delete Account
          <span class="float-right closeIco" @click="showDeleteAccountForm = false"></span>
        </h3>
        <DeleteAccount :userId="user.sub"></DeleteAccount>
      </md-dialog>

      <div class="btn-container">
        <div class="d-flex">
          <MdButton class="n8-btn md-raised mr-2 btnDelete" @click="confirmGlobalSignout = true">
            <i class="fa mr-2"></i>
            <span>Global Signout</span>
          </MdButton>
          <MdButton class="n8-btn md-raised mr-2 btnDelete" @click="showDeleteAccountForm = true">
            <i class="fa fa-trash-alt mr-2"></i>
            <span>Delete Account</span>
          </MdButton>
          <MdButton class="n8-btn md-raised mr-2 btnCancel" @click="goBack">
            <span class="icon btn-cancel">Cancel</span>
          </MdButton>
          <MdButton class="n8-btn gold-btn md-raised btnSave" @click="save">
            <span class="icon btn-save">Save</span>
          </MdButton>
        </div>
      </div>
    </div>
    <div class="btn-mobile">
      <MdButton class="n8-btn md-raised mr-2 btnDelete mb-2" @click="confirmGlobalSignout = true">
        <i class="fa mr-2"></i>
        <span>Global Signout</span>
      </MdButton>
      <MdButton class="n8-btn md-raised mr-2 btnDelete" @click="showDeleteAccountForm = true">
        <i class="fa fa-trash-alt mr-2"></i>
        <span>Delete Account</span>
      </MdButton>
    </div>
    <div class="user-payment-history" v-if="!loading">
      <PaymentHistory :order-history="orderHistory"></PaymentHistory>
    </div>
  </div>
</template>
<script>
import MetaInfo from "@/mixins/MetaInfo";
import ContactInformation from "@/components/mydetails/ContactInformation";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import Address from "@/components/mydetails/Address";
import DeleteAccount from "@/components/membership/DeleteAccount";
import PaymentHistory from "@/components/mydetails/PaymentHistory";
import SmallLoader from "@/components/SmallLoader.vue";

export default {
  mixins: [MetaInfo],
  components: {
    ContactInformation,
    Address,
    DeleteAccount,
    PaymentHistory,
    SmallLoader
  },
  data() {
    return {
      title: "My Details",
      meta: [{ description: "" }],
      message: null,
      notificationType: null,
      showDeleteAccountForm: false,
      confirmGlobalSignout: false,
      loading: true
    };
  },
  computed: {
    ...mapGetters({
      tokens: "auth/getAuthTokens",
      user: "auth/getUser",
      detail: "auth/getUserDetails"
    }),
    ...mapGetters(["auth/getUser"]),
    ...mapState({
      orderHistory: state => state.myDetails.orderHistory,
      countries: state => state.myDetails.countries
    }),
    countryStringify() {
      return this.getSelectedCountryObject(this.$refs.addressInfo.country);
    },
    countryName() {
      return JSON.parse(this.countryStringify).name;
    }
  },
  mounted() {
    //console.log("getUser", this["auth/getUser"]);
    this.getUserDetails();
    this.$store.dispatch("myDetails/getCountryList");
    this.$store.dispatch("ui/SET_TOP_DARK_BG_HEIGHT", 0);
    this.$store.dispatch("myDetails/GET_ORDER_HISTORY", this["auth/getUser"].sub);
  },
  methods: {
    getUserDetails() {
      if (this.user) {
        this.$store
          .dispatch("auth/getUserDetailsFromDynamoDb", this.user.sub)
          .finally(() => (this.loading = false));
        console.log("detail", this.detail);
      } else {
        console.log("Current user", this.user);
        this.loading = false;
      }
    },
    goBack() {
      this.$router.push("/");
    },
    save() {
      if (this.$refs.contactInfo.invalidMandatoryField) {
        this.$store.dispatch("general/SET_NOTIFICATION", {
          showNotif: true,
          type: "error",
          message: "Please check your input."
        });
        return;
      }
      let firstNameChange = this.$refs.contactInfo.firstName !== this.user["custom:firstName"];
      let lastNameChange = this.$refs.contactInfo.lastName !== this.user["custom:lastName"];
      let emailChange = this.$refs.contactInfo.email !== this.user.email;
      let countryChange = this.countryStringify !== this.detail.country;

      if (firstNameChange || lastNameChange || emailChange || countryChange) {
        console.log({
          firstNameChanged: firstNameChange,
          lastNameChanged: lastNameChange,
          emailChanged: emailChange,
          countryChanged: countryChange
        });
        this.saveToCognito();
      }
      this.saveToDynamoDb();

      if (countryChange) this.updateUserLocation();
    },
    saveToCognito() {
      let obj = {
        firstName: this.$refs.contactInfo.firstName,
        lastName: this.$refs.contactInfo.lastName,
        email: this.$refs.contactInfo.email,
        userId: this.user.sub,
        country: this.countryName
      };
      this.$store
        .dispatch("auth/updateUserToCognito", obj)
        .then(res => {
          console.log("success update cognito", res);
        })
        .catch(err => {
          console.log("error update user to cognito", err);
          this.$store.dispatch("general/SET_NOTIFICATION", {
            showNotif: true,
            type: "error",
            message: "Something went wrong, please try again later."
          });
        });
    },
    saveToDynamoDb() {
      let payload = {
        id: this.user.sub,
        firstName: this.$refs.contactInfo.firstName,
        lastName: this.$refs.contactInfo.lastName,
        email: this.$refs.contactInfo.email,
        dateOfBirth: this.$refs.contactInfo.dateOfBirth
          ? moment(this.$refs.contactInfo.dateOfBirth).format("YYYY-MM-DD HH:mm:ss")
          : null,
        address: this.$refs.addressInfo.address,
        city: this.$refs.addressInfo.city,
        postCode: this.$refs.addressInfo.postCode,
        state: this.$refs.addressInfo.state,
        country: this.countryStringify,
        updatedDateTime: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        secondaryAddress: this.$refs.addressInfo.address2
      };
      console.log("Payload update user", payload);
      this.$store
        .dispatch("auth/updateUserToDynamoDb", payload)
        .then(res => {
          if (res) {
            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "success",
              message: "Success."
            });
          }
        })
        .catch(err => {
          console.log("error update user to db", err);
          this.$store.dispatch("general/SET_NOTIFICATION", {
            showNotif: true,
            type: "error",
            message: "Something went wrong, please try again later."
          });
        });
    },
    getSelectedCountryObject(code) {
      let country = this.countries.find(item => item.code === code);
      if (country) {
        return JSON.stringify(country);
      }
      return "";
    },
    async globalSignout() {
      await this.$store.dispatch("auth/globalSignout");
      this.confirmGlobalSignout = false;
      // this.$router.replace({ name: "login" });
    },
    updateUserLocation: function() {
      const payload = {
        oldTags: JSON.parse(this.detail.country).name,
        tags: this.countryName
      };
      this.$store.dispatch("activeCampaign/UPDATE_LOCATION", payload, {
        root: true
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";
.mydetails-container {
  display: flex;
  flex-direction: column;
  h1 {
    color: $gold;
    font-weight: bold;
    font-size: 64px;
    margin-bottom: 69px;
  }
  .btn-container {
    float: right;
    margin-top: 1.5rem;

    @include breakpoint("extralarge") {
      width: 55%;
    }

    @include breakpoint("large") {
      width: 77%;
    }

    @include breakpoint("small") {
      position: fixed;
      background: #332f2c;
      width: 100%;
      height: auto;
      padding: 15px 20px;
      bottom: 0;
      left: 0;
      z-index: 10;
      right: 0;
      margin: 0;
    }
  }
  .btnDelete {
    @include breakpoint("small") {
      display: none;
    }
  }
  .form {
    display: flex;
  }
  .btnCancel,
  .btnSave,
  .btnDelete {
    padding: 14px 30px !important;
  }
  .title {
    text-align: center;
    @include breakpoint("small") {
      text-align: left;
      font-size: 22px;
      margin: 0;
      font-family: "Opensans-regular";
      font-weight: bold;
      color: white;
      padding: 0 22px;
    }
  }

  .btn-mobile {
    display: none;

    @include breakpoint("small") {
      display: block;
    }

    .btnDelete {
      @include breakpoint("small") {
        display: block !important;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .form {
      flex-direction: column;
    }
    .btn-container {
      float: unset;
      flex-direction: column;
      width: unset;
    }
    .user-payment-history {
      background-color: transparent !important;
    }
  }
  label {
    text-transform: uppercase;
  }
  .user-payment-history {
    margin-top: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 25px;
    border-radius: 25px;
  }
}
</style>

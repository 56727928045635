<template>
  <ul class="navbar-nav mr-auto">
    <li class="nav-item">
      <router-link to="/" :class="parentActive('/shows')">
        <i class="icon shows"></i> Shows
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/venues" :class="parentActive('/venues')">
        <i class="icon venues"></i> Venues
      </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/about"> <i class="icon about"></i> About </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/faq"> <i class="icon faq"></i> FAQ </router-link>
    </li>
    <li class="nav-item">
      <router-link to="/emailenquiry"> <i class="icon faq"></i> Enquiries </router-link>
    </li>
    <li
      class="nav-item dropdown"
      @mouseover="toggleDropdown(true)"
      @mouseleave="toggleDropdown(false)"
      :class="isDropdownHasActiveChild ? 'active' : ''"
      v-if="isLoggedIn"
    >
      <a><i class="icon login"></i> My Account</a>
      <transition name="slide-down">
        <ul class="dropdown-menu show animated" v-show="dropdownShow">
          <li>
            <router-link to="/myorder"> <i class="icon orders"></i> My Orders </router-link>
          </li>
          <li>
            <router-link to="/mydetails"><i class="icon details"></i> My Details</router-link>
          </li>
          <!--
          <li>
            <a href=""><i class="icon settings"></i> Settings</a>
          </li>
          -->
        </ul>
      </transition>
    </li>
    <li class="nav-item" v-if="!isLoggedIn">
      <router-link to="/login"> <i class="icon logout"></i> Login </router-link>
    </li>
    <li class="nav-item" v-if="isLoggedIn">
      <router-link to="/login" class="cursor-pointer" @click.native="logout" replace>
        <i class="icon logout"></i> Log Out
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      dropdownShow: false
    };
  },
  watch: {
    $route() {
      this.closeDrawer();
    }
    // isLoggedIn(newVal, oldVal) {
    //   console.log(`Updating from ${oldVal} to ${newVal}`);
    // },
  },
  computed: {
    isDropdownHasActiveChild() {
      // TODO: recheck the final route's name of the children
      const childRoute = ["myorder", "details", "settings"];
      return childRoute.some(name => name === this.$route.name);
    },
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    }
    // ...mapState({
    //   isLoggedIn: "auth/isLoggedIn",
    // }),
  },
  methods: {
    parentActive(parentPath) {
      return this.$route.meta.parentPath == parentPath ? "active" : "";
    },
    closeDrawer() {
      this.$store.dispatch("general/SET_MOBILEMENUSTATUS", false);
    },
    toggleDropdown(val) {
      this.dropdownShow = val;
    },
    logout() {
      this.$store.dispatch("auth/signOut");
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>

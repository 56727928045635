import axios from "@/axios-instance";

const faqs = {
  namespaced: true,
  state: {
    faqsList: []
  },
  actions: {
    GET_FAQS({ dispatch, commit }) {
      const url = "/content/faq";
      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });
        axios
          // .get(url)
          .get(url)
          .then((resp) => {
            const ret = resp.data;
            
            console.log('faq-data',ret);
            //console.log(['RESPONSE', ret])
            commit("setFaqs", ret);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(ret);
          })
          .catch((error) => {
            //console.log(["API-ERROR",error]);
            dispatch("general/SET_DATALOADING", false, { root: true });
            reject(error);
          });
      })
    },
    SET_FAQS({ commit }, params) {
      commit("setFaqs", params.data);
    }
  },
  mutations: {
    setFaqs(state, payload) {
      state.faqsList = payload;
    }
  },
  getters: {
    getFaqs(state) {
      return state.faqsList;
    },
  },
};

export default faqs;

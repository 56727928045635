import { render, staticRenderFns } from "./InfoBox.vue?vue&type=template&id=4f8b2010&scoped=true&"
import script from "./InfoBox.vue?vue&type=script&lang=js&"
export * from "./InfoBox.vue?vue&type=script&lang=js&"
import style0 from "./InfoBox.vue?vue&type=style&index=0&id=4f8b2010&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8b2010",
  null
  
)

export default component.exports
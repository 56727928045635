import Vue from "vue";
import Vuex from "vuex";
import general from "./modules/general";
import ui from "./modules/ui";
import cookiesManagement from "./modules/cookiesManagement";
import play from "./modules/play";
import homepage from "@/pages/homepage/store";
import faq from "@/pages/faq/store";
import search from "@/pages/search/store";
import orders from "@/pages/myorder/store";
import venues from "@/pages/venues/store";
import showDetails from "@/pages/showdetails/store";
import myDetails from "@/pages/mydetails/store";
import auth from "./modules/auth";
import router from "../router";

import createPersistedState from "vuex-persistedstate";
import purchaseSettings from "./modules/purchaseSettings";
import activeCampaign from "./modules/activeCampaign";

Vue.use(Vuex);
const persistedstate = new createPersistedState({
  key: "bovr-vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.localStorage window.sessionStorage or localForage
  paths: ["auth", "play", "playWS", "purchaseSettings"], // only the persisted ones
  reducer(val, paths) {
    if (!localStorage.getItem("n8_isuseracceptcookie")) {
      // DONT USE PERSISTEDSTATE IF USER DECLINE COOKIES
      return {};
    }

    // make persisted state as in paths
    let reducer = {};

    Object.entries(val).forEach(entry => {
      const [key, value] = entry;
      if (paths.includes(key)) {
        reducer[key] = value;
      }
    });

    return reducer;
  }
});

const store = {
  modules: {
    ui,
    cookiesManagement,
    general,
    homepage,
    faq,
    search,
    orders,
    venues,
    showDetails,
    myDetails,
    auth,
    play,
    purchaseSettings,
    activeCampaign
  },
  plugins: [persistedstate],
  state: {
    playWS: {
      isConnected: false,
      message: "",
      reconnectError: false
    }
  },
  mutations: {
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      console.log("***", state, event);
      state.playWS.isConnected = true;
    },
    SOCKET_ONCLOSE(state, event) {
      console.log("***", state, event);
      state.playWS.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      console.error("***", state, event);
    },
    // default handler called for all methodsa
    SOCKET_ONMESSAGE(state, message) {
      console.log(message);
      state.playWS.message = message || {};

      var msgObj = typeof message === "string" ? JSON.parse(message) : message;
      const { action, data } = msgObj;

      console.log("*** SOCKET_ONMESSAGE", message, data);

      if (action === "SIGNOUT") {
        this.dispatch("SIGNOUT", null);
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info("***", state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      console.log("***", state);
      state.playWS.reconnectError = true;
    }
  },
  actions: {
    PLAY_WS_SEND_MESSAGE(context, message) {
      console.log("*** send message", message);
      Vue.prototype.$socket ? Vue.prototype.$socket.send(JSON.stringify(message)) : null;
    },
    CONNECT_WS({ dispatch, rootGetters }) {
      try {
        if (rootGetters["auth/isLoggedIn"] == false) return;

        console.log("is logged in");
        const t = rootGetters["auth/getIdToken"];
        const dk = rootGetters["auth/getDeviceKey"];
        const uid = rootGetters["auth/getUserId"];

        console.log("Connecting WS...");
        this._vm.$connect();

        let _timeout = null;
        _timeout = setTimeout(() => {
          dispatch(
            "PLAY_WS_SEND_MESSAGE",
            { action: "register", data: { uid, t, dk } },
            { root: true }
          );
          clearTimeout(_timeout);
        }, 3000);
      } catch (err) {
        if (err.message === "tokens not found in local storage") {
          router.push({ name: "login" });
        }
      }
    },
    DISCONNECT_WS() {
      console.log("Disconnecting WS...");
      this._vm.$disconnect();
    },
    SIGNOUT({ dispatch }, payload) {
      dispatch("auth/signOut", payload, { root: true });
    }
  },
  gettters: {
    playWSIsConnected(state) {
      return state.playWS.isConnected;
    }
  }
};

export default new Vuex.Store(store);

<template>
  <div class="player">
    <div class="loading" v-if="!isTrailer && !isVideoLoaded">
      <small-loader class="d-block mx-auto" />
      {{ loadingText }}
    </div>
    <div class="unity-container">
      <unity
        class="unity-player"
        ref="unityInstance"
        :src="unitySrc"
        :width="unityW"
        :height="unityH"
        :unityLoader="unityLoader"
      ></unity>
      <transition name="slide">
        <unity-controlbar
          class="unity-controller"
          @onSetQuality="onChangeQuality"
          @onPlay="onStartOrPlay()"
          @onPause="onPause()"
          @onVR="onVR()"
          @onFullscreen="onFullscreen()"
          @onSetVolume="onSetVolume"
          @onPlayAt="playVideoAt"
          :isTrailer="isTrailer"
          :isPlay="isPlay"
          :videoMetadata="mainVideoJson"
          :videoLength="videoLength"
          :videoCurrentPlay="videoCurrentPlay"
          :volumeLevel="volumeLevel"
          :quality="quality"
          :isVRSupported="isVRSupported"
          v-if="show && videoControlShow"
        ></unity-controlbar>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Unity from "@/components/videoplayer/Unity";
// import Unity from "vue-unity-webgl";
import UnityControlbar from "@/components/videoplayer/UnityControlbar";
import SmallLoader from "@/components/SmallLoader";

export default {
  props: {
    trailerUrl: {
      type: String,
      default: ""
    },
    promoVideoJson: {
      type: Object,
      default: () => {}
    },
    mainVideoJson: {
      type: Object,
      default: () => {}
    },
    videoControlShow: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unitySrc: "/player/Build/Build.json",
      unityLoader: "/player/Build/UnityLoader.js",
      unityProgress: "/player/TemplateData/UnityProgress.js",
      unityW: 1200,
      unityH: 800,
      unityData: null,
      isPlay: false,
      isResume: false,
      isPlayerLoaded: false,
      isvideoLoaded: false,
      isVRSupported: true,
      quality: "med",
      loadingText: "loading.."
    };
  },
  created() {
    // console.log("*** UPEN PLAYER created", this._uid);
  },
  mounted() {
    setInterval(() => {
      this.unityData = window.upenPlayerData.json || null;
      this.isPlayerLoaded = this.unityData ? JSON.parse(this.unityData).isPlayerLoaded : false;

      this.isvideoLoaded = this.unityData ? JSON.parse(this.unityData).isvideoLoaded : false;

      // if (this.isPlayerLoaded && this.isvideoLoaded) clearInterval(intvl);
    }, 1000);
  },
  watch: {
    // load/init video into player after videoJsonString && isPlayerLoaded state has been loaded, even the player still hidden
    isPlayerLoaded(val) {
      if (val) {
        this.loadPromo();
      } else {
        // catch+show error when player somehow back unloaded
        this.loadingText = "reload the player...";
      }
    },
    // loadVideo when the upenplayer is show
    show(val) {
      if (val) {
        this.checkVRCapability();

        if (!this.isTrailer) {
          // "INIT IS DONE IN WATCH videoJsonString, AND JUST START THE VIDEO+PROMO"
          this.loadVideo();
        } else {
          this.loadAndPlayTrailer();
        }
      } else {
        this.onPause();
      }
    },
    // after loadVideo, watch this property. startVideo if true and not isTrailer
    isvideoLoaded(val) {
      if (val && !this.isTrailer) {
        if (!this.isResume) {
          this.startVideo();
          // this.isResume = true;
        } else {
          this.onPlay();
        }
      }
    }
  },
  computed: {
    ...mapState({
      isTrailer: state => state.showDetails.isTrailer
    }),
    videoLength() {
      return this.unityData ? JSON.parse(this.unityData).MainVideoLenth : 0;
    },
    videoCurrentPlay() {
      return this.unityData ? JSON.parse(this.unityData).MainVideoPLayTime : 0;
    },
    volumeLevel() {
      return this.unityData ? JSON.parse(this.unityData).MainVideoVolume : 0;
    },
    isVideoLoaded() {
      return this.unityData ? JSON.parse(this.unityData).MainVideoLenth > 0 : false;
    }
  },
  methods: {
    loadAndPlayTrailer() {
      this.loadTrailer(this.trailerUrl);

      // check every 500ms interval if the video loaded, then play
      const intervalToPlay = setInterval(() => {
        if (this.isvideoLoaded) {
          this.onPlay();
          clearInterval(intervalToPlay);
        }
      }, 500);
    },
    onFullscreen() {
      const realButton = document.getElementsByClassName("fullscreen")[0];
      realButton.click();
      // tell the player it is full screen
      this.$refs.unityInstance.message("MediaController", "GoTOFullScreen");
    },
    loadTrailer(trailerJson) {
      this.$refs.unityInstance.message("MediaController", "LoadTrailer", trailerJson);
    },
    loadPromo() {
      try {
        this.$refs.unityInstance.message(
          "MediaController",
          "LoadPromovideo",
          JSON.stringify(this.promoVideoJson)
        );
      } catch (error) {
        //
      }
    },
    loadVideo() {
      this.$refs.unityInstance.message(
        "MediaController",
        "LoadVideo",
        JSON.stringify(this.mainVideoJson)
      );
      this.$store.dispatch("play/START_PINGER", null, { root: true });
    },
    toggle3d(val = 1) {
      this.$refs.unityInstance.message("MediaController", "changeVideoView", val);
    },
    playVideoAt(positionInSecond) {
      this.$refs.unityInstance.message("MediaController", "PlayVideoAt", positionInSecond);
    },
    startVideo() {
      try {
        // StartVideo: play promo then auto play the mainVid
        // this.$refs.unityInstance.message("MediaController", "StartVideo");
        this.$refs.unityInstance.message("MediaController", "PlayVideo");
        this.$store.dispatch("play/START_PINGER", null, { root: true });
        this.isPlay = true;
      } catch (error) {
        console.log("start error", error);
      }
    },
    onChangeQuality(quality) {
      const qualities = ["low", "med", "high"];
      const qualitySelected = qualities.indexOf(quality.toLowerCase());
      this.quality = quality;
      this.$refs.unityInstance.message("MediaController", "changeVideoQuality", qualitySelected);
    },
    onSetVolume(vol) {
      this.$refs.unityInstance.message("MediaController", "SetVolume", vol);
    },
    onStartOrPlay() {
      if (this.isResume) {
        this.onPlay();
      } else {
        this.startVideo();
        this.isResume = true;
      }
    },
    onPlay() {
      try {
        this.$refs.unityInstance.message("MediaController", "PlayVideo");
        this.$store.dispatch("play/START_PINGER", null, { root: true });
        this.isPlay = true;
      } catch (error) {
        console.log(error);
      }
    },
    onPause() {
      this.isPlay = false;
      this.$refs.unityInstance.message("MediaController", "PauseVideo");
    },
    onStop() {
      this.isPlay = false;
      this.$refs.unityInstance.message("MediaController", "StopVideo");

      window.upenPlayerData = {};

      this.$store.dispatch("play/STOP_PINGER", null, { root: true });
    },
    checkVRCapability() {
      const self = this;
      document.addEventListener(
        "onVRSupportedCheck",
        function(event) {
          self.isVRSupported = event.detail.supported; // make it true atm
        },
        false
      );
    },
    onVR() {
      // document.dispatchEvent(new CustomEvent("toggleVR", {}));
      // unityInstance.Module.WebXR.toggleVR();
      this.$refs.unityInstance.toggleVR();
    },
    toggleControl(state) {
      this.videoControlShow = state;
    },
    getUnityData(jsonObj) {
      console.log("upen player ready unity data here: ", jsonObj);
    },
    onPlayTest() {
      const videoUrl = this.videoUrl;

      if (videoUrl) {
        this.$refs.unityInstance.message("MediaController", "Test", videoUrl);
      }
    }
  },
  destroyed() {
    //console.log("*** UPEN PLAYER destroyed", this._uid);
  },
  components: {
    Unity,
    UnityControlbar,
    SmallLoader
  }
};
</script>

<style lang="scss">
.unity-container {
  position: relative;
  width: 100%;
  height: 100vh;
  #unity-container {
    width: 100% !important;
    height: 100% !important;
  }
  .footer .fullscreen {
    display: none;
  }
  .unity-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .unity-controller {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  color: white;
  width: 200px;
  text-align: center;
  z-index: 10000;
}
.hide {
  opacity: 0;
}
</style>

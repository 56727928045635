<template>
  <div id="app" :class="finalClass">
    <transition name="slide-down">
      <cookies-bar
        ref="cookiesBar"
        class="cookie-bar"
        @onClose="closeCookieBar"
        v-if="showCookieBar"
      />
    </transition>
    <router-view :key="$route.fullPath" />
    <NotificationBar></NotificationBar>

    <keep-alive>
      <N8Dialog class="video-modal-fullscreen" :show="modalShow">
        <VideoContainerPage></VideoContainerPage>
      </N8Dialog>
    </keep-alive>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import CookiesBar from "./components/shared/CookiesBar";
import N8Dialog from "./components/modal/ModalDialog";
import VideoContainerPage from "./components/videoplayer/VideoContainerPage";
import NotificationBar from "./components/NotificationBar.vue";

export default {
  components: {
    CookiesBar,
    NotificationBar,
    N8Dialog,
    VideoContainerPage,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      modalShow: state => state.general.modalShow,
      bgBlack: state => state.ui.isBackgroundBlack,
      isUserAcceptCookie: state => state.cookiesManagement.isUserAcceptCookie,
    }),

    showCookieBar() {
      return this.isUserAcceptCookie == null;
    },
    finalClass() {
      let classname = [];
      if (this.bgBlack) {
        classname.push("bg-black");
      }
      if (this.isUserAcceptCookie == null) {
        classname.push("with-cookie-bar");
      }

      return classname.join(" ");
    },
  },
  mounted() {
    let qs = this.$route.query.search;
    if (qs) {
      const payload = { searchKey: qs };
      this.search(payload);
      this.$router.push({
        name: "search",
        query: { search: qs },
      });
    }
  },
  methods: {
    ...mapActions({
      search: "search/SET_SEARCH_KEY",
      setUserCookiesAcceptance: "cookiesManagement/SET_USER_ACCEPT_COOKIE",
    }),
    closeCookieBar(payload) {
      this.setUserCookiesAcceptance(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.cookie-bar {
  position: fixed;
  top: 0;
}
.with-cookie-bar {
  display: flex;
  flex-direction: column;
  padding-top: 450px;
  @media only screen and (min-width: 530px) {
    padding-top: 280px;
  }
}
</style>

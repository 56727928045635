import CognitoApiProxy from "../../services/cognito-api";
import AuthTokens from "@/models/AuthTokens";
import axios from "@/axios-instance";
import router from "../../router";

const auth = {
  namespaced: true,
  state: {
    isLoggedIn: null,
    userName: "",
    token: localStorage.getItem("tokens") || "",
    userDetails: null,
    cognitoUserDetails: null,
    user: null,
    deviceKey: null,
  },
  mutations: {
    // eslint-disable-next-line no-unused-vars
    setAuthHeader(state, token) {
      axios.defaults.headers.common["Authorization"] = token;
    },
    signOut(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.token = "";
      localStorage.clear();
      state.userDetails = null;
    },
    setIsLoggedIn(state, data) {
      state.isLoggedIn = data;
    },
    isLoggedIn(state) {
      const authService = new CognitoApiProxy();
      const isAuthenticated = authService.isAuthenticated();
      console.log("isAuth", isAuthenticated);
      state.isLoggedIn = isAuthenticated;
    },
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    setCognitoUserDetails(state, payload) {
      state.cognitoUserDetails = payload;
    },
    setUser(state, payload) {
      state.user = payload ? payload : JSON.parse(localStorage.getItem("user"));
    },
    setDeviceKey(state, payload) {
      state.deviceKey = payload;
    },
    resetState(state) {
      state.isLoggedIn = null;
      state.userName = "";
      state.token = "";
      state.userDetails = null;
      state.cognitoUserDetails = null;
      state.user = null;
      state.deviceKey = null;
    },
  },
  actions: {
    async signUp({ dispatch }, userData) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      let response;
      const authService = new CognitoApiProxy();
      response = await authService.signUp(
        userData.email, //guid
        userData.password,
        [
          { email: userData.email },
          { "custom:firstName": userData.firstName },
          { "custom:lastName": userData.lastName },
          { "custom:role": userData.role }
        ]
      );

      if (response) {
        dispatch("general/SET_DATALOADING", false, { root: true });
        return response;
      } else {
        console.log("error: ", response.error.message);
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    // eslint-disable-next-line no-unused-vars
    async saveUserToDynamoDb({ commit, dispatch }, userData) {
      const url = "/user/member";
      return new Promise((resolve, reject) => {
        axios
          .post(url, userData)
          .then((res) => {
            const result = res.data;
            console.log("result :", res);
            resolve(result);
          })
          .catch((e) => {
            console.log("error = ", e.errors[0]);
            reject(e);
          });
      });
    },
    async signIn({ commit, state, dispatch, rootState }, authDetail) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      let response;
      const authService = new CognitoApiProxy();
      response = await authService.signIn(
        authDetail.userName,
        authDetail.password
      );

      // If there's user data in response
      if (response.data) {
        const authTokens = new AuthTokens(response.data);
        commit("setIsLoggedIn", true);
        // Set accessToken
        localStorage.setItem("tokens", JSON.stringify(authTokens));
        state.token = JSON.stringify(authTokens);
        state.userName = authTokens.claims.email;
        localStorage.setItem("user", JSON.stringify(authTokens.claims));
        commit("setUser", authTokens.claims);
        commit("setCognitoUserDetails", authTokens.claims);
        // Update user details
        // commit('UPDATE_USER_INFO', response.data.userData, { root: true })

        const deviceKey = localStorage.getItem(
          `CognitoIdentityServiceProvider.${authTokens.claims.aud}.${authTokens.claims.sub}.deviceKey`
        );
        console.log("setDeviceKey", deviceKey);
        commit("setDeviceKey", deviceKey);

        // Set bearer token in axios
        commit("setAuthHeader", authTokens.idToken);

        dispatch("CONNECT_WS", null, { root: true });

        await dispatch("play/GET_ELIGIBLE_SESSIONS", null, { root: true });
        await dispatch(
          "homepage/SET_ELIGIBILITYLIST",
          rootState.homepage.highlightList,
          { root: true }
        );
        dispatch("general/SET_DATALOADING", false, { root: true });
      } else {
        response.error = { message: "Wrong Username or Password" };
        dispatch("general/SET_DATALOADING", false, { root: true });
      }
    },
    // eslint-disable-next-line no-unused-vars
    async forgotPassword(store, email) {
      const authService = new CognitoApiProxy();
      const response = await authService.forgotPassword(email);
      if (response) {
        return response;
      } else {
        throw new Error("Forgot password failed. " + response.message);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async confirmPassword({ dispatch }, { email, code, newPassword }) {
      const authService = new CognitoApiProxy();
      let response = await authService.confirmPassword(
        email,
        code,
        newPassword
      );

      if (!response) {
        //Success
        // add tagging reset password confirmation
        const payload = { email, tags: "RESET_PASSWORD_CONFIRMATION" };
        dispatch("activeCampaign/ADD_CONTACT_TAGS", payload, { root: true });
        return (response = { isSuccess: true });
      } else {
        console.log("Confirm Password", response);
        return response.message;
      }
    },
    async refreshAuthTokens({ dispatch }) {
      try {
        const tokens = await new CognitoApiProxy().checkAndRenewTokenForExpiration();

        if (tokens) {
          localStorage.setItem("tokens", JSON.stringify(tokens));
        } else {
          throw new Error("401");
        }
      } catch (err) {
        dispatch("auth/signOut");
      }
    },
    async signOut({ commit, dispatch, state }) {
      try {
        dispatch(
          "play/STOP_WATCHING_SESSSION",
          { videoId: "none" },
          { root: true }
        );

        const t = new AuthTokens(JSON.parse(localStorage.getItem("tokens")))
          .idToken;
        const dk = state.deviceKey;
        const uid = state.user.sub;
        dispatch(
          "PLAY_WS_SEND_MESSAGE",
          { action: "unregister", data: { uid, t, dk } },
          { root: true }
        );

        dispatch("DISCONNECT_WS", null, { root: true });

        new CognitoApiProxy().getCognitoUser(state.userName).signOut();
      } finally {
        dispatch("homepage/CLEAR_ELIGIBLE_DATA", null, { root: true });
        dispatch("play/RESET_STATE", null, { root: true });
        commit("resetState");
        commit("signOut");
        router.push({ name: "homepage" });
        // document.location = "/";
      }
    },
    async confirmSignup(store, { email, code }) {
      const authService = new CognitoApiProxy();
      const data = await authService.verify(email, code);
      console.log("Confirm signup: ", data);
      return data;
    },
    async isAuthenticated(commit) {
      try {
        const authService = new CognitoApiProxy();
        const isAuthenticated = authService.isAuthenticated();
        commit("setIsLoggedIn", isAuthenticated);
        return isAuthenticated;
      } catch (error) {
        return error;
      }
    },
    getUserDetailsFromDynamoDb({ commit, dispatch }, userId) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      const url = `/user/member/${userId}`;
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            commit("setUserDetails", res.data);
            resolve(res);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch((err) => {
            reject(err);
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      });
    },
    async changePassword({ dispatch }, userData) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        const authService = new CognitoApiProxy();
        authService
          .changePassword(
            userData.userId,
            userData.oldPassword,
            userData.newPassword
          )
          .then((res) => {
            console.log("Change password", res);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(res);
          })
          .catch((err) => {
            console.error("Error", err);
            dispatch("general/SET_DATALOADING", false, { root: true });
            if (err.message === "401") dispatch("autth/signOut");
            reject(err);
          });
      });
    },
    async updateUserToDynamoDb({ commit, dispatch }, userData) {
      const url = "/user/member";
      return new Promise((resolve, reject) => {
        axios
          .put(url, userData)
          .then((res) => {
            const result = res.data;
            console.log("update user : " + res);
            commit("setUserDetails", res.data);
            resolve(result);
          })
          .catch((err) => {
            console.error("error updating user", err);
            if (err.message === "401") dispatch("auth/signOut");
            reject(err);
          });
      });
    },
    async updateUserToCognito({ dispatch }, userData) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      return new Promise((resolve, reject) => {
        const authService = new CognitoApiProxy();
        authService
          .updateUserAttribute(userData.email, userData.userId, [
            { email: userData.email },
            { "custom:firstName": userData.firstName },
            { "custom:lastName": userData.lastName },
            { "custom:country": userData.country },
          ])
          .then((res) => {
            console.log("update user attr resp", res);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(res);
          })
          .catch((err) => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            console.error("update user attr error", err);
            if (err.message === "401") dispatch("auth/signOut");
            reject(err);
          });
      });
    },
    async deleteUserFromCognito({ dispatch }, userData) {
      dispatch("general/SET_DATALOADING", true, { root: true });
      //let response;
      return new Promise((resolve, reject) => {
        const authService = new CognitoApiProxy();
        authService
          .deleteUser(userData.userId, userData.email, userData.password)
          .then((res) => {
            console.log("delete user from cognito", res);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(res);
          })
          .catch((err) => {
            console.error("Error", err);
            dispatch("general/SET_DATALOADING", false, { root: true });
            if (err.message === "401") dispatch("auth/signOut");
            reject(err);
          });
      });

      // if (response) return response;
      // else console.log("Error", response.error.message);
    },
    async deleteUserFromDynamoDb({ commit, dispatch }, userId) {
      const url = `/user/member/${userId}`;
      return new Promise((resolve, reject) => {
        axios
          .delete(url)
          .then((res) => {
            const result = res.data;
            console.log("delete user : " + res);
            commit("setUserDetails", res.data);
            resolve(result);
          })
          .catch((e) => {
            console.error("error delete user", e);
            if (e.message === "401") dispatch("auth/signOut");
            reject(e);
          });
      });
    },
    async globalSignout() {
      try {
        const url = "/user/member/logout-all-devices";
        await axios.post(url);
      } catch (err) {
        console.error(err);
      }
    },
    async saveContactId(_store, payload) {
      const url = "/user/member/activecampaign-contact";
      return axios.put(url, payload);
    },
    async verifyUser({ commit }, userData) {
      const url = "/user/member/verifyUser";
      return new Promise((resolve, reject) => {
        axios
          .put(url, userData)
          .then((res) => {
            const result = res.data;
            commit("setUserDetails", res.data);
            resolve(result);
          })
          .catch((e) => {
            console.error("error verify user", e);
            reject(e);
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => {
      try {
        console.log("*** isLoggedIn", state.isLoggedIn);
        return state.isLoggedIn;
        // const rawTokens = JSON.parse(localStorage.getItem("tokens")) || {};
        // const tokens = Object.keys(rawTokens).length > 0  ? rawTokens : {};
        // const { idToken } = tokens;

        // // if (tokens) {
        // //   const { idTokenExpiry = Date.now() } = tokens || {};
        // //   if (idTokenExpiry - 120 <= Date.now() / 1000) {

        // //     tokens = await new CognitoApiProxy().checkAndRenewTokenForExpiration()

        // //     if (tokens) {
        // //       localStorage.setItem("tokens", JSON.stringify(tokens));
        // //     } else {
        // //       localStorage.clear();
        // //       return false;
        // //     }
        // //   }

        // //   return tokens != null;
        // // }

        // state.isLoggedIn = idToken != null;
        // return state.isLoggedIn;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    getUser(state) {
      return state.user || JSON.parse(localStorage.getItem("user"));
    },
    getAuthTokens() {
      return new AuthTokens(JSON.parse(localStorage.getItem("tokens")));
    },
    getIdToken() {
      try {
        const tokensString = localStorage.getItem("tokens");

        if (!tokensString) return null;
        // throw new Error("tokens not found in local storage");

        return new AuthTokens(JSON.parse(tokensString)).idToken;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    getUserDetails(state) {
      return state.userDetails;
    },
    getDeviceKey(state) {
      return state.deviceKey;
    },
    getUserId(state) {
      return state.user.sub;
    },
  },
};

export default auth;

<template>
  <section class="js-horizontal-collapse horizontal-collapse">
    <ul class="horizontal-collapse__inner">
      <HorizontalCollapseItem
        v-for="(highlightItem, index) in highlightList"
        :className="
          ('horizontal-collapse__item--' + index, setItemWidth(index, highlightList.length))
        "
        :key="highlightItem.title"
        :current-index="index"
        :slug="highlightItem.slug"
        :totalItem="highlightList.length"
        :image-background="
          highlightIndex === index
            ? resolveCdnPath(highlightItem.artworks.featured_lg)
              ? resolveCdnPath(highlightItem.artworks.featured_lg)
              : defaultArtworks.featured_lg
            : resolveCdnPath(highlightItem.artworks.featured_sm) || defaultArtworks.featured_sm
        "
      >
        <div
          class="horizontal-collapse__inactive-content"
          @click="clickWatchNowButton(false, highlightItem, index)"
        >
          <transition name="slide">
            <div v-if="highlightIndex !== index">
              <h3 class="horizontal-collapse__heading">
                {{ shorten(highlightItem.title) }}
              </h3>
              <MdButton class="n8-btn md-raised">
                <span class="btn-label icon">Info</span>
              </MdButton>
            </div>
          </transition>
        </div>
        <div class="horizontal-collapse__active-content">
          <transition name="slide">
            <div v-if="highlightIndex === index">
              <div class="content summary-container">
                <div
                  class="summary-section"
                  :class="highlightItem.status !== 'released' ? `comming-soon` : ``"
                >
                  <router-link
                    :to="{
                      name: 'showdetails',
                      params: { id: highlightItem.slug }
                    }"
                  >
                    <h3 class="heading">
                      <strong>{{ highlightItem.title }}</strong>
                    </h3>
                  </router-link>
                  <p class="note">
                    {{
                      highlightItem.synopsis
                        ? `${highlightItem.synopsis.substring(0, 200)}...`
                        : highlightItem.title
                    }}
                  </p>
                  <p class="location" v-if="highlightItem.status === 'released'">
                    Showing at {{ highlightItem.venue.name }}.
                    {{ displayDateformat(highlightItem.createdDate) }}
                  </p>
                  <p class="location" v-else>
                    <!-- Coming soon. -->
                  </p>
                </div>
                <div class="buy-section" v-if="highlightItem.status === 'released'">
                  <div class="price">
                    <span class="currency">{{ currency }}</span>
                    <span>{{ includeTax(highlightItem.price) | priceFormat }}</span>
                  </div>
                  <div class="underline-dash"></div>
                  <MdButton
                    class="n8-btn md-raised"
                    :class="{ disabled: showWatchPanel }"
                    @click="clickWatchNowButton(true, highlightItem, index)"
                    :disabled="isLoadingPlayer"
                  >
                    <span class="btn-label icon">
                      <span v-if="isLoadingPlayer">Loading...</span>
                      <span v-else>Watch Now</span>
                    </span>
                  </MdButton>
                </div>
                <div class="buy-section" v-if="highlightItem.status === 'comingsoon'">
                  <div class="price" style="visibility: hidden">
                    <span class="currency">{{ currency }}</span>
                    <span>{{ includeTax(highlightItem.price) | priceFormat }}</span>
                  </div>
                  <div class="underline-dash" style="visibility: hidden"></div>
                  <MdButton
                    class="n8-btn md-raised mt-3"
                    :class="{ disabled: showWatchPanel }"
                    @click="openDetailPage(highlightItem.slug)"
                  >
                    <span class="btn-label icon">
                      <span>Coming Soon</span>
                    </span>
                  </MdButton>
                </div>
              </div>
              <md-dialog
                :md-active="showWatchPanel"
                class="registration-container"
                :class="membershipDisplay == 'PAYMENT' ? 'payment-dialog' : ''"
              >
                <md-dialog-actions class="login-popup-action">
                  <span
                    class="icon-close fa fa-times"
                    @click="clickWatchNowButton(false, highlightItem, index)"
                  ></span>
                </md-dialog-actions>
                <md-dialog-content class="login-popup-content">
                  <MembershipForm
                    :hl-item="highlightItem"
                    :isActive="highlightIndex === index"
                    v-if="showWatchPanel"
                    @closeFormSection="closeFormSection"
                  ></MembershipForm>
                </md-dialog-content>
              </md-dialog>
            </div>
          </transition>
        </div>
      </HorizontalCollapseItem>
    </ul>

    <div class="col-12 mobile-carousel" v-if="highlightList.length > 0">
      <Slider :list="highlightList" :responsive="sliderResponsiveSetting" />
      <div class="underline-dash-gold mt-4"></div>
    </div>

    <payment-vr-modal :isShow="showVrPaymentDialog" @onCloseModal="closeVrPaymentDialog" />
  </section>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
// import { buildVideoJson } from "@/helper/videoDataHelper.js";
import getSymbolFromCurrency from "currency-symbol-map";
import HorizontalCollapseItem from "./HorizontalCollapseItem";
import MembershipForm from "@/components/membership/index";
import PaymentVrModal from "@/components/modal/PaymentVrModal";
import Slider from "@/components/slider";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import UrlHelper from "../../helper/url-helper";

var mobile = require("is-mobile");
const urlHelper = new UrlHelper();

export default {
  name: "HorizontalCollapse",
  props: {
    className: {
      type: String,
      default: ""
    },
    highlightList: Array,
    eligibilityList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    HorizontalCollapseItem,
    MembershipForm,
    PaymentVrModal,
    Slider
  },
  data() {
    return {
      selected: null,
      showWatchPanel: false,
      defaultArtworks: {
        featured_lg: "/img/mock/feature-lg-default.png",
        featured_sm: "/img/mock/feature-sm-default.png"
      },
      sliderResponsiveSetting: [
        {
          breakpoint: 920,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      epochNow: Date.now(),
      isLoadingPlayer: false,
      showVrPaymentDialog: false
    };
  },
  watch: {
    showPanel(val) {
      this.showWatchPanel = val;
    },
    epochNow: {
      // this is just to make it intervally loop
      handler(value) {
        if (value > 0) {
          /*
          setTimeout(() => {
            this.$store.dispatch(
              "homepage/SET_ELIGIBILITYLIST",
              this.highlightList
            );
            this.epochNow = Date.now();
          }, 60000);
          */
        }
      },
      immediate: true
    }
  },
  methods: {
    shorten(title) {
      if (title.length > 30) {
        return title.substring(0, 30) + " ...";
      }

      return title;
    },
    clickWatchNowButton(isShow, showObj, index) {
      if (isShow) {
        const isEligible = this.$store.getters["play/isEligible"](showObj.id);
        if (isEligible) {
          // const mainVidJson = buildVideoJson(showObj);
          // this.$store.dispatch("showDetails/SET_MAINVIDEO", mainVidJson);
          this.watchNow(showObj);
          return;
        }
      }

      // not eligible. set active and show payment panel
      // if vr browser, show dialog error
      if (this.isVrBrowser && this.highlightIndex == index) {
        this.showVrPaymentDialog = true;
      } else {
        this.$store.dispatch({
          type: "homepage/SET_HIGHLIGHT_INDEX",
          data: index
        });

        this.$store.dispatch({
          type: "homepage/SET_WATCH_PANEL_DISPLAY",
          data: isShow
        });

        this.showWatchPanel = this.showPanel;
      }

    },
    closeVrPaymentDialog() {
      this.showVrPaymentDialog = false;
    },
    async watchNow(showObj) {
      let data = await this.$store.dispatch("showDetails/GET_SHOW", showObj.id);
      this.$store.dispatch({ type: "showDetails/SET_SHOW", data: data });
      this.isLoadingPlayer = true;

      if (mobile() && !this.isVrBrowser) {
        this.$router.push({
          name: "watch",
          params: {
            id: showObj.slug,
            videoId: showObj.id,
            deeplinkUrl: showObj.deeplinkUrl
          },
          query: { deferred: true }
        });
      } else {
        this.$router.push({
          name: "watch",
          params: {
            id: showObj.slug,
            videoId: showObj.id
          }
        });
      }

      this.isLoadingPlayer = false;
    },

    onItemClick(item) {
      //console.log(['selected item', item]);
      this.selected = item.id;
    },
    resolveCdnPath(path) {
      return urlHelper.resolveCdnPath(path);
    },
    getImgUrl(item) {
      return item.artworks && item.artworks.featured_lg
        ? this.resolveCdnPath(item.artworks.featured_lg)
        : this.resolveCdnPath(this.featured_lg);
    },
    displayDateformat(date) {
      return moment(date).format("MMMM yyyy");
    },
    gotoDetailPlayer(slug) {
      this.$store.dispatch("showDetails/SET_OPENPLAYER", true, {
        root: true
      });
      this.$router.push({
        path: `/shows/${slug}`
      });
    },
    openDetailPage(slug) {
      this.$router.push({
        name: "showdetails",
        params: { id: slug }
      });
    },
    closeFormSection() {
      this.showWatchPanel = false;
    },
    setItemWidth: function(index, totalItems) {
      if (totalItems == 1) return "full-width";
      else if (totalItems <= 3 && totalItems >= 3 && this.highlightIndex != index)
        return "medium-width";
      else if (totalItems <= 2 && totalItems >= 2) {
        if (this.highlightIndex != index) return "large-width";
        else return "xlarge-width";
      }
    }
  },
  computed: {
    ...mapState({
      highlightIndex: state => state.homepage.highlightIndex,
      showPanel: state => state.homepage.isShowWatchPanel,
      featuresEligibilityList: state => state.homepage.featuresEligibilityList,
      highlightVideoData: state => state.homepage.highlightVideoData,
      mainVideoJson: state => state.showDetails.mainVideoJson,
      membershipDisplay: state => state.homepage.membershipDisplay
    }),
    currency() {
      return getSymbolFromCurrency("GBP");
    },
    isVrBrowser() {
      return ["OculusBrowser", "SamsungBrowser", "MobileVR", "Mobile VR", "VR"].some(item => {
        return window.navigator.userAgent.indexOf(item) > -1;
      });
    }
  },
  beforeDestroy() {
    this.epochNow = null;
  }
};
</script>

<style lang="scss" scoped>
$transitionDurationMs: 0.5s;
.horizontal-collapse {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3rem;
  color: #fff;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  &__inner {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  &__item {
    min-height: 42rem;
    list-style: none;
    padding: 0;
    overflow: hidden;
    min-width: 16.5%;
    // max-width: 50%;
    position: relative;
    transition: width $transitionDurationMs;
    background-size: cover;
    background-position: center;
    background-color: transparent;

    &.is-active {
      // color: #fff;
      .horizontal-collapse__inactive-content {
        opacity: 0;
        // bottom: 0rem;
        // left: 0rem;
      }
      .horizontal-collapse__active-content {
        opacity: 1;
      }
    }
  }
  &__item-inner {
    // padding: 20px;
    border-radius: 25px;
  }
  &__inactive-content {
    opacity: 1;
    transition: opacity $transitionDurationMs;
    position: absolute;
    .horizontal-collapse__heading {
      user-select: none;
      cursor: default;
    }
  }
  &__active-content {
    opacity: 0;
    // padding: 20px;
    transition: opacity $transitionDurationMs;
    cursor: default;
  }
  &__heading {
    margin-top: 0;
    margin-bottom: 2rem;
  }
  &__body {
    margin-bottom: 2rem;
  }
  &__link {
    display: inline-block;
    background-color: #fff;
    color: #333;
    text-decoration: none;
    line-height: 1;
    padding: 1rem 2rem;
    border-radius: 2rem;
    font-size: 1.7rem;
    font-weight: bold;
  }
}
</style>

import axios from "axios";
import AuthTokens from "@/models/AuthTokens";
import CognitoApiProxy from "../services/cognito-api";


const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  async config => {

    if (config.baseURL.indexOf(".boxofficevr.com") > -1) {
      const rawTokens = JSON.parse(localStorage.getItem("tokens"));

      if(!rawTokens)
        return config;

      let tokens = Object.keys(rawTokens).length > 0  ? new AuthTokens(rawTokens) : null;

      if (tokens) {
        // console.log(tokens.idTokenExpiry - 120 <= Date.now() / 1000 ? "token should be renew" : "token still alive");

        if (tokens.idTokenExpiry - 120 <= Date.now() / 1000) {
          console.log("renewing token");

          try {
            const newTokens = await new CognitoApiProxy().checkAndRenewTokenForExpiration();

            if (newTokens) {
              console.log("tokens renewed");
              localStorage.setItem("tokens", JSON.stringify(newTokens));
              tokens = newTokens;
            }
          } catch(err) {
            console.error(err);
            localStorage.clear();
            window.location.href = "/login";
            return;
          }
        }

        const deviceKey = localStorage.getItem(`CognitoIdentityServiceProvider.${tokens.claims.aud}.${tokens.claims.sub}.deviceKey`) || null;

        config.headers.common["Authorization"] = tokens.idToken || "";

        if (config.url.toLowerCase().indexOf('/play/') > -1 && deviceKey)
        config.headers.common["X-BOVR-DeviceKey"] = deviceKey;

        return config;
      }

      window.location.href = "/login";
      return;
    }

    return config;
  }
);

instance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  console.log(JSON.stringify(error));
  if (error.message.indexOf("401") > -1 && !originalRequest._retry && error.config.baseURL.indexOf(".boxofficevr.com") > -1) {
    originalRequest._retry = true;
    var accessToken = "";
    try {
      const newTokens = await new CognitoApiProxy().checkAndRenewTokenForExpiration();

      if (newTokens) {
        console.log("tokens renewed");
        localStorage.setItem("tokens", JSON.stringify(newTokens));
        accessToken = newTokens.idToken;
      }
    } catch(err) {
      console.error(err);
      localStorage.clear();
      window.location.href = "/login";
      return;
    }
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    return instance(originalRequest);
  }
  return Promise.reject(error);
});

export const http_mocks = axios.create({
  baseURL: "/data",
});

export default instance;

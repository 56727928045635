var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forgot-password-container"},[_c('div',{staticClass:"col-12 text-left"},[_c('h1',{staticClass:"font-22 text-gold font-weight-bold mb-4"},[_vm._v("Forgot your password?")]),_c('p',{staticClass:"sub-heading"},[_vm._v(" Enter your email address and we'll send you a link to reset your password. ")]),_c('validation-observer',{ref:"forgotPasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''},[_c('label',{attrs:{"for":"input-email"}},[_vm._v("Email address *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"email","id":"input-email","aria-describedby":"emailHelp","placeholder":"Email address"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit($event)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('MdButton',{staticClass:"n8-btn n8-btn-gray light-theme md-raised mt-5",class:invalid ? "disabled" : "",on:{"click":_vm.submit}},[_c('span',{staticClass:"btn-label"},[_vm._v("Reset password")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
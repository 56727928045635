<template>
  <form class="form-inline my-2 my-lg-0 search-container">
    <input
      ref="searchfield"
      class="form-control mr-sm-2 search-field"
      type="search"
      placeholder="Search"
      aria-label="Search"
      v-model="searchTxt"
      autofocus
    />
    <router-link
      :to="{ name: 'search', query: { search: searchTxt } }"
      tag="button"
      class="btn btn-outline-success my-0"
      @click.native="onSearch"
      ><i class="fa fa-search"></i
    ></router-link>
  </form>
</template>

<script>
export default {
  data() {
    return {
      searchTxt: ""
    };
  },
  methods: {
    onSearch: function() {
      // this.$store.dispatch({
      //   type: "general/SET_SEARCH_RESULT",
      //   search: this.searchTxt,
      //   data: this.filteredData,
      // });
      this.$store.dispatch({
        type: "search/SET_SEARCH_KEY",
        searchKey: this.searchTxt
      });

      this.$store.dispatch({
        type: "search/SEARCH"
      });
    }
  }
};
</script>

<style lang="scss">
.search-container {
  width: 40px;
  transition: all 0.3s;
}
.search-field {
  opacity: 0;
}
.search-container.active,
.search-container:hover {
  width: 100%;

  .search-field {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {
  .search-field {
    opacity: 1;
  }
  .search-container {
    width: auto;
  }
  .search-container:hover {
    width: auto;

    .search-field {
      opacity: 1;
    }
  }
}
</style>

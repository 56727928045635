<template>
  <div class="description-container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-heading gold" v-if="!isMobileView">{{ data.name }}</h1>
        <h4 class="page-heading gold" v-else>{{ data.name }}</h4>
        <h2 class="subheading venue-location" v-if="!isMobileView">
          {{ data.city }}, {{ data.country }}
        </h2>
      </div>
      <div class="col-md-7 col-lg-4 pr-xl-5 paragraph-container">
        <h4 v-if="!isMobileView">Description</h4>
        <p ref="venueDescription" class="description-paragraph" :class="expanded ? 'expand' : ''">
          {{ data.description }}
        </p>
        <span v-if="showReadmore" class="cursor-pointer text-uppercase" @click="expandDescription">
          <u>
            {{ expanded ? "Read less" : "Read more" }}
          </u>
        </span>
      </div>
      <div class="col-md-5 col-lg-4 pl-xl-5 pr-xl-5">
        <div class="mb-3">
          <h4>Website</h4>
          <p>{{ data.website }}</p>
        </div>

        <div class="mb-3">
          <h4>Contact</h4>
          <p class="mb-0">{{ data.email }}</p>
          <p>{{ data.contact }}</p>
        </div>

        <div class="mb-3">
          <h4>Address</h4>
          <p class="mb-0">{{ data.address }}</p>
          <p>{{ data.state }}, {{ data.postCode }}, {{ data.country }}</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 text-md-right">
        <google-map
          id="map"
          ref="map"
          v-if="!dataLoading"
          :location="venueLocation"
          :key="data.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GoogleMap from "@/components/map/GoogleMap";
export default {
  props: ["data", "isMobileView"],
  computed: {
    ...mapState({
      dataLoading: state => state.general.dataLoading
    }),
    venueLocation: function() {
      return {
        lat: this.data.latitude ? parseFloat(this.data.latitude) : null,
        lng: this.data.longitude ? parseFloat(this.data.longitude) : null
      };
    }
  },
  data() {
    return {
      expanded: false,
      showReadmore: false
    };
  },
  watch: {
    data(value) {
      if (value && value.description) {
        this.$nextTick(() => {
          this.resolveDescriptionHeight();
        });
      }
    }
  },
  methods: {
    expandDescription() {
      this.expanded = !this.expanded;
    },
    resolveDescriptionHeight() {
      const elementP = this.$refs.venueDescription;
      if (elementP) {
        if (elementP.offsetHeight < elementP.scrollHeight) {
          this.showReadmore = true;
        } else this.showReadmore = false;
      } else this.showReadmore = false;
    }
  },
  mounted() {
    this.resolveDescriptionHeight();
  },
  components: {
    GoogleMap
  }
};
</script>

<style lang="scss" scoped>
.subheading {
  margin-bottom: 40px;
}
.description-container {
  p {
    font-size: 14px;
    line-height: 19.6px;
    white-space: pre-line;
  }
}
</style>

<template>
  <div class="video-page-container" @mousemove="resetInterval" v-touch:tap="resetInterval">
    <small-loader class="d-block mx-auto" v-if="dataLoading" />
    <div class="player-container" :class="dataLoading ? `hide` : ``">
      <button class="btn back-button pl-0" @click="closePage" v-if="!hideVideoButtons">
        <i class="icon-n8 icon-back" /><span>Back</span>
      </button>
      <upen-player
        ref="upenPlayer"
        :trailerUrl="trailerUrl"
        :promoVideoJson="promoVideoJson"
        :mainVideoJson="mainVideoJson"
        :video-control-show="!hideVideoButtons"
        :show="playerOpen"
      ></upen-player>

      <!-- video-url="https://testvideosfromneon8.s3-ap-southeast-2.amazonaws.com/Little+Match+Girl+vr180.mp4" -->
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import SmallLoader from "@/components/SmallLoader";
import UpenPlayer from "@/components/videoplayer/UpenPlayer";
import MetaInfo from "@/mixins/MetaInfo";

export default {
  mixins: [MetaInfo],
  components: {
    UpenPlayer,
    SmallLoader
  },
  props: {
    //
  },
  data() {
    return {
      meta: [{ description: "" }],
      id: null,
      interval: 0,
      pauseVideo: false
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.updateDiffs();
    }, 1000);
    this.updateDiffs();
  },
  computed: {
    ...mapState({
      show: state => state.showDetails.showData,
      dataLoading: state => state.general.dataLoading,
      isLoggedIn: state => state.auth.isLoggedIn,
      trailerUrl: state => state.showDetails.trailerUrl,
      playerOpen: state => state.showDetails.playerOpen,
      isTrailer: state => state.showDetails.isTrailer,
      promoVideoJson: state => state.showDetails.promoVideoJson,
      mainVideoJson: state => state.showDetails.mainVideoJson
    }),
    ...mapGetters(["showDetails/getShow"]),
    title() {
      return this.show.title; // for html title
    },
    hideVideoButtons() {
      return this.interval > 3; // 3 seconds mouse idle
    }
  },
  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      console.log(orientation);
      if (orientation === "portrait-primary") {
        // portrait mode.
      } else if (orientation === "landscape-primary") {
        // landscape mode
      }
    },
    closePage() {
      // this.$emit("onClose");
      const wasTrailer = this.isTrailer;

      this.$refs.upenPlayer.onStop();
      this.$store.dispatch("showDetails/SET_ISTRAILER", false);
      this.$store.dispatch("showDetails/SET_OPENPLAYER", false);

      this.$store.dispatch(
        "play/STOP_WATCHING_SESSSION",
        { videoId: this.show.id },
        { root: true }
      );
      this.$store.dispatch("general/SET_MODALSHOW", false);

      if (!wasTrailer) {
        // trailer open in same tab, so no need go back
        this.$router.go(-1);
      }
    },
    updateDiffs() {
      this.interval++;
    },
    resetInterval() {
      this.interval = 0;
    }
  },
  beforeDestroy() {
    console.log("show", this.show);
    if (this.show && this.show.id) {
      this.$store.dispatch(
        "play/STOP_WATCHING_SESSSION",
        { videoId: this.show.id },
        { root: true }
      );
    }
  }
};
</script>
<style lang="scss">
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.video-page-container {
  background: black;
  margin: 0 -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.btn.back-button {
  color: white !important;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  top: 20px;
  left: 10px;
  line-height: 26px;
  z-index: 2;
  @include breakpoint("medium") {
    top: 45px;
    left: 65px;
  }
}
.player-container {
  width: 100%;
  height: 100%;
}
.hide {
  opacity: 0;
}
</style>

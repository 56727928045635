<template>
  <div class="emailinquiry col-lg-8 col-md-10 col-sm-12">
    <div class="row">
      <h1 class="col-12 page-enquiries-title">
        We'd love to hear from you
        <div class="border-bottom"></div>
      </h1>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12 inquiry-intro">
        <p>
          Have an enquiry or have feedback for us? Fill out the form to contact our team and we'll
          get back to you as soon as we can.
        </p>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="_form_3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import MetaInfo from "@/mixins/MetaInfo";
import { mapActions } from "vuex";

export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "Email Inquiry",
      meta: [{ description: "" }]
    };
  },
  mounted() {
    let activeCampaignScript = document.createElement("script");
    activeCampaignScript.setAttribute("charset", "utf-8");
    activeCampaignScript.setAttribute("type", "text/javascript");
    activeCampaignScript.setAttribute(
      "src",
      "https://boxofficevr.activehosted.com/f/embed.php?id=3"
    );
    document.head.appendChild(activeCampaignScript);
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    })
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
.emailinquiry {
  margin: 0 auto;
}
.page-enquiries-title {
  color: $gold;
  padding-bottom: 40px;
  position: relative;
  font-weight: bold;
}
.border-bottom {
  position: absolute;
  border-bottom: 1px solid $gold !important;
  width: 150px;
  bottom: 0;
}
.inquiry-intro {
  padding-top: 30px;
}

._field4 select {
  padding: 10px 5px;
}
@media screen and (max-width: 767px) {
  .page-enquiries-title {
    background-color: #332f2c;
    padding-bottom: 10px;
    font-size: 28px;
    margin: 0;
    padding-top: 10px;
    text-transform: capitalize;
  }
  .inquiry-intro {
    background-color: #332f2c;
    padding: 20px;
  }
}
</style>

<style lang="scss">
/* AC form render async. when it doesnt find div._form_3, it renders on the bottom. if this happen, hide it */
form._form_3 {
  display: none;
}

div._form_3 form._form_3 {
  display: block;
}

._form_3 ._field4 select {
  padding: 10px 5px !important;
}
._form_3 ._form-title {
  display: none !important;
}
._form_3 form {
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 20px !important;
}
.g-recaptcha {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}
@media screen and (max-width: 767px) {
  ._form_3 form {
    width: 100% !important;
  }
}
</style>

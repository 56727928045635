<template>
  <div class="login-page-container">
    <div class="inner">
      <LoginForm></LoginForm>
    </div>
  </div>
</template>
<script>
import LoginForm from "@/components/membership/LoginForm";
import MetaInfo from "@/mixins/MetaInfo";
import { mapActions } from "vuex";

export default {
  mixins: [MetaInfo],
  components: {
    LoginForm,
  },
  data() {
    return {
      title: "Login",
      meta: [{ description: "this is meta description" }],
    };
  },
  computed: {},
  mounted() {
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: "",
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS",
    }),
  },
};
</script>

<style lang="scss">
@import "~@/assets/sass/base/mixins";
@import "~@/assets/sass/base/variables";
.login-page-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
  .line {
    margin: 20px 0;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: " ";
      border-top: 1px solid $grey;
      width: 44%;
      margin-right: 20px;
    }
    &:after {
      content: " ";
      border-top: 1px solid $grey;
      width: 44%;
      margin-left: 20px;
    }
  }
}
</style>

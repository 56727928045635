<template>
  <div class="similar mb-4">
    <h2 class="mb-4">
      <strong>Similar {{ title }}</strong>
    </h2>
    <div class="row">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="classname"
        v-on:click="onItemClick(item)"
        class="cursor-pointer"
      >
        <InfoBox
          :id="item.id"
          :img="item.img"
          :title="item.title"
          :subtitle="item.subtitle"
          :bottomText="item.bottomText"
          :routeToDetail="routeToDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoBox from "@/components/shared/InfoBox";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    routeToDetail: {
      type: String,
      default: "venuesDetail",
    },
    perRow: {
      type: Number,
      default: 3,
    },
  },
  components: {
    InfoBox,
  },
  computed: {
    classname() {
      let classname = "col-md-6 col-lg-4";

      switch (this.perRow) {
        case 2:
          classname = "col-md-6 col-lg-6";
          break;
        case 3:
          classname = "col-md-6 col-lg-4";
          break;
        case 4:
          classname = "col-md-6 col-lg-3";
          break;
        default:
          classname = "col-md-6 col-lg-4";
          break;
      }
      return classname;
    },
  },
  methods: {
    onItemClick(item) {
      this.$router
        .push({
          name: item.routeToDetail,
          params: { id: item.id },
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
.similar-container {
  float: left;
  margin-right: 15px;
}
h5 {
  font-family: "Playfair-bold";
}
</style>

<template>
  <div class="cookie-policy">
    <h2 style="padding-bottom: 30px;">COOKIE POLICY</h2>
    <NestedList :list="list" />
  </div>
</template>
<script>
import NestedList from "@/components/shared/NestedList";
import MetaInfo from "@/mixins/MetaInfo";
import { mapActions } from "vuex";

export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "Cookie Policy [TBD]"
    };
  },
  mounted() {
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  components: {
    NestedList
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    })
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/sass/base/variables";
h2 {
  color: $gold;
  text-align: center;
}
</style>

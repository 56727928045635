import { mapGetters } from "vuex";

const metaInfo = {
  computed: {
    ...mapGetters({
      metaData: "general/getMetaTags"
    })
  },
  metaInfo() {
    return {
      title: `Box Office VR - ${this.metaData.pageTitle || ""}`,
      meta: [
        {
          id: "description",
          name: "description",
          content: this.metaData.metaDescription || ""
        },
        {
          id: "title",
          name: "title",
          content: this.metaData.metaTitle || this.metaData.pageTitle
        }
        // any other metas
      ]
    };
  }
};

export default metaInfo;

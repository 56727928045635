<template>
  <div class="now-showing mb-5">
    <h3 class="mb-4">Now Showing</h3>
    <InfoBox
      :id="id"
      :routeToDetail="routeToDetail"
      :img="img"
      :title="title"
      :subtitle="subtitle"
    />
  </div>
</template>

<script>
import InfoBox from "@/components/shared/InfoBox";
import UrlHelper from "../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      routeToDetail: "venuesDetail"
    };
  },
  computed: {
    id() {
      return this.item.id;
    },
    title() {
      return this.item.name;
    },
    subtitle() {
      return `${this.item.city}, ${this.item.country}`;
    },
    img() {
      let mediaFile =
        this.item.mediaFiles && this.item.mediaFiles.find(item => item.key == "thumbnail_small")
          ? this.item.mediaFiles.find(item => item.key == "thumbnail_small").value
          : null;

      if (mediaFile) mediaFile = urlHelper.resolveCdnPath(mediaFile);

      return mediaFile;
    }
  },
  components: {
    InfoBox
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
h3,
h5 {
  font-family: "Playfair-bold";
  color: $gold;
}
</style>

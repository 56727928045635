var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-container",class:_vm.fromLoginPage ? 'background-login' : ''},[_c('div',{staticClass:"panel-switch-login"},[(!_vm.fromLoginPage)?_c('div',{staticClass:"text-left info-title font-weight-bold"},[_c('span',[_vm._v("Log In")])]):_c('h1',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Log In")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''},[_c('label',{attrs:{"for":"input-email"}},[_vm._v("Email address *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"email","name":"input-email","aria-describedby":"emailHelp","placeholder":"Email address"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logIn($event)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"form-group mb-0"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:errors[0] || (invalid && dirty & changed) ? 'md-invalid' : '',attrs:{"md-toggle-password":false}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v("Password *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"password","name":"input-password","aria-describedby":"emailHelp","placeholder":"Password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logIn($event)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.errorMsg)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e()],1),_c('div',{staticClass:"text-left lnkForgotPassword mt-3",on:{"click":function($event){return _vm.toggleDisplayState('FORGET')}}},[_c('span',[_vm._v("Forgot Your Password?")])]),_c('MdButton',{staticClass:"n8-btn n8-btn-gray light-theme md-raised",class:invalid || _vm.loading ? "disabled" : "",attrs:{"disabled":_vm.loading},on:{"click":_vm.logIn,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.logIn($event)}}},[_c('span',{staticClass:"btn-label"},[_vm._v(_vm._s(_vm.loading ? "loading..." : "Log In"))])]),(_vm.fromLoginPage)?[_c('div',{staticClass:"line"},[_vm._v("or")]),_c('MdButton',{staticClass:"n8-btn n8-btn-black gold-theme md-raised",attrs:{"href":"/signup"}},[_c('span',{staticClass:"btn-label"},[_vm._v("Sign Up")])])]:_vm._e()],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="mobile">
      <div v-if="list.length > 0">
        <div
          class="slider-container cursor-pointer"
          v-for="(item, index) in list"
          :key="index"
          :class="selected == item.id ? ' active' : ''"
          @mouseenter="setActiveItem(item.id)"
          @mouseleave="unsetActiveItem"
          @click="gotoDetail(item.id)"
        >
          <InfoBox
            class="white-text venue-item pb-4"
            :id="item.id"
            :img="item.img"
            :title="item.name"
            :subtitle="`${item.city}, ${item.country}`"
            routeToDetail="venuesDetail"
          />
          <div class="underline-dash-gold"></div>
        </div>
      </div>
      <div v-else>
        <div v-for="index in 8" :key="index" class="slider-container">
          <div class="slider-container">
            <img src="" class="slider-img slider-img-default" />
          </div>
        </div>
      </div>
    </div>
    <div class="desktop">
      <div v-if="list.length > 0" class="venue-slider">
        <VueSlickCarousel
          v-bind="carouselSettings"
          ref="carouselDynamic"
          @init="onInitCarousel"
        >
          <div
            class="slider-container cursor-pointer"
            v-for="(item, index) in list"
            :key="index"
            :class="selected == item.id ? ' active' : ''"
            @mouseenter="setActiveItem(item.id)"
            @mouseleave="unsetActiveItem"
            @click="gotoDetail(item.id)"
          >
            <InfoBox
              class="white-text venue-item"
              :id="item.id"
              :img="item.img"
              :title="item.name"
              :subtitle="`${item.city}, ${item.country}`"
              routeToDetail="venuesDetail"
            />
          </div>
        </VueSlickCarousel>
      </div>
      <div v-else>
        <VueSlickCarousel
          v-bind="carouselSettings"
          ref="carouselDynamic"
          @init="onInitCarousel"
        >
          <div v-for="index in 8" :key="index" class="slider-container">
            <div class="slider-container">
              <img src="" class="slider-img slider-img-default" />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
/*
 * DOC:
 * >> Expected List Structure:
 *    - TBD - (Make it generic via dynamic props)
 */

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import InfoBox from "@/components/shared/InfoBox";

export default {
  data() {
    return {
      carouselSettings: {
        arrows: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        lazyLoad: "progressive",
        //focusOnSelect: true,
        responsive: [
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 720,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
      selected: undefined,
    };
  },
  props: {
    list: Array,
    customClasses: {
      type: String,
      default: "",
    },
  },
  components: {
    VueSlickCarousel,
    InfoBox,
  },
  beforeMount() {
    // console.log("sliderData", this.list);
  },
  methods: {
    onInitCarousel() {
      //console.log('our carousel is ready');
    },
    gotoDetail(itemId) {
      this.$router.push({
        name: "venuesDetail",
        params: { id: itemId },
      });
    },
    setActiveItem(itemId) {
      this.selected = itemId;
    },
    unsetActiveItem() {
      this.selected = null;
    },
    getImgUrl(id) {
      var images = require.context("@/assets/images/mock/", false, /\.png$/);
      return images("./" + id + ".png");
    },
  },
};
</script>
<style lang="scss" scoped>
.n8-gradient-black-transparent {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.slick-slider {
  margin: 0 -10px;
}
.slider-container {
  padding: 20px 10px;
  margin: 0;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 480px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}
</style>

<template>
  <div class="container" :class="classname">
    <div class="top-dark-bg" v-bind:style="{ height: topDarkBgHeight + 'px' }"></div>
    <div class="bottom-dark-bg" v-bind:style="{ height: bottomDarkBgHeight + 'px' }"></div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex flex-column min-vh-100">
          <main-navbar />
          <router-view class="flex-grow-1" :key="$route.fullPath" />
          <footer-page />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MainNavbar from "@/components/navbar/MainNavbar";
import FooterPage from "@/components/navbar/Footer";

export default {
  created() {
    this.$store.dispatch("purchaseSettings/GET_PURCHASE_SETTINGS");
    this.setkBackgroundColor();
  },
  computed: {
    ...mapState({
      topDarkBgHeight: state => state.ui.topDarkBgHeight,
      bottomDarkBgHeight: state => state.ui.bottomDarkBgHeight
    }),
    bodyClass() {
      return this.$route.meta.cssClass ? this.$route.meta.cssClass : "";
    },
    classname() {
      let classname = "";
      switch (this.$route.meta.pageType) {
        case "landingPage":
          classname = `landing-page ${this.$route.name}`;
          break;
        case "detailPage":
          classname = `detail-page ${this.$route.name}`;
          break;
        case "standardPage":
        default:
          classname = `standard-page ${this.$route.name}`;
          break;
      }
      return `${classname} ${this.bodyClass}`;
    }
  },
  methods: {
    setkBackgroundColor: function() {
      this.$store.dispatch("ui/SET_BACKGROUND_BLACK", this.bodyClass === "bg-black");
    }
  },
  components: {
    MainNavbar,
    FooterPage
  }
};
</script>

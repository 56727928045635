var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirmpassword-container"},[_c('h1',{staticClass:"font-22 text-gold font-weight-bold"},[_vm._v("Confirm Password")]),_vm._v(" Please enter and confirm your new password. "),_c('validation-observer',{ref:"confirmPasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|minPassword:8","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:invalid && dirty & changed ? 'md-invalid' : '',attrs:{"md-toggle-password":false}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v("Password *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"password","id":"input-password","aria-describedby":"emailHelp","placeholder":"Enter password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"ConfirmPassword","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:invalid && dirty & changed ? 'md-invalid' : '',attrs:{"md-toggle-password":false}},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v("Confirm Password *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"password","id":"confirm-password","aria-describedby":"emailHelp","placeholder":"Enter confirm password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('MdButton',{staticClass:"n8-btn light-theme md-raised mt-5",class:invalid ? "disabled" : "",on:{"click":_vm.submit}},[_c('span',{staticClass:"btn-label"},[_vm._v("Submit")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
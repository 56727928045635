<template>
  <nav class="navbar navbar-expand-lg">
    <div class="navbar-brand">
      <router-link to="/">
        <img src="/img/logo/boxoffice.svg" alt="Box Office VR" />
      </router-link>
    </div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <nav-list class="text-nowrap" />
    </div>
    <search class="d-none d-lg-block"></search>
    <button-hamburger-group class="d-lg-none" />
    <mobile-sidebar class="d-lg-none" :class="mobileSidebarClassname" />
  </nav>
</template>

<script>
import { mapState } from "vuex";
import NavList from "@/components/navbar/NavList";
import MobileSidebar from "@/components/navbar/MobileSidebar";
import Search from "@/components/search/search";
import ButtonHamburgerGroup from "./ButtonHamburgerGroup.vue";

export default {
  components: {
    NavList,
    MobileSidebar,
    Search,
    ButtonHamburgerGroup,
  },
  computed: {
    ...mapState({
      isMobileMenuOpened: (state) => state.general.isMobileMenuOpened,
    }),
    mobileSidebarClassname() {
      return this.isMobileMenuOpened ? "open" : "";
    },
  },
};
</script>

<template>
  <div class="now-showing-slider-container">
    <h2 v-if="!isMobileView" class="gold mb-3">
      <strong>Now Showing</strong>
    </h2>
    <Slider v-if="!isMobileView" :list="list" class="px-0" />
    <section v-else>
      <div
        style="padding:20px; 20px 0"
        v-for="(item, index) in list"
        v-on:click="gotoDetail(item)"
        :key="index"
        class="cursor-pointer"
      >
        <div class="info-box">
          <div class="slider-container info-box-img-container">
            <div class="inner">
              <img :src="resolveCdnPath(item.artworks.featured_sm)" class="info-box-img" />
              <div class="slider-info">
                <div class="price">
                  <span class="currency">{{ currency }}</span>
                  {{ includeTax(item.price) | priceFormat }}
                </div>
              </div>
              <div class="info-box-icon n8-gradient-black-transparent">
                <div class="n8-btn" @click="gotoDetail(item)">
                  <i class="icon fas fa-info-circle"></i>&nbsp;&nbsp; Info
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="underline-dash-gold mb-4"></div>
      </div>
    </section>
  </div>
</template>

<script>
import getSymbolFromCurrency from "currency-symbol-map";
import Slider from "@/components/slider";
import UrlHelper from "../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isMobileView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultImg: "/img/thumbnail-default.png",
      selected: 0
    };
  },
  methods: {
    setActiveItem(item) {
      this.selected = item.id;
    },
    gotoDetail(item) {
      this.$router.push({
        name: "showdetails",
        params: { id: item.slug }
      });
    },
    resolveCdnPath(path) {
      return path ? urlHelper.resolveCdnPath(path) : this.defaultImg;
    }
  },
  computed: {
    currency() {
      return getSymbolFromCurrency("GBP");
    }
  },
  components: {
    Slider
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/components/infobox";

.now-showing-slider-container {
  h2 {
    padding: 47px 50px 0 50px;
  }
  .info-box-img-container {
    height: 360px;
  }
}
</style>

<template>
  <div class="my-order-page">
    <h1 class="text-center mb-5">My Orders</h1>

    <div class="top-black-bg">
      <h2 class="text-center mb-5">Current Orders</h2>

      <small-loader class="d-block mx-auto" v-if="dataLoading" />
      <div class="row" v-else>
        <div
          class="col-md-6 col-lg-4"
          v-for="(order, idx) in currentOrdersList"
          :key="idx"
        >
          <div
            class="order-item"
            :style="{ backgroundImage: 'url(' + getImageUrl(order) + ')' }"
          >
            <div class="order-item-inner order-item__inner">
              <h3 class="order-item__heading">{{ order.meta.product.name }}</h3>
              <div class="price">
                <span>{{
                  formatPrice(
                    order.meta.payment.currency,
                    order.meta.payment.totalPrice
                  )
                }}</span>
              </div>
              <p class="mb-4">
                <span>Expiry: </span> <i>{{ getExpiryText(order) }}</i>
              </p>
              <div class="row mx-0 justify-content-between">
                <div class="px-lg-3 px-sm-0 px-2">
                  <router-link
                    class=" mt-2"
                    :to="{
                      name: 'showdetails',
                      params: { id: order.meta.product.slug },
                    }"
                  >
                    <MdButton class="n8-btn md-raised btn-order-info">
                      <span class="btn-information icon">Info</span>
                    </MdButton>
                  </router-link>
                </div>
                <div class="px-lg-3 px-sm-0 px-2">
                  <MdButton
                    class="n8-btn md-raised gold-btn btn-order-watch mt-2"
                    @click="gotoDetailPlayer(order.meta.product.slug)"
                  >
                    <span class="btn-label icon">Watch Now</span>
                  </MdButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pastOrdersList.length > 0" class="bottom-black-bg past-orders  ">
      <h2 class="text-center mb-5">Past Orders</h2>
      <small-loader class="d-block mx-auto" v-if="dataLoading" />
      <div class="row" v-else>
        <div
          class="col-md-6 col-lg-4"
          v-for="(order, idx) in pastOrdersList"
          :key="idx"
        >
          <div
            class="past-order-item"
            :style="{ backgroundImage: 'url(' + getImageUrl(order) + ')' }"
          >
            <div class="past-order-item-inner past-order-item__expired">
              <div class="heading">{{ order.meta.product.name }}</div>
              <div class="price">
                <span>{{
                  formatPrice(
                    order.meta.payment.currency,
                    order.meta.payment.totalPrice
                  )
                }}</span>
              </div>
              <div class="order-with-line expiry">
                <p><span>Expired: </span>{{ getExpiryText(order) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SmallLoader from "@/components/SmallLoader";
import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  mounted() {
    this.$store.dispatch(
      "orders/GET_CUSTOMER_ORDERS",
      this["auth/getUser"].sub
    );
  },
  computed: {
    ...mapState({
      currentOrdersList: (state) => state.orders.currentOrdersList,
      pastOrdersList: (state) => state.orders.pastOrdersList,
      dataLoading: (state) => state.general.dataLoading,
    }),
    ...mapGetters(["auth/getUser"]),
  },
  components: {
    SmallLoader,
  },
  methods: {
    getExpiryText(order) {
      if (!moment(order.validUntilDateTime).isBefore(new Date(), "day")) {
        return `Valid for ${this.$store.getters["purchaseSettings/getLifetime"].watchingLifetimeInHours} hours after viewing starts`;
      }
      return moment(order.watchingExpiry || order.validUntilDateTime).format(
        "DD/MM/YYYY hh:mma"
      );
    },
    getImageUrl(order) {
      if (order.meta.product.poster !== undefined) {
        return urlHelper.resolveCdnPath(order.meta.product.poster);
      }
      return "/img/mock/feature-sm-default.png";
    },
    formatPrice(currency, price) {
      if (!currency) {
        currency = "GBP";
      }
      return `${getSymbolFromCurrency(currency)} ${price}`;
    },
    gotoDetailPlayer(slug) {
      this.$router.push({
        path: `/shows/${slug}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
h1,
h2 {
  color: $gold;
  font-weight: 700;
}
h1 {
  font-size: 64px;
}
h2 {
  font-size: 32px;
}
.order-item,
.past-order-item {
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-bottom: 30px;
  height: 420px;
  border-radius: 25px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &__inner {
    border-radius: 0px 0px 25px 25px;
  }
  h2 {
    position: absolute;
    z-index: 1;
    color: white;
    bottom: 15px;
    padding: 10px 25px;
    font-size: 22px;
    border-left: 7px solid #ffc107;
    background-color: rgba(0, 0, 0, 0.5);
    line-height: 1em;
  }
  p {
    font-size: 0.95em;
    margin-bottom: 5px;
  }
  .order-item__heading {
    font-family: "Opensans-bold";
    font-weight: bolder;
    margin-bottom: 0;
    vertical-align: top;
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .order-item-inner,
  .past-order-item-inner {
    padding: 10px 17px;
    background-color: #433c38;
  }
  .past-order-item-inner {
    padding: 10px 2px;
    background-color: #24211f;
  }
  .price {
    font-size: 25px;
    font-family: "Opensans-regular";
  }

  .order-with-line {
    border-bottom: 0.5px solid #aaa4a1;
  }
  .btn-order-info {
    width: 110px;
  }
  .btn-order-watch {
    width: 170px;
  }

  @media only screen and (max-width: 1024px) {
    .btn-order-info {
      width: 50px;
    }
    .btn-order-watch {
      width: 130px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .order-item-inner {
      min-height: 200px;
    }
  }
}
.top-black-bg {
  background-color: #191716;
  border-radius: 25px;

  @media only screen and (max-width: 767px) {
    margin: 0 -5px 20px;
    padding: 20px;
  }
  @media only screen and (min-width: 768px) {
    margin: 0 -200px 40px;
    padding: 40px 200px;
  }
}
.bottom-black-bg {
  padding: 10px;
}

.past-orders {
  .past-order-item {
    height: 300px;
    margin-bottom: 40px;
  }
  .heading {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    height: 26px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .price,
  .expiry {
    height: 25px;
    font-size: 14px;
  }
  .order-with-line {
    padding-bottom: 5px;
  }
}
</style>

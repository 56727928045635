function getVideoBaseUrl(fullpath) {
  // from https://n8-contents.s3.ap-southeast-2.amazonaws.com/videos/4ca91a69-9b54-443a-b8a6-0ac5135e8388/mp4_2d_hd.mp4
  // to https://n8-contents.s3.ap-southeast-2.amazonaws.com/videos/4ca91a69-9b54-443a-b8a6-0ac5135e8388/
  if (!fullpath) return null;

  return fullpath.substring(0, fullpath.lastIndexOf("/")) + "/";
}

function getFilename(fullpath) {
  // return last segment of fullpath
  if (!fullpath) return null;
  return fullpath.substring(fullpath.lastIndexOf("/") + 1);
}

export function buildVideoJson(videoObj) {
  if (!videoObj.videoFiles)
    return {
      url: "",
      Title: videoObj.title,
      SubTitleUrl: "something ",
      LastViewedTimeStamp: 0.0
    };

  const videoBaseUrl = getVideoBaseUrl(videoObj.videoFiles.mp4_2d_md);
  const twodee = {
    ld_file: getFilename(videoObj.videoFiles.mp4_2d_ld),
    md_file: getFilename(videoObj.videoFiles.mp4_2d_md),
    hd_file: getFilename(videoObj.videoFiles.mp4_2d_hd)
  };

  const threedee = {
    ld_file: getFilename(videoObj.videoFiles.mp4_3d_ld),
    md_file: getFilename(videoObj.videoFiles.mp4_3d_md),
    hd_file: getFilename(videoObj.videoFiles.mp4_3d_hd)
  };
  console.log(videoObj);

  return {
    url: videoBaseUrl,
    Title: videoObj.title,
    SubTitleUrl: "something ",
    LastViewedTimeStamp: 0.0,
    TwoDee: {
      LowQuality: {
        QualityDisplayText: "",
        VideoUrl: twodee.ld_file
      },
      MedQuality: {
        QualityDisplayText: "",
        VideoUrl: twodee.md_file
      },
      HighQuality: {
        QualityDisplayText: "",
        VideoUrl: twodee.hd_file
      }
    },
    ThreeDee: {
      LowQuality: {
        QualityDisplayText: "",
        VideoUrl: threedee.ld_file
      },
      MedQuality: {
        QualityDisplayText: "",
        VideoUrl: threedee.md_file
      },
      HighQuality: {
        QualityDisplayText: "",
        VideoUrl: threedee.hd_file
      }
    }
  };
}

export function updateMainVideoUrls(mainVideo, videoUrls) {
  /* NO 2D data. because the player default load is TwoDee, we set the TwoDee data with ThreeDee */
  console.debug("updating main video urls..");
  if (mainVideo == null) {
    return {
      url: "", // getVideoBaseUrl(videoUrls.mp4_2d_md),
      Title: "",
      SubTitleUrl: "something ",
      LastViewedTimeStamp: 0.0,
      TwoDee: {
        LowQuality: {
          QualityDisplayText: "",
          VideoUrl: videoUrls.mp4_3d_ld
        },
        MedQuality: {
          QualityDisplayText: "",
          VideoUrl: videoUrls.mp4_3d_md
        },
        HighQuality: {
          QualityDisplayText: "",
          VideoUrl: videoUrls.mp4_3d_hd
        }
      },
      ThreeDee: {
        LowQuality: {
          QualityDisplayText: "",
          VideoUrl: videoUrls.mp4_3d_ld
        },
        MedQuality: {
          QualityDisplayText: "",
          VideoUrl: videoUrls.mp4_3d_md
        },
        HighQuality: {
          QualityDisplayText: "",
          VideoUrl: videoUrls.mp4_3d_hd
        }
      }
    };
  } else {
    /*
    NO 2D
    mainVideo.TwoDee.LowQuality.VideoUrl = videoUrls.mp4_2d_ld;
    mainVideo.TwoDee.MedQuality.VideoUrl = videoUrls.mp4_2d_md;
    mainVideo.TwoDee.HighQuality.VideoUrl = videoUrls.mp4_2d_hd;
    */
    mainVideo.TwoDee.LowQuality.VideoUrl = videoUrls.mp4_3d_ld;
    mainVideo.TwoDee.MedQuality.VideoUrl = videoUrls.mp4_3d_md;
    mainVideo.TwoDee.HighQuality.VideoUrl = videoUrls.mp4_3d_hd;
    mainVideo.ThreeDee.LowQuality.VideoUrl = videoUrls.mp4_3d_ld;
    mainVideo.ThreeDee.MedQuality.VideoUrl = videoUrls.mp4_3d_md;
    mainVideo.ThreeDee.HighQuality.VideoUrl = videoUrls.mp4_3d_hd;

    return mainVideo;
  }
}

export function buildTrailerJson(videoUrl, title = "") {
  const videoBaseUrl = getVideoBaseUrl(videoUrl);
  const twodee = {
    ld_file: getFilename(videoUrl),
    md_file: getFilename(videoUrl),
    hd_file: getFilename(videoUrl)
  };

  const threedee = {
    ld_file: getFilename(videoUrl),
    md_file: getFilename(videoUrl),
    hd_file: getFilename(videoUrl)
  };

  return {
    url: videoBaseUrl,
    Title: `[TRAILER] ${title}`,
    SubTitleUrl: "something ",
    LastViewedTimeStamp: 0.0,
    TwoDee: {
      LowQuality: {
        QualityDisplayText: "",
        VideoUrl: twodee.ld_file
      },
      MedQuality: {
        QualityDisplayText: "",
        VideoUrl: twodee.md_file
      },
      HighQuality: {
        QualityDisplayText: "",
        VideoUrl: twodee.hd_file
      }
    },
    ThreeDee: {
      LowQuality: {
        QualityDisplayText: "",
        VideoUrl: threedee.ld_file
      },
      MedQuality: {
        QualityDisplayText: "",
        VideoUrl: threedee.md_file
      },
      HighQuality: {
        QualityDisplayText: "",
        VideoUrl: threedee.hd_file
      }
    }
  };
}

const general = {
  namespaced: true,
  state: {
    searchTerm: "",
    searchResult: [],
    isMobileMenuOpened: false,
    isSearchbarActive: false,
    modalShow: false,
    dataLoading: false,
    showNotification: false,
    notificationType: null,
    notificationMessage: null,
    buildNumber: process.env.VUE_APP_BUILD_NUMBER,
    metaTags: {}
  },
  mutations: {
    setSearchResult(state, payload) {
      state.searchResult = payload;
    },
    setDataLoading(state, payload) {
      state.dataLoading = payload;
    },
    setMobileMenuStatus(state, payload) {
      state.isMobileMenuOpened = payload;
    },
    toggleMobileMenu(state) {
      state.isMobileMenuOpened = !state.isMobileMenuOpened;
    },
    setSearchbarActive(state, payload) {
      state.isSearchbarActive = payload;
    },
    setNotificationStatus(state, payload) {
      state.showNotification = payload;
    },
    setNotificationType(state, payload) {
      state.notificationType = payload;
    },
    setNotificationMessage(state, payload) {
      state.notificationMessage = payload;
    },
    setModalShow(state, payload) {
      state.modalShow = payload;
    },
    setMetaTags(state, payload) {
      state.metaTags = payload;
    }
  },
  actions: {
    SET_SEARCH_RESULT({ commit }, payload) {
      commit("setSearchResult", payload);
    },
    SET_DATALOADING({ commit }, payload) {
      commit("setDataLoading", payload);
    },
    SET_MOBILEMENUSTATUS({ commit }, payload) {
      commit("setMobileMenuStatus", payload);
    },
    TOGGLE_MOBILEMENUSTATUS({ commit }) {
      commit("toggleMobileMenu");
    },
    SET_SEARCHBARACTIVE({ commit }, payload) {
      commit("setSearchbarActive", payload);
    },
    SET_NOTIFICATION({ commit }, payload) {
      //send notif type and message in here
      commit("setNotificationStatus", payload.showNotif);
      commit("setNotificationType", payload.type); // Notification type: info, success, error
      commit("setNotificationMessage", payload.message);
    },
    SET_MODALSHOW({ commit }, payload) {
      commit("setModalShow", payload);
    },
    SET_META_TAGS({ commit }, payload) {
      commit("setMetaTags", payload);
    }
  },
  getters: {
    getSearchResult(state) {
      return state.searchResult;
    },
    getMobileMenuStatus(state) {
      return state.isMobileMenuOpened;
    },
    getNotificationStatus(state) {
      return state.showNotification;
    },
    getNotificationType(state) {
      return state.notificationType;
    },
    getNotificationMessage(state) {
      return state.notificationMessage;
    },
    getMetaTags(state) {
      return state.metaTags;
    }
  }
};

export default general;

<template>
  <div class="confirm-password-container bg-confirm-password">
    <ConfirmPassword></ConfirmPassword>
  </div>
</template>
<script>
import ConfirmPassword from "@/components/membership/ConfirmPassword";
export default {
  components: {
    ConfirmPassword
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.confirm-password-container {
  margin: 80px auto;
}
.bg-confirm-password {
  border-radius: 25px;
  padding: 40px;
  max-width: 410px;
  background-color: #24211f;
  border: 1px #ae8138 solid;

  @include breakpoint("medium") {
    width: 410px;
  }
}
</style>

<template>
  <div class="n8-map">
    <div class="inner">
      <gmap-map
        id="map"
        class="map"
        ref="map"
        :center="center"
        :zoom="12"
        :options="options"
        style="width: 100%; height: 100%"
      >
        <gmap-marker ref="marker" :position="center"></gmap-marker>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAP_APIKEY,
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});

export default {
  props: {
    location: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      center: { lat: -33.86514, lng: 151.2099 },
      zoom: 12,
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        disableDefaultUI: true,
        gestureHandling: "none",
        zoomControl: false,
        styles: [
          {
            featureType: "poi.attraction",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.business",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.government",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.medical",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.place_of_worship",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.school",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi.sports_complex",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    /* this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo({ lat: 1.38, lng: 103.8 });
    });
    */
    this.center = this.location.lat ? this.location : this.center;
  },
};
</script>

<style lang="scss" scoped>
.n8-map {
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  padding-top: 85%;
  width: 100%;
  & > .inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
<template>
  <div>
    <div class="show-detail-container">
      <small-loader class="d-block mx-auto" v-if="dataLoading" />
      <div v-else>
        <div v-if="detail">
          <div class="show-detail full-width-mobile-container mb-1">
            <div class="bg-slider" ref="sliderBackground">
              <hero-slider
                ref="sliderContainer"
                :key="dynamicKey"
                :items="heroSlider"
                :trailer="trailerVideoUrl"
                @onPlayTrailer="watchTrailer"
                @hook:updated="updateTopDarkBgCalc"
                v-if="heroSlider && heroSlider.length > 1"
              ></hero-slider>

              <single-hero
                :item="heroSlider"
                :trailer="trailerVideoUrl"
                @onPlayTrailer="watchTrailer"
                v-else
              />
            </div>
            <!-- DESKTOP -->
            <description-section
              class="desktop-section show-desktop"
              :detail="detail"
              :displayFormLogin="displayFormLogin"
              :isPaymentFormActive="true"
              @closeFormSection="closeFormSection($event)"
              ref="detailDescContainer"
            >
              <box-ticket class="text-center mb-3" v-if="detail.status === 'released'">
                <div class="buy-section">
                  <div class="price">
                    <span
                      ><span class="currency">{{ currency }}</span>
                      {{ includeTax(detail.price) | toFloat }}</span
                    >
                    <div class="underline-dash mb-0"></div>
                  </div>
                  <div class="button-container">
                    <!-- Loader -->
                    <div class="mt-2 mt-lg-4" v-if="isEligible == null">Loading..</div>

                    <!-- Watch Button -->
                    <div v-if="isEligible === true">
                      <MdButton
                        class="n8-btn gold-btn md-raised"
                        :disabled="isButtonWatchNowDisabled"
                      >
                        <span class="btn-label icon" @click="watchNow">
                          <span v-if="isLoadingPlayer">Loading...</span>
                          <span v-else>Watch Now</span>
                        </span>
                      </MdButton>
                      <div
                        @click="goToFaqPage"
                        class="bovr-ico-support btn-help btn-help-mobile"
                      ></div>

                      <expiry-info
                        :show-id="id"
                        :epochNow="epochNow"
                        :showExpirationRule="showExpirationRule"
                        v-if="!isCheckEligibleFailed"
                      />
                    </div>

                    <!-- Purchase Button -->
                    <MdButton
                      class="n8-btn gold-btn md-raised grey"
                      @click="purchaseClick"
                      v-if="isEligible === false"
                      :class="displayFormLogin ? `disabled` : ``"
                      :disabled="displayFormLogin"
                    >
                      <span class="btn-label icon"> Purchase </span>
                    </MdButton>
                    <div
                      v-if="isEligible === false"
                      @click="goToFaqPage"
                      class="bovr-ico-support btn-help btn-help-mobile"
                    ></div>

                    <!-- Help Button -->
                    <MdButton
                      class="n8-btn transparent-btn btn-help btn-help-desktop"
                      @click="goToFaqPage"
                    >
                      <span class="bovr-ico-support"></span>
                      <span class="btn-label"> Help</span>
                    </MdButton>
                  </div>
                  <div class="video-error-container text-center mt-3" v-if="isCheckEligibleFailed">
                    <i class="fa fa-exclamation-triangle"></i>
                    <small> There is a technical issue with the video data. </small>
                  </div>
                </div>
                <!-- <div class="buy-section">
									<div>
										Coming Soon
										<div class="underline-dash mb-0"></div>
									</div>
								</div> -->
                <div class="spinner" v-if="isLoadingPlayer">
                  <small-loader></small-loader>
                </div>
              </box-ticket>
            </description-section>

            <!-- MOBILE -->
            <mobile-tabs-section
              class="mobile-section d-block d-lg-none"
              :detail="detail"
              :now-showing-item="nowShowingVenue"
              :next-showing-items="nextShowingVenues"
              :isPaymentFormActive="false"
              :similarShows="similarShows"
              @closeFormSection="closeFormSection($event)"
            />
          </div>
          <div class="now-showing-container row d-none d-lg-flex">
            <div class="col-md-4" v-if="nowShowingVenue">
              <now-showing :item="nowShowingVenue" />
            </div>
            <div class="col-md-8">
              <next-showing
                :items="nextShowingVenues"
                :videoId="detail.id"
                item-classname="col-md-6"
                v-if="nextShowingVenues"
              />
            </div>
          </div>

          <div
            v-if="similarShows && similarShows.length"
            class="similar-show-container d-none d-lg-flex"
            ref="similarShowContainer"
          >
            <!-- <div class="row w-100"> -->
            <div class="col-12">
              <h5 class="sm-text-center">Similar Shows</h5>
              <Slider :list="similarShows" :type="'similar-shows'" />
            </div>
          </div>
          <!-- </div> -->
        </div>
        <div v-else>Not found</div>
      </div>
    </div>

    <payment-vr-modal :isShow="showVrPaymentDialog" @onCloseModal="closeVrPaymentDialog" />
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import getSymbolFromCurrency from "currency-symbol-map";
import HeroSlider from "@/components/Hero";
import SingleHero from "@/components/Hero/SingleHero";
import SmallLoader from "@/components/SmallLoader";
import DescriptionSection from "./partials/DescriptionSection";
import MobileTabsSection from "./partials/MobileTabsSection";
import BoxTicket from "@/components/shared/BoxTicket";
import NowShowing from "@/components/NowShowing";
import NextShowing from "@/components/NextShowing";
import PaymentVrModal from "@/components/modal/PaymentVrModal";
import MetaInfo from "@/mixins/MetaInfo";
import Slider from "@/components/slider";
import ExpiryInfo from "./partials/ExpiryInfo.vue";
import { isMobileOnly, osVersion, osName } from 'mobile-device-detect';

export default {
  mixins: [MetaInfo],
  components: {
    BoxTicket,
    DescriptionSection,
    MobileTabsSection,
    HeroSlider,
    SingleHero,
    NowShowing,
    NextShowing,
    PaymentVrModal,
    SmallLoader,
    Slider,
    ExpiryInfo
  },
  data() {
    return {
      meta: [{ description: "" }],
      id: null,
      pauseVideo: false,
      isTrailerPlay: false,
      // isEligible: null,
      isLoadingPlayer: false,
      isCheckEligibleFailed: false,
      showVrPaymentDialog: false,
      nowShowingVenue: null,
      nextShowingVenues: [],
      showSimilarShows: true,
      // expiryTimestamp: 0,
      epochNow: Date.now(),
      dynamicKey: Date.now()
      // showExpirationRule: {
      // 	timeToWatchAfterPlayed: 0,
      // 	timeToWatchAfterPurchased: 0,
      // },
    };
  },
  async created() {
    this.id = this.$route.params.id;

    let data = await this.$store.dispatch("showDetails/GET_SHOW", this.id);
    this.$store.dispatch({ type: "showDetails/SET_SHOW", data: data });

    if (data.venue.id)
      this.nowShowingVenue = await this.$store.dispatch("venues/GET_VENUE_DETAIL", data.venue.id, {
        root: true
      });

    // get eligible sessions
    this.$store.dispatch("play/GET_ELIGIBLE_SESSIONS", null, {
      root: true
    });

    // next showing, get detail based on venueId of nextShowingVenues array
    if (data.nextShowingVenues) {
      for (let idx = 0; idx < data.nextShowingVenues.length; idx++) {
        if (data.nextShowingVenues[idx].venueId) {
          const venueDetailInfo = await this.completeNextShowingVenue(
            data.nextShowingVenues[idx].venueId
          );

          data.nextShowingVenues[idx].selectedVenue = venueDetailInfo;
        }
      }
    }
    this.nextShowingVenues = data.nextShowingVenues;

    // similar shows
    this.$store.dispatch("showDetails/GET_SIMILAR_SHOW", data.genre).then(resp => {
      const data = resp.data.filter(f => f.id != this.detail.id);
      this.$store.commit("showDetails/setSimilarShows", data);
      setTimeout(() => {
        this.updateBottomDarkBgCalc();
      }, 4000);
    });

    if (this.isLoggedIn) {
      await this.$store.dispatch("showDetails/GET_NOTIFIERS");
    }

    // trailer url
    this.$store.dispatch("showDetails/SET_TRAILERVIDEO", data.videoFiles.trailer_2d_md);
    this.setMetaTags({
      pageTitle: data.title,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription
    });
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
    this.$store.dispatch("showDetails/SET_OPENPLAYER", false);

    console.log("*** " + osName + " " + osVersion);
  },
  computed: {
    ...mapState({
      detail: state => state.showDetails.showData,
      dataLoading: state => state.general.dataLoading,
      isPlayerOpen: state => state.showDetails.playerOpen,
      similarShows: state =>
        state.showDetails.similarShows.slice(0, 4).map(detail => {
          return {
            id: detail.id,
            title: detail.title,
            artworks: detail.artworks,
            type: "similar_shows",
            price: detail.price,
            slug: detail.slug
          };
        }),
      mainVideoJson: state => state.showDetails.mainVideoJson,
      displayFormLogin: state => state.showDetails.displayFormLogin
    }),
    ...mapGetters({
      getShow: "showDetails/getShow",
      isLoggedIn: "auth/isLoggedIn",
      user: "auth/getUser",
      eligibleSession: "play/eligibleSessions",
      getNotifiers: "showDetails/getNotifiers"
    }),
    currency() {
      return getSymbolFromCurrency("GBP");
    },
    eligibleToWatch() {
      return this.isLoggedIn && this.isEligible;
    },
    heroSlider() {
      if (Object.keys(this.detail).length === 0 && this.detail.constructor === Object) return;

      const hero = this.detail.artworks.slider_l || [];
      return hero.map(s => {
        return {
          key: "hero",
          value: s
        };
      });
    },
    nowShowingItem() {
      return this.detail.venue;
    },
    trailerVideoUrl() {
      // trailer
      return this.detail && this.detail.videoFiles ? this.detail.videoFiles.trailer_2d_md : null;
    },
    isTrailerExist() {
      return (
        this.detail &&
        this.detail.videoFiles &&
        this.detail.videoFiles.trailer_2d_md !== undefined &&
        this.detail.videoFiles.trailer_2d_md !== null
      );
    },
    isButtonWatchNowDisabled() {
      return this.isCheckEligibleFailed || this.isLoadingPlayer;
    },
    session() {
      const session = this.$store.getters["play/checkEligibility"](
        this.id || this.$route.params.id
      );
      return session;
    },
    expiryTimestamp() {
      if (this.session) {
        return this.session.expiryTimestamp;
      }

      return 0;
    },
    showExpirationRule() {
      if (this.session) {
        return this.session.showExpiration;
      }

      return {
        timeToWatchAfterPlayed: 0,
        timeToWatchAfterPurchased: 0
      };
    },
    isEligible() {
      const _isEligible = this.$store.getters["play/isEligible"](this.id || this.$route.params.id);
      // console.log("IsEligible", this.id || this.$route.params.id, _isEligible);
      return _isEligible;
    },
    isVrBrowser() {
      return ["OculusBrowser", "SamsungBrowser", "MobileVR", "Mobile VR", "VR"].some(item => {
        return window.navigator.userAgent.indexOf(item) > -1;
      });
    },
    designatedDeeplink() {
      return isMobileOnly && !this.isVrBrowser && !(osName == "Android" && osVersion == "11");
    }
  },
  filters: {
    toFloat(val) {
      return Number.parseFloat(val).toFixed(2);
    }
  },
  watch: {
    epochNow: {
      handler(value) {
        let localEligibleCheck = true; // trick the first if()..because it probably still null
        if (value && localEligibleCheck) {
          // console.log("IS ELIGIBLE", this.isEligible);
          setTimeout(() => {
            this.epochNow = Date.now();
            // this.isEligible = this.expiryTimestamp > this.epochNow;
            localEligibleCheck = this.isEligible;
          }, 60000);
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    }),
    async completeNextShowingVenue(venueId) {
      return new Promise(resolve => {
        let nextShowingVenue = this.$store.dispatch("venues/GET_VENUE_DETAIL", venueId, {
          root: true
        });

        resolve(nextShowingVenue);
      });
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      console.log(orientation);
      if (orientation === "portrait-primary") {
        // portrait mode.
      } else if (orientation === "landscape-primary") {
        // landscape mode
      }
    },
    async watchNow() {
      const wsPayload = {
        videoId: this.detail.id
      };
      this.isLoadingPlayer = true;

      if (this.designatedDeeplink) {
        console.log("DesignatedDeeplink");
        this.$router.push({
          name: "watch",
          params: {
            id: this.detail.slug,
            videoId: this.detail.id,
            deeplinkUrl: this.detail.deeplinkUrl
          },
          query: { deferred: true }
        });
      } else {
        const wsResp = await this.$store.dispatch("play/GET_WATCHING_SESSION", wsPayload, {
          root: true
        });

        if (wsResp.status == "v") {
          // await this.$store.dispatch("showDetails/SET_OPENPLAYER", true, { root: true });
          // await this.$store.dispatch("general/SET_MODALSHOW", true, { root: true });
          this.$router.push({
            name: "watch",
            params: {
              id: this.detail.slug,
              videoId: this.detail.id
            }
          });
        }
      }


      this.isLoadingPlayer = false;
    },
    watchTrailer() {
      this.isTrailerPlay = true;
      this.$store.dispatch("showDetails/SET_ISTRAILER", true);
      this.$store.dispatch("showDetails/SET_OPENPLAYER", true);
      this.$store.dispatch("general/SET_MODALSHOW", true);
    },
    purchaseClick() {
      // want to make sure if the video already paid
      // this.checkEligibility(this.detail);
      // build video data
      if (this.isEligible) {
        this.watchNow();
      } else {
        if (this.isVrBrowser) {
          this.showVrPaymentDialog = true;
        } else {
          this.$store.dispatch("showDetails/SHOW_FORM_LOGIN", true);
        }
      }
    },
    closeVrPaymentDialog() {
      this.showVrPaymentDialog = false;
    },
    async closeFormSection({ play = false } = {}) {
      if (play && this.isEligible) {
        // console.log("user is eligible for this show");
        // this.expiryTimestamp = expiryTimestamp;
        this.$store.dispatch("general/SET_DATALOADING", true, { root: true });
        setTimeout(async () => {
          await this.watchNow();
          this.$store.dispatch("general/SET_DATALOADING", false, {
            root: true
          });
        }, 10000);
      }

      this.$store.dispatch("showDetails/SHOW_FORM_LOGIN", false);
    },
    updateTopDarkBgCalc() {
      this.$store.dispatch(
        "ui/SET_TOP_DARK_BG_HEIGHT",
        this.$refs.sliderBackground ? this.$refs.sliderBackground.clientHeight + 270 : 500
      );
    },
    updateBottomDarkBgCalc() {
      //Show bottom dark bg
      if (this.similarShows && this.similarShows.length > 0)
        this.$store.dispatch(
          "ui/SET_BOTTOM_DARK_BG_HEIGHT",
          this.$refs.similarShowContainer ? this.$refs.similarShowContainer.clientHeight + 328 : 0
        );
    },
    checkEligibility: function(data) {
      this.$store.getters["play/checkEligibility"](data.id);
    },
    goToFaqPage: function() {
      this.$router.push({ name: "faq" });
    }
  },
  beforeDestroy() {

  },
  destroyed() {
    this.$store.dispatch("ui/RESET_TOP_DARK_BG_HEIGHT");
    this.$store.dispatch("ui/RESET_BOTTOM_DARK_BG_HEIGHT");
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.desktop-section {
  z-index: 2;
}

.mobile-section {
  position: relative;
  z-index: 1;
}
.description-container {
  overflow: hidden;
  h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.show-detail-container {
  .buy-section {
    .price {
      .currency {
        font-size: 25px;
      }
    }
  }
}
.now-showing-container {
  background: $dark-brown;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-bottom: 10px;
}

.spinner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 991px) {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.btn-help {
  width: 32px;
  font-size: 32px;
  padding: 8px;

  &.btn-help-desktop {
    display: none !important;

    @include breakpoint("large") {
      display: block !important;
    }
  }

  &.btn-help-mobile {
    display: inline-block !important;

    @include breakpoint("large") {
      display: none !important;
    }
  }
}
</style>

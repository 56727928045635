<template>
  <div class="show-item" @click="gotoDetail">
    <div class="inner">
      <img :src="fallbackIfUndefined(data.artworks, data.type)" class="slider-img" />
      <div class="slider-info">
        <div class="price">
          <div v-show="data.price > 0">
            <span class="currency">{{ currency }}</span>
            {{ includeTax(data.price) | priceFormat }}
          </div>
        </div>
        <router-link :to="{ name: 'showdetails', params: { id: slug } }" class="info">
          <i class="icon fas fa-info-circle"></i> Info
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import getSymbolFromCurrency from "currency-symbol-map";
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    data: Object
  },
  computed: {
    slug: {
      get() {
        const { id, slug } = this.data || {};

        return slug ?? id;
      }
    },
    currency() {
      return getSymbolFromCurrency("GBP");
    }
  },
  methods: {
    fallbackIfUndefined(artworks, type = "") {
      let { slider_p = null, featured_sm = "/img/mock/slider_p_default.png" } = artworks || {};

      const defaultImage = "/img/mock/slider_p_default.png";

      if (type === "now-showing") {
        return urlHelper.resolveCdnPath(featured_sm);
      } else {
        return type === "similar_shows"
          ? urlHelper.resolveCdnPath(featured_sm)
          : urlHelper.resolveCdnPath(slider_p)
          ? urlHelper.resolveCdnPath(slider_p[0])
          : defaultImage;
      }
    },
    gotoDetail() {
      this.$router.push({
        name: "showdetails",
        params: { id: this.slug }
      });
    }
  }
};
</script>

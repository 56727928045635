import axios from "@/axios-instance";

const activecampaignServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_ACTIVECAMPAIGN_API_KEY,
};

const activeCampaign = {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    CREATE_ACTIVE_CAMPAIGN_CONTACT({ rootGetters }, payload){
      console.log('detail',payload);
      const data = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        country: payload.country
      }
      return axios.post("/activecampaign", data, { headers: activecampaignServiceReqHeaders });
    },
    ADD_CONTACT_TAGS(_store, payload) {
      return axios.post("/activecampaign/tags",payload, { headers: activecampaignServiceReqHeaders });
    },
     // eslint-disable-next-line no-unused-vars
    UPDATE_LOCATION({ rootGetters }, payload) {
      const user = rootGetters["auth/getUser"];
      const data = {
        data: {
          email: user.email,
          oldTags: `LOCATION-${payload.oldTags}`,
          tags: `LOCATION-${payload.tags}`
        }
      }
      return axios.put("/activecampaign/location", data, { headers: activecampaignServiceReqHeaders })
    }
  }
}

export default activeCampaign;

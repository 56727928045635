import axios from "@/axios-instance";

const orderServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_ORDER_API_KEY,
};

const purchaseSettings = {
  namespaced: true,
  state: {
    vatRate: 0,
    purchaseLifetimeInHours: 0,
    watchingLifetimeInHours: 0,
  },
  actions: {
    GET_PURCHASE_SETTINGS({ commit }) {
      axios
        .get('/order/config/purchase-settings',{ headers: orderServiceReqHeaders })
        .then((resp) => {
          const data = resp.data.data;
          commit('setVatTaxRate', data.taxRates[0].taxRate);
          commit('setLifetime', data)
          // console.log('rate set',data);
        }).catch(err => {
          console.log(err)
        });
    }
  },
  mutations: {
    setVatTaxRate(state, payload) {
      state.vatRate = payload;
    },
    setLifetime(state, payload) {
      state.purchaseLifetimeInHours = payload.purchaseLifetimeInHours,
      state.watchingLifetimeInHours = payload.watchingLifetimeInHours
    }
  },
  getters: {
    vatTaxRate(state) {
      return state.vatRate;
    }, 
    getLifetime(state) {
      return { purchaseLifetimeInHours: state.purchaseLifetimeInHours, watchingLifetimeInHours: state.watchingLifetimeInHours }
    }
  }
}

export default purchaseSettings;
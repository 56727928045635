import axios from "@/axios-instance";
import { updateMainVideoUrls } from "../../../helper/videoDataHelper";
import UrlHelper from "../../../helper/url-helper";
const urlHelper = new UrlHelper();

const videoServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_VIDEO_API_KEY
};
const notifyServiceReqHeaders = {
  "x-api-key": process.env.VUE_APP_NOTIFIER_API_KEY
};

const promoVideoFile = "promo.mp4";

const showDetails = {
  namespaced: true,
  state: {
    showData: {},
    showDetailList: [],
    allShows: [],
    playerOpen: false,
    promoVideoJson: {
      url: `${window.location.origin}/video/`,
      Title: "name",
      SubTitleUrl: "something ",
      LastViewedTimeStamp: 0.0,
      TwoDee: {
        LowQuality: {
          QualityDisplayText: "",
          VideoUrl: promoVideoFile
        },
        MedQuality: {
          QualityDisplayText: "",
          VideoUrl: promoVideoFile
        },
        HighQuality: {
          QualityDisplayText: "",
          VideoUrl: promoVideoFile
        }
      },
      ThreeDee: {
        LowQuality: {
          QualityDisplayText: "",
          VideoUrl: promoVideoFile
        },
        MedQuality: {
          QualityDisplayText: "",
          VideoUrl: promoVideoFile
        },
        HighQuality: {
          QualityDisplayText: "",
          VideoUrl: promoVideoFile
        }
      }
    },
    mainVideoJson: null,
    trailerUrl: null,
    isTrailer: false,
    isDeferred: false,
    nowShowing: {},
    nextShowing: [],
    similarShows: [],
    displayFormLogin: false,
    notifierList: []
  },
  actions: {
    GET_ALL_SHOWS({ dispatch }) {
      const url = "/video/";
      dispatch("general/SET_DATALOADING", true, { root: true });
      axios
        .get(url, { headers: videoServiceReqHeaders })
        .then(resp => {
          let data = resp.data;
          dispatch("SET_ALL_SHOWS", data);
          dispatch("general/SET_DATALOADING", false, { root: true });
        })
        .catch(error => {
          dispatch("general/SET_DATALOADING", false, { root: true });
          console.log(error);
        });
    },
    GET_SHOW({ state, dispatch, commit }, id) {
      const url = "/video/" + id; // slug
      // check first in state list
      const showItem = state.showDetailList.find(item => item.slug == id);
      if (typeof showItem === "object") return showItem;

      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });
        axios
          .get(url, { headers: videoServiceReqHeaders })
          .then(resp => {
            let data = resp.data;
            commit("storeDetail", data);
            resolve(data);
            dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch(error => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            reject(error);
          });
      });
    },
    GET_NOTIFIERS({ dispatch, commit }) {
      if (!this.state.notifierList) {
        const url = "/notifier/";
        // dispatch("general/SET_DATALOADING", true, { root: true });
        return axios
          .get(url, { headers: notifyServiceReqHeaders })
          .then(resp => {
            let notifiersData = [];
            resp.data.forEach(notifier => {
              if (notifier.meta) {
                notifiersData.push({
                  videoId: notifier.meta.videoId,
                  venueId: notifier.meta.venueId,
                  isNotify: notifier.meta.isNotify
                });
              }
            });
            commit("setNotifiersFromNetwork", notifiersData);

            // dispatch("general/SET_DATALOADING", false, { root: true });
          })
          .catch(error => {
            console.log("GET_NOTIFIERS_ERROR", error);
            // dispatch("general/SET_DATALOADING", false, { root: true });
          });
      } else {
        dispatch("SET_NOTIFIERS", this.state.notifierList);
      }
    },
    POST_NOTIFIERS(_store, payload) {
      const data = {
        data: payload.data
      };
      const url = `/notifier/notify-me`;
      return axios.post(url, data, { headers: notifyServiceReqHeaders });
    },
    GET_SIMILAR_SHOW(_store, genre) {
      const url = `/video/similar-shows/${genre.replace(" ", "")}`;
      return axios.get(url, { headers: videoServiceReqHeaders });
    },
    SET_ALL_SHOWS({ commit }, payload) {
      commit("setShows", payload);
    },
    SET_NOTIFIERS({ commit }, payload) {
      console.log("SET_NOTIFIERS", payload);
      commit("setNotifiers", payload);
    },
    SET_SHOW({ commit }, params) {
      commit("setShow", params.data);
    },
    SET_OPENPLAYER({ commit }, params) {
      commit("setOpenPlayer", params);
    },
    CHECK_ELIGIBLE(_store, payload) {
      const reqHeader = {
        "Content-Type": "application/json",
        "x-api-key": process.env.VUE_APP_ORDER_API_KEY
      };

      const data = {
        data: payload
      };

      const url = `/order/retrieve`;
      return axios.post(url, data, { headers: reqHeader });
    },
    SET_MAINVIDEO({ commit }, payload) {
      commit("setMainVideoJson", payload);
    },
    SET_PROMOVIDEO({ commit }, payload) {
      commit("setPromoVideoJson", payload);
    },
    SET_TRAILERVIDEO({ commit }, payload) {
      commit("setTrailerVideo", payload);
    },
    SET_ISTRAILER({ commit }, payload) {
      commit("setIsTrailer", payload);
    },
    SET_VIDEO_DEFERRED({ commit }, payload) {
      commit("setIsDeferred", payload);
    },
    UPDATE_MAIN_VIDEO_URLS({ commit }, payload) {
      commit("updateMainVideoUrls", payload.videoUrls);
    },
    SHOW_FORM_LOGIN({ commit }, payload) {
      commit("setDisplayFormLogin", payload);
    }
  },
  mutations: {
    setShow(state, payload) {
      state.showData = payload;
    },
    storeDetail(state, payload) {
      state.showDetailList.push(payload);
    },
    setShows(state, payload) {
      state.allShows = payload;
    },
    setNotifiers(state, payload) {
      // add delay ~2.5 mins to sync it with persistance time taken in backend
      const delay = Date.now() + 150000;

      if (Array.isArray(payload)) {
        if (state.notifierList.length === 0) {
          state.notifierList = payload;

          state.notifier.forEach(item => (item.timeout = delay));
        } else {
          payload.forEach(item => {
            let existingNotifiedItemIdx = state.notifierList.findIndex(
              x => x.venueId === item.venueId && x.videoId === item.videoId
            );

            if (existingNotifiedItemIdx > -1) {
              item.timeout = delay;
              state.notifierList[existingNotifiedItemIdx] = item;
            } else {
              item.timeout = delay;
              state.notifierList.push(item);
            }
          });
        }
      } else {
        let existingNotifiedItemIdx = state.notifierList.findIndex(
          x => x.venueId === payload.venueId && x.videoId === payload.videoId
        );

        if (existingNotifiedItemIdx > -1) {
          payload.timeout = delay;
          state.notifierList[existingNotifiedItemIdx] = payload;
        } else {
          payload.timeout = delay;
          state.notifierList.push(payload);
        }
      }
    },
    setNotifiersFromNetwork(state, payload) {
      // add delay ~2.5 mins to sync it with persistance time taken in backend
      const delay = Date.now() + 150000;

      // don't update if state item has not timeout yet

      if (Array.isArray(payload)) {
        if (state.notifierList.length === 0) {
          state.notifierList = payload;
          // delay 2.5 mins to sync it with persistance time taken in backend
          state.notifier.forEach(item => (item.timeout = delay));
        } else {
          payload.forEach(item => {
            let existingNotifiedItemIdx = state.notifierList.findIndex(
              x => x.venueId === item.venueId && x.videoId === item.videoId
            );

            if (existingNotifiedItemIdx > -1) {
              // check the delay before notified item can be updated
              if (!state.notifierList[existingNotifiedItemIdx].timeout)
                state.notifierList[existingNotifiedItemIdx].timeout = Date.now() - 1000;

              if (state.notifierList[existingNotifiedItemIdx].timeout < Date.now()) {
                item.timeout = delay;
                state.notifierList[existingNotifiedItemIdx] = item;
              }
            } else {
              item.timeout = delay;
              state.notifierList.push(item);
            }
          });
        }
      } else {
        let existingNotifiedItemIdx = state.notifierList.findIndex(
          x => x.venueId === payload.venueId && x.videoId === payload.videoId
        );

        if (existingNotifiedItemIdx > -1) {
          // check the delay before notified item can be updated
          if (!state.notifierList[existingNotifiedItemIdx].timeout)
            state.notifierList[existingNotifiedItemIdx].timeout = Date.now() - 1000;

          if (state.notifierList[existingNotifiedItemIdx].timeout < Date.now()) {
            payload.timeout = delay;
            state.notifierList[existingNotifiedItemIdx] = payload;
          }
        } else {
          payload.timeout = delay;
          state.notifierList.push(payload);
        }
      }
    },
    setOpenPlayer(state, payload) {
      state.playerOpen = payload;
    },
    setSimilarShows(state, payload) {
      state.similarShows = payload;
    },
    setMainVideoJson(state, videoFinalJson) {
      state.mainVideoJson = videoFinalJson;
    },
    setPromoVideoJson(state, videoFinalJson) {
      state.promoVideoJson = videoFinalJson;
    },
    setTrailerVideo(state, trailerUrl) {
      state.trailerUrl = urlHelper.resolveCdnPath(trailerUrl);
    },
    setIsDeferred(state, payload) {
      state.isDeferred = payload;
    },
    setIsTrailer(state, payload) {
      state.isTrailer = payload;
    },
    updateMainVideoUrls(state, payload) {
      console.debug("updating main video urls");
      state.mainVideoJson = updateMainVideoUrls(state.mainVideoJson, payload);
    },
    setDisplayFormLogin(state, payload) {
      state.displayFormLogin = payload;
    }
  },
  getters: {
    getShow(state) {
      return state.showData;
    },
    getNotifiers(state) {
      return state.notifierList;
    }
  }
};

export default showDetails;

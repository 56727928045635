<template>
  <div class="about">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="d-none d-md-block about-title font-48" style="margin-bottom: -24px;">
          ABOUT
        </div>
        <div class="d-block d-md-none about-title">
          About Us
          <div class="border-bottom"></div>
        </div>
        <div class="d-block d-md-flex">
          <div class="d-none d-md-block about-title font-48">US</div>
          <div class="about-content">
            <p>
              <strong>
                Box Office VR is an online ticket office where you can see the latest theatre shows
                filmed in the latest virtual reality technology. You can enjoy this unique viewing
                experience from the comfort of your own home, whilst also supporting local theatre
                venues and arts organisations.
              </strong>
            </p>
            <p>
              Box Office VR is brought to you by Kabuki Drop Ltd, developed in direct response to
              the devastating impact COVID-19 has had on the theatre industry across the globe. The
              platform brings a unique, immersive theatre experience for the audience whilst
              simultaneously ensuring that much needed income is driven back to the theatre
              production companies and venues who need it.
            </p>
            <p>
              We believe that theatre and the arts in general are an essential part of what it is to
              be human. We also believe that digital theatre is here to stay. So as digital
              offerings become a much more regular feature of theatre output, and as the world of
              virtual reality starts to find its feet in a world outside of gaming, we wanted to
              ensure that the meeting of these two worlds was done with care and thought for the
              arts industry and for its audience – something done <i>for</i> and <i>with</i> them,
              not 'to' them.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 about-img section-1 text-center">
        <div class="img-container">
          <img :src="getImgUrl('about-1')" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 about-img text-center">
        <div class="img-container">
          <img :src="getImgUrl('about-2')" class="" />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 section-2">
        <div class="about-content no-border-bottom">
          <p>
            Therefore, alongside wanting to give audiences a unique viewing experience, we wanted to
            ensure that Venues, from the largest auditoriums to the smallest town halls, could be
            represented in a digital space and that they and theatre producers and production
            companies big and small, had the same income opportunities that live art brings them.
            That way we hope to support the ongoing creation and hosting of top quality theatre for
            audiences to enjoy in years to come, live or digital. Whatever the world might throw at
            us.
          </p>
          <p class="about-standout">
            "VR will never replace the live experience, but let us show you how it may sit alongside
            it, as an additional exciting way to share the best of theatre with the widest of
            audiences and support local theatre at the same time."
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="pt-0 col-lg-8 col-md-8 col-sm-12">
        <div class="about-content">
          <p>
            Box Office VR's platform and software is currently a beta version and will continue to
            undergo development, testing, and improvements.
          </p>
          <p>
            Should you encounter any bugs, glitches, lack of functionality or other problems please
            let us know immediately. We would welcome your help in improving the experience. Please
            feel free to contact us
            <router-link to="/emailenquiry" class="text-decoration-underline">here</router-link>
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 about-img section-3 text-center">
        <div class="img-container">
          <img :src="getImgUrl('about-3')" class="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import * as utils from "@/utils/index.js";
import MetaInfo from "@/mixins/MetaInfo";
import { isMobile } from "mobile-device-detect";
import { mapActions } from "vuex";

export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "About Us",
      meta: [{ description: "this is meta description" }],
      isMobile: isMobile
    };
  },
  components: {},
  mounted() {
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    }),
    //...utils,
    getImgUrl(id) {
      var images = require.context("@/assets/images/mock/", false, /\.png$/);
      return images("./" + id + ".png");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.text-decoration-underline {
  text-decoration: underline;
}

.about {
  padding-right: 0;
  @include breakpoint("large") {
    padding-right: 50px;
  }
}
.about-title {
  font-family: "Playfair-bold";
  color: $gold;
  font-size: 2.2em;
  position: relative;
  @include breakpoint("medium") {
    margin-bottom: inherit;
  }
}
.about-content {
  padding: 66px 0;
  border: none;
  max-width: 525px;
  @include breakpoint("medium") {
    border-top: 1px solid $gold;
    border-bottom: 1px solid $gold;
    margin: 55px 45px;
  }
  &.no-border-bottom {
    border-bottom: none;
    @include breakpoint("medium") {
      border-bottom: 1px solid $gold;
    }
  }
  p {
    font-size: 16px;
    line-height: 1.4em;
    margin-bottom: 27px;
    @include breakpoint("large") {
      font-size: 18px;
    }

    &.about-standout {
      font-size: 1.3em;
      font-weight: bold;
      padding: 20px 0 0;
      color: $gold;
      @include breakpoint("large") {
        padding: 20px 50px;
      }
    }
  }
}
.about-content-mobile {
  border-top: 0;
  border-bottom: 0;
  padding: 15px 0;
}
.about-img .img-container {
  height: 80%;
}

.about-img img {
  height: 100%;
  object-fit: cover;
}
.border-bottom {
  position: absolute;
  border-bottom: 1px solid $gold !important;
  min-width: 185px;
  left: 0;
  bottom: 0;
}

.about {
  & > .row {
    &:nth-child(odd) {
      background-color: $medium-brown;
      @include breakpoint("medium") {
        background-color: initial !important;
      }
    }
    &:nth-child(even) {
      background-color: $dark-brown;
      @include breakpoint("medium") {
        background-color: initial !important;
      }
    }

    & > div {
      padding: 40px 25px 20px;
      @include breakpoint("medium") {
        padding: 100px 0 100px 20px; //0 15px;
      }
      @include breakpoint("xxl") {
        padding: 100px 0 100px 160px;
      }

      img {
        display: none;
        @include breakpoint("medium") {
          display: inline-block;
        }
      }
    }
    & > div.section-2 {
      @include breakpoint("medium") {
        padding: 100px 0 100px 65px;
      }
    }
    & .about-img {
      display: none;
      padding: 0;

      &.section-1,
      &.section-3 {
        @include breakpoint("medium") {
          padding: 20px;
        }
      }

      @include breakpoint("medium") {
        display: block;
      }
    }
  }
}
</style>

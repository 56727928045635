<template>
	<div class="venue-container">
		<small-loader v-if="!venues"></small-loader>

		<div v-if="origData.length > 0" style="padding: 40px">
			<br />
			<center>
				<div>
					<div :class="'search-btn' + (isOpen ? ' active' : '')">
						<button class="n8-btn" v-on:click="open">Sort and Filter</button>
						<a
							v-on:click="close"
							:class="'search-close' + (isOpen ? ' active' : '')"
						>
							<i class="fa fa-times" style="font-size: 1.4em"></i>
						</a>
					</div>
					<div :class="'search-filter' + (isOpen ? ' active' : '')">
						<div class="search-filter-options row">
							<!--
              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="search-filter-option">
                  <div class="search-filter-option-title">SORT BY</div>
                  <div class="btn-group">
                    <button
                      v-for="(opt, index) in filter_options.sort_by"
                      v-bind:key="index"
                      type="button"
                      :class="checkSelected('sort_by', opt.value)"
                      v-on:click="
                        setFilterSingle(
                          'sort_by',
                          'sort_by_' + opt.value,
                          opt.value
                        )
                      "
                    >
                      {{ opt.label }}
                    </button>
                  </div>
                </div>
              </div>
              -->
							<div class="col-lg-6 col-md-6 col-sm-12">
								<div class="search-filter-option">
									<div class="search-filter-option-title">ALPHABETICAL</div>
									<div class="btn-group">
										<button
											v-for="(opt, index) in filter_options.alphabet"
											v-bind:key="index"
											type="button"
											:class="
												typeof filters['alphabet'] != 'undefined' &&
												filters['alphabet'] == opt.value
													? 'btn n8-btn filter-active'
													: 'btn n8-btn'
											"
											v-on:click="
												setFilterSingle(
													'alphabet',
													'alphabet_' + opt.value,
													opt.value
												)
											"
										>
											{{ opt.label }}
										</button>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-6 col-sm-12">
								<div class="search-filter-option">
									<div class="search-filter-option-title">LOCATION</div>
									<div class="btn-group">
										<select
											name="location"
											v-on:change="setLocation($event)"
											v-model="selectedLocation"
										>
											<option value="">Select Country</option>
											<option
												v-for="(opt, index) in filter_options.location"
												v-bind:key="index"
												:value="opt.name"
											>
												{{ opt.name }}
											</option>
										</select>
									</div>
								</div>
							</div>
						</div>
						<hr style="background-color: silver; margin-bottom: 30px" />
						<div class="search-filter-buttons">
							<button
								class="n8-btn search-filter-button-undo"
								v-on:click="undo"
							>
								<i class="fa fa-undo"></i> Undo Changes
							</button>
							<button
								class="n8-btn search-filter-button-reset"
								v-on:click="reset"
							>
								<i class="fa fa-sync"></i> Reset All
							</button>
							<button
								class="n8-btn search-filter-button-apply"
								v-on:click="apply"
							>
								<i class="fa fa-arrow-down"></i> Apply
							</button>
						</div>
					</div>
				</div>
			</center>
			<br />
		</div>

		<div class="row venue-list m-0" v-if="filteredData.length > 0">
			<div
				v-for="(item, index) in filteredData"
				:key="index"
				@mouseenter="setActiveItem(item.objectID)"
				@mouseleave="unsetActiveItem"
				@click="itemClicked(item)"
				class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4"
			>
				<div
					class="slider-container cursor-pointer"
					:class="selected == item.objectID ? `active` : ``"
				>
					<div class="inner">
						<img
							:src="
								item.mediaFiles &&
								item.mediaFiles[0] &&
								item.mediaFiles[0].value
									? getImage(item.mediaFiles[0].value)
									: defaultImg
							"
							class="slider-img"
							@error="loadDefaultImage"
						/>
						<div class="venue-info" style="opacity: 1">
							<div class="theatre">{{ item.name }}</div>
							<div class="country">{{ item.city }} , {{ item.country }}</div>
						</div>
						<div class="slider-info">
							<div>&nbsp;</div>
							<router-link
								:to="{ name: 'venuesDetail', params: { id: item.objectID } }"
								class="info"
							>
								<i class="icon fas fa-info-circle"></i> Info
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<h3 class="text-center p-5">
				<span class="not-found">No search results found.</span>
			</h3>
		</div>
	</div>
</template>

<script>
import axios from "@/axios-instance";
import { mapState } from "vuex";
import smallLoader from "@/components/SmallLoader.vue";
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
	props: {
		venues: Array,
		searchBy: {
			default: "",
			value: String,
		},
	},
	components: {
		smallLoader,
	},
	data() {
		var data = {
			isOpen: false,
			selected: null,
			defaultImg: "/img/thumbnail-default.png",
			origData: [],
			venuesCopy: [],
			filters: {
				sort_by: "",
				alphabet: "",
				location: "",
			},
			filter_options: {
				sort_by: [
					{ value: "alphabetical", label: "Alphabetical" },
					{ value: "distance", label: "Distance" },
				],
				alphabet: [
					{ value: "asc", label: "A to Z" },
					{ value: "desc", label: "Z to A" },
				],
				location: [],
			},
			selectedLocation: "",
		};

		return data;
	},
	created() {
		axios
			.get(location.origin + "/data/countries.json")
			.then((response) => {
				this.filter_options.location = response.data;
			})
			.catch((err) => {
				console.log(["countries fetch error", err]);
			});
	},
	mounted() {
		this.origData = this.venues;
		this.venuesCopy = this.venues;
	},
	computed: {
		...mapState({
			searchKey: (state) => state.search.searchKey,
			searchFiltersVenue: (state) => state.search.searchFiltersVenue,
		}),
		filteredData() {
			console.log(["filteredData VENUES", this.venues]);
			return this.venuesCopy.filter(
				(item) =>
					typeof item.meta != "undefined" &&
					item.meta.toLowerCase() == "venuedetails"
			);
		},
	},
	methods: {
		itemClicked(item) {
			this.$router.push({
				name: "venuesDetail",
				params: { id: item.objectID },
			});
		},
		getImage(path) {
			return urlHelper.resolveCdnPath(path);
		},
		setActiveItem(itemId) {
			this.selected = itemId;
		},
		unsetActiveItem() {
			this.selected = null;
		},
		loadDefaultImage(event) {
			event.target.src = this.defaultImg;
		},
		open() {
			this.isOpen = true;
		},
		close() {
			this.isOpen = false;
		},
		apply() {
			this.$store
				.dispatch("search/SET_SEARCH_FILTERS_VENUE", this.filters)
				.then(() => {
					var temp = JSON.parse(JSON.stringify(this.origData));

					// filter by location
					if (
						typeof this.searchFiltersVenue.location != "undefined" &&
						this.searchFiltersVenue.location != ""
					) {
						console.log(["location", this.searchFiltersVenue.location]);
						temp = temp.filter((item) => {
							console.log(["locationcheck", item]);
							return (
								item.country &&
								item.country.toLowerCase() ==
									this.searchFiltersVenue.location.toLowerCase()
							);
						});
					}

					// filter by alphabetical
					if (this.searchFiltersVenue.alphabet != "") {
						if (this.searchFiltersVenue.alphabet == "asc") {
							temp = temp.sort();
						} else {
							temp = temp.reverse();
						}
					}

					// sort
					if (
						typeof this.searchFiltersVenue.sort_by != "undefined" &&
						this.searchFiltersVenue.sort_by != ""
					) {
						switch (this.searchFiltersVenue.sort_by) {
							case "alphabetical":
								temp = temp.sort((a, b) => {
									let label_a = a.name.toLowerCase();
									let label_b = b.name.toLowerCase();
									if (label_a > label_b) {
										return 1;
									} else {
										return -1;
									}
								});
								break;
							case "distance":
								temp = temp.sort((a, b) => {
									let a_dt = new Date(Date.parse(a.createdDate));
									let b_dt = new Date(Date.parse(b.createdDate));
									if (a_dt > b_dt) {
										return 1;
									} else {
										return -1;
									}
								});
								break;
						}
					}

					this.venuesCopy = temp;
				});
		},
		reset() {
			this.filters = {
				sort_by: "",
				location: "",
				alphabet: "",
			};
			this.venuesCopy = this.origData;
			this.selectedLocation = "";
			this.setLocation({ target: { value: "" } });
		},
		undo() {
			this.filters = {
				sort_by: "",
				location: "",
				alphabet: "",
			};
			this.venuesCopy = this.origData;
		},
		setLocation(event) {
			this.setFilterSingle(
				"location",
				"location_" + event.target.value,
				event.target.value
			);
		},
		setFilterSingle(key_filter, key_ui, value) {
			this.filters[key_filter] = value;
		},
		setFilterMultiple(key_filter, key_ui, value) {
			this.filters[key_filter][value] = !this.filters[key_filter][value];
		},
		checkSelected(key_filter, value) {
			if (
				typeof this.filters[key_filter] != "undefined" &&
				this.filters[key_filter] == value
			) {
				return "btn n8-btn filter-active";
			} else {
				return "btn n8-btn";
			}
		},
	},
	watch: {
		venues: function (newdata) {
			this.origData = newdata;
			this.reset();
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.not-found {
	font-family: "Playfair-bold";
	color: $gold;
}

.slider-container {
	position: relative;
	width: 100%;
	padding-top: 75%;
	margin-right: 20px;

	.inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.searchTxt {
	font-family: "Playfair-bold";
	color: $gold;
}
.search-btn {
	padding: 20px 0;
	margin: 0 20px;
	position: relative;
	&.active {
		background-color: #000;
		border-left: 1px solid $gold;
		border-right: 1px solid $gold;
		border-top: 1px solid $gold;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		> button {
			border: 0 !important;
		}
	}
	> .search-close {
		position: absolute;
		top: 20px;
		right: 30px;
		display: none;
		&.active {
			display: block;
		}
	}
}
select {
	background: #000;
	color: $gold;
	padding: 7px 10px;
	border-radius: 30px;
	border: 1px solid $gold;
	max-width: 100%;
}
.search-filter {
	background-color: #000;
	padding: 10px 20px;
	margin: 0 20px;
	border-left: 1px solid $gold;
	border-right: 1px solid $gold;
	border-bottom: 1px solid $gold;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
	display: none;
	&.active {
		display: block;
	}
}

.search-filter-buttons {
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
	> button {
		float: right;
		font-size: 1em;
		font-weight: bold;
		width: 200px;
		margin-left: 30px;

		@include breakpoint("small") {
			width: 100% !important;
			margin-bottom: 10px;
			font-size: 0.8em !important;
		}
	}
}

.search-filter-button-apply {
	background-color: $gold;
	width: 170px !important;

	@include breakpoint("large") {
		width: 300px !important;
	}
}
.search-filter-button-reset {
	background-color: silver;
	border: 0;
	color: #000;

	@include breakpoint("medium") {
		width: 170px !important;
	}
}

.search-filter-button-undo {
	@include breakpoint("medium") {
		width: 170px !important;
	}
}

.search-filter-option {
	display: inline-block;
	width: 100%;
	margin-bottom: 20px;
	color: $gold;
	> .btn-group {
		width: 100%;
		> button {
			font-size: 0.8em !important;
			padding: 10px 5px !important;
		}
		> button:active,
		> button:focus {
			background-color: transparent !important;
		}
		> button.filter-active {
			background-color: $gold !important;
		}
	}
	> .search-filter-option-title {
		text-align: left;
		font-size: 0.7em;
		font-weight: bold;
		margin-bottom: 5px;
	}
}
.btn-grids > .btn-grid {
	margin-top: -1px;
}
.btn-grid {
	display: flex;
	justify-content: center;
	> button {
		margin-left: -1px;
		width: 200px;
		min-width: 130px;
	}
	> button:active,
	> button:focus {
		background-color: transparent !important;
	}
	> button.filter-active {
		background-color: $gold !important;
	}
}
button.filter-active {
	background-color: $gold !important;
}
.no-radius {
	border-radius: 0 !important;
}
.radius-top-left {
	border-top-left-radius: 30px !important;
}
.radius-top-right {
	border-top-right-radius: 30px !important;
}
.radius-bottom-left {
	border-bottom-left-radius: 30px !important;
}
.radius-bottom-right {
	border-bottom-right-radius: 30px !important;
}
</style>

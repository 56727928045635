<template>
  <div class="confirmpassword-container">
    <h1 class="font-22 text-gold font-weight-bold">Confirm Password</h1>
    Please enter and confirm your new password.
    <validation-observer ref="confirmPasswordForm" #default="{ invalid }">
      <div class="form-group">
        <ValidationProvider
          name="Password"
          rules="required|minPassword:8"
          v-slot="{ errors, invalid, dirty, changed }"
          vid="password"
        >
          <md-field
            :md-toggle-password="false"
            :class="invalid && dirty & changed ? 'md-invalid' : ''"
          >
            <label for="input-password">Password *</label>
            <md-input
              type="password"
              class="form-control n8-text"
              id="input-password"
              aria-describedby="emailHelp"
              placeholder="Enter password"
              v-model="password"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider
          name="ConfirmPassword"
          rules="required|confirmed:password"
          v-slot="{ errors, invalid, dirty, changed }"
        >
          <md-field
            :md-toggle-password="false"
            :class="invalid && dirty & changed ? 'md-invalid' : ''"
          >
            <label for="confirm-password">Confirm Password *</label>
            <md-input
              type="password"
              class="form-control n8-text"
              id="confirm-password"
              aria-describedby="emailHelp"
              placeholder="Enter confirm password"
              v-model="confirmPassword"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <MdButton
        class="n8-btn light-theme md-raised mt-5"
        :class="invalid ? `disabled` : ``"
        @click="submit"
      >
        <span class="btn-label">Submit</span>
      </MdButton>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { confirmed, min } from "vee-validate/dist/rules";
extend("minPassword", {
  ...min,
  message: "Minimum password length is 8"
});
extend("confirmed", {
  ...confirmed,
  message: "The confirmation password does not match"
});
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      password: null,
      confirmPassword: null,
      email: this.$route.query.u,
      code: this.$route.query.c,
      showSnackbar: false,
      message: null,
      notificationType: null
    };
  },
  mounted() {},
  methods: {
    async submit() {
      try {
        const success = await this.$refs.confirmPasswordForm.validate();
        if (success) {
          let payload = {
            email: this.email,
            code: this.code,
            newPassword: this.confirmPassword
          };

          let response = await this.$store.dispatch("auth/confirmPassword", payload);

          if (!response.message) {
            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "success",
              message: "Success."
            });
            setTimeout(() => {
              this.$router.push("/login");
            }, 4000);
          } else {
            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "error",
              message: response.message
            });
          }
        }
      } catch (error) {
        console.log("Error confirm password", error.message);
        this.$store.dispatch("general/SET_NOTIFICATION", {
          showNotif: true,
          type: "error",
          message: error.message
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
h1 {
  font-family: "Opensans-regular";
}
.sub-heading {
  font-family: "Opensans-regular";
  font-size: 14px;
  margin-bottom: 30px;
}
.confirmpassword-container {
  margin: 80px 0;
}
</style>

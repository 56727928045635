export default class AuthTokens {
  constructor({
    idToken,
    accessToken,
    refreshToken,
    idTokenExpiry,
    claims,
  } = {}) {
    this.idToken = idToken;
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.idTokenExpiry = idTokenExpiry;
    this.claims = claims;
  }
}

<template>
  <div class="venue-container px-2 py-2">
    <small-loader v-if="!venues"></small-loader>
    <div class="row venue-list" v-if="filteredData.length > 0">
      <div
        v-for="(item, index) in filteredData"
        :key="index"
        @mouseenter="setActiveItem(item.id)"
        @mouseleave="unsetActiveItem"
        @click="itemClicked(item)"
        class="col-lg-4 col-md-6 col-sm-6 col-xs-12 m-0 mb-4"
      >
        <div class="slider-container cursor-pointer" :class="selected == item.id ? `active` : ``">
          <div class="inner">
            <img :src="getImage(item.img)" class="slider-img" @error="loadDefaultImage" />
            <div class="venue-info" style="opacity: 1">
              <div class="theatre">{{ item.name }}</div>
              <div class="country">{{ item.city }} , {{ item.country }}</div>
            </div>
            <div class="slider-info">
              <div>&nbsp;</div>
              <router-link :to="{ name: 'venuesDetail', params: { id: item.id } }" class="info">
                <i class="icon fas fa-info-circle"></i> Info
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3 class="text center">
        <span class="not-found">No search results found.</span>
      </h3>
    </div>
  </div>
</template>

<script>
import smallLoader from "@/components/SmallLoader.vue";
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    venues: Array,
    searchBy: {
      default: "",
      value: String
    }
  },
  components: {
    smallLoader
  },
  data() {
    return {
      selected: null,
      defaultImg: "/img/thumbnail-default.png"
    };
  },
  computed: {
    filteredData() {
      return this.venues.filter(
        venue =>
          venue.name.toLowerCase().includes(this.searchBy.toLowerCase()) ||
          venue.state.toLowerCase().includes(this.searchBy.toLowerCase()) ||
          venue.country.toLowerCase().includes(this.searchBy.toLowerCase())
      );
    }
  },
  methods: {
    itemClicked(item) {
      this.$router.push({
        name: "venuesDetail",
        params: { id: item.id }
      });
    },
    getImage(path) {
      return urlHelper.resolveCdnPath(path);
    },
    setActiveItem(itemId) {
      this.selected = itemId;
    },
    unsetActiveItem() {
      this.selected = null;
    },
    loadDefaultImage(event) {
      event.target.src = this.defaultImg;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
.not-found {
  font-family: "Playfair-bold";
  color: $gold;
}

.slider-container {
  position: relative;
  width: 100%;
  padding-top: 75%;

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div class="address-container">
    <h2 class="title pb-3">Address</h2>
    <div class="mt-4">
      <div class="form-group">
        <md-field>
          <label>Address Line 1</label>
          <md-input
            type="text"
            class="form-control n8-text"
            v-model="address"
          />
        </md-field>
      </div>
      <div class="form-group">
        <md-field>
          <label>Address Line 2</label>
          <md-input
            type="text"
            class="form-control n8-text"
            v-model="address2"
          />
        </md-field>
      </div>
      <div class="form-group">
        <md-field>
          <label>City</label>
          <md-input type="text" class="form-control n8-text" v-model="city" />
        </md-field>
      </div>
      <div class="form-group">
        <md-field>
          <label>Post Code</label>
          <md-input
            type="text"
            class="form-control n8-text"
            v-model="postCode"
          />
        </md-field>
      </div>
      <div class="form-group">
        <md-field>
          <label>State</label>
          <md-input type="text" class="form-control n8-text" v-model="state" />
        </md-field>
      </div>
      <div class="form-group">
        <md-field>
          <label>Country</label>
          <md-select v-model="country">
            <md-option disabled value="">Please Select</md-option>
            <md-option
              v-for="(item, index) in countries"
              v-bind:key="index"
              :value="item.code"
            >
              {{ item.name }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "MyAddress",
  data() {
    return {
      address: null,
      city: null,
      postCode: null,
      state: null,
      country: null,
      address2: null,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      userInfo: "auth/getUserDetails",
    }),
    ...mapState({
      countries: (state) => state.myDetails.countries,
    }),
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.mapValue(newVal);
        }
      },
    },
  },
  mounted() {},
  methods: {
    mapValue(data) {
      this.address = data.address;
      this.city = data.city;
      this.postCode = data.postCode;
      this.state = data.state;
      this.country = this.getSelectedCountryCode(data.country);
      this.address2 = data.secondaryAddress;
    },
    getSelectedCountryCode(object) {
      let country = this.countries.find(item => JSON.stringify(item) === object)
      if (country) {
        return country.code
      }
      return ''
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";
h2 {
  color: $gold;
  font-size: 32px;
  font-weight: bold;
  &.title {
    border-bottom: 0.5px solid #aaa4a1;
  }
  @include breakpoint("small") {
    font-family: "Opensans-regular";
    font-size: 22px;
    color: white;
  }
}
.address-container {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  padding: 40px;
  flex: 50%;
  margin-left: 2rem;

  label {
    letter-spacing: 0.996px;
    text-transform: uppercase;
  }

  @include breakpoint("small") {
    background: transparent;
    padding: 40px 22px;
  }
}
@media only screen and (max-width: 991px) {
  .address-container {
    flex: 100%;
    margin-left: unset;
  }
}
</style>

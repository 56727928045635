<template>
  <md-snackbar
    :md-position="position"
    :md-active.sync="showNotification"
    :md-persistent="true"
    :class="notificationType"
    :md-duration="7000"
    @md-closed="closeNotification"
  >
    <span>{{ message }}</span>
    <!-- <md-button class="md-primary" @click="showSnackbar = false">Retry</md-button> -->
  </md-snackbar>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "NotificationBar",
  data() {
    return {
      position: "center",
    };
  },
  computed: {
    showNotification: {
      get() {
        return this.$store.state.general.showNotification;
      },
      set(newValue) {
        this.$store.dispatch("general/SET_NOTIFICATION", {
          showNotif: newValue,
          type: null,
          message: null,
        });
      },
    },
    ...mapState({
      notificationType: state => state.general.notificationType,
      message: state => state.general.notificationMessage,
    }),
    ...mapGetters([
      "general/getNotificationType",
      "general/getNotificationMessage",
    ]),
  },
  watch: {},
  mounted() {
    this.showNotification = this.showSnackbar;
  },
  methods: {
    closeNotification() {
      this.showNotification = false;
    },
  },
};
</script>
<style scoped>
.md-snackbar {
  z-index: 1005;
}
</style>
<template>
  <div class="changepassword-container">
    <h1 class="font-22 text-gold font-weight-bold">Change Password</h1>
    Please enter your old password and new password
    <validation-observer ref="changePasswordForm" #default="{ invalid }">
      <div class="form-group">
        <ValidationProvider
          name="OldPassword"
          rules="required"
          v-slot="{ errors, invalid, dirty, changed }"
          vid="oldPassword"
        >
          <md-field
            :md-toggle-password="false"
            :class="invalid && dirty & changed ? 'md-invalid' : ''"
          >
            <label>Old Password *</label>
            <md-input
              type="password"
              class="form-control n8-text"
              placeholder="Old password"
              v-model="oldPassword"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider
          name="NewPassword"
          rules="required|minPassword:8"
          v-slot="{ errors, invalid, dirty, changed }"
          vid="newPassword"
        >
          <md-field
            :md-toggle-password="false"
            :class="invalid && dirty & changed ? 'md-invalid' : ''"
          >
            <label>New Password *</label>
            <md-input
              type="password"
              class="form-control n8-text"
              placeholder="New password"
              v-model="newPassword"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <MdButton
        class="n8-btn light-theme md-raised mt-5"
        :class="invalid ? `disabled` : ``"
        @click="submit"
      >
        <span class="btn-label">Submit</span>
      </MdButton>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { min } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
extend("minPassword", {
  ...min,
  message: "Minimum password length is 8"
});
export default {
  name: "ChangePassword",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      message: null,
      notificationType: null
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser"
    })
  },
  mounted() {},
  methods: {
    async submit() {
      const success = await this.$refs.changePasswordForm.validate();
      if (success) {
        let payload = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          userId: this.user.sub
        };
        this.$store
          .dispatch("auth/changePassword", payload)
          .then(() => {
            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "success",
              message: "Success."
            });
            setTimeout(() => {
              this.$router.push("/mydetails");
            }, 2000);
          })
          .catch(err => {
            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "error",
              message:
                err.response.code === "NotAuthorizedException"
                  ? "Incorrect password."
                  : err.response.message
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Opensans-regular";
}
.changepassword-container {
  margin: 80px 0;
}
</style>

<template>
  <div
    @click="gotoDetail"
    class="info-box slider-container cursor-pointer"
    @mouseenter="mouseEnter(id)"
    @mouseleave="mouseLeave"
    :class="selectedItem == id ? `active` : ``"
  >
    <div class="info-box-img-container">
      <div class="inner">
        <img :src="getImgUrl(img)" class="info-box-img" />
        <div class="info-box-description">
          <div class="info-box-title">
            {{ title }}
          </div>
          <div class="info-box-subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div class="info-box-icon n8-gradient-black-transparent">
          <div class="n8-btn" @click="gotoDetail">
            <i class="icon fas fa-info-circle"></i>&nbsp;&nbsp; Info
          </div>
        </div>
      </div>
    </div>
    <div v-if="bottomText != ''" class="info-box-content d-lg-block">
      {{ bottomText }}
    </div>
  </div>
</template>
<script>
/*
 * DOC:
 * >> Expected Item Object Structure:
 *    - TBD - (Make it generic via dynamic props)
 */
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    id: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    bottomText: {
      type: String,
      default: ""
    },
    routeToDetail: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      defaultImg: "/img/thumbnail-default.png",
      selectedItem: null
    };
  },
  methods: {
    getImgUrl(img) {
      return img ? urlHelper.resolveCdnPath(img) : this.defaultImg;
    },
    gotoDetail() {
      this.$router.push({ name: this.routeToDetail, params: { id: this.id } }).catch(() => {});
    },
    mouseEnter: function(id) {
      this.selectedItem = id;
    },
    mouseLeave: function() {
      this.selectedItem = null;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/components/infobox";
</style>

<template>
  <div>
    <VueFaqAccordion
      :items="list"
      fontColor="silver"
      activeColor="#FFFFFF"
      borderColor="transparent"
      questionProperty="title"
      answerProperty="content"
      :initialQuestionIndex="0"
      :class="customClasses"
      v-slot="itemData"
    >
      <video v-if="videoSrc(itemData)" width="900" height="525" controls>
        <source :src="videoSrc(itemData)" type="video/mp4" />
      </video>
    </VueFaqAccordion>
  </div>
</template>
<script>
/*
 * DOC:
 * >> Expected List Structure:
 *    - TBD - (Make it generic via dynamic props)
 */

import VueFaqAccordion from "vue-faq-accordion";
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    customClasses: {
      type: String,
      default: ""
    }
  },
  components: {
    VueFaqAccordion
  },
  mounted() {
    let x = this.list;
    console.log(x);
  },
  methods: {
    showVideo(data) {
      if (data.title == "How Do I View the VR Shows?") return true;
      return false;
    },
    videoSrc(data) {
      switch (data.title) {
        case "How Do I View the VR Shows?":
          return this.resolveCdnPath(
            "https://n8-contents.s3.ap-southeast-2.amazonaws.com/trailers/faqs/chromeFlag.mp4"
          );
        case "Oculus Headsets Settings":
          return this.resolveCdnPath(
            "https://n8-contents.s3.ap-southeast-2.amazonaws.com/trailers/faqs/OutSourceOC.mp4"
          );
        case "VR headset not detected":
          return this.resolveCdnPath(
            "https://n8-contents.s3.ap-southeast-2.amazonaws.com/trailers/faqs/REgistry.mp4"
          );
        case "How to allow your browser to play VR content":
          return this.resolveCdnPath(
            "https://n8-contents.s3.ap-southeast-2.amazonaws.com/trailers/faqs/SiteBlock.mp4"
          );
        default:
          return "";
      }
    },
    resolveCdnPath(path) {
      return urlHelper.resolveCdnPath(path);
    }
  }
};
</script>
<style lang="scss">
nav.faq__nav {
  display: none !important;
}
</style>

<template>
  <div class=" n8-cookies-container">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="n8-cookies">
            <div class="inner">
              <h3 class="gold">Cookies on Boxofficevr.com</h3>
              <p>We use some essential cookies to make this website work.</p>

              <p>
                We’d like to set additional cookies to understand how you use Boxofficevr.com,
                remember your settings and improve our services.
              </p>

              <p>
                We also use cookies set by other sites to help us deliver content from their
                services.
              </p>
              <div class="button-container">
                <MdButton class="n8-btn gold-btn md-raised grey" @click="closeBar(true)">
                  <span class="btn-label">Accept All Cookies</span>
                </MdButton>
                <MdButton
                  class="n8-btn n8-btn-gray grey-btn md-raised grey"
                  @click="closeBar(false)"
                >
                  <span class="btn-label">Decline Cookies</span>
                </MdButton>
                <router-link to="/privacy-policy"> View Privacy Policy </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeBar(val) {
      this.$emit("onClose", val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.n8-cookies-container {
  background: black;
  position: relative;
  width: 100%;
  z-index: 10;

  h3 {
    margin-bottom: 20px;
  }
}
.n8-cookies {
  width: 100%;
  font-size: 14px;
  height: auto;
  padding: 20px 0;
  top: 0;
  left: 0;
  width: 100%;
  @include breakpoint("large") {
    padding: 30px 40px;
  }
}
.button-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  button {
    display: block;
    width: auto;
    padding: 0 20px;
    margin: 0 0 10px 0;
    @media only screen and (min-width: 530px) {
      display: inline-block;
      margin: 0 8px 10px 0;
    }
    @media only screen and (min-width: 640px) {
      margin: 0 15px 10px 0;
    }
  }
  a,
  a:hover {
    color: $gold !important;
    display: inline-block;
    font-family: "playfair-regular";
    font-size: 14px;
    padding: 15px 0;
  }
  @media only screen and (min-width: 530px) {
    display: inline-block;
    text-align: inherit;
  }
}
</style>

<template>
  <div class="confirmregistration-container bg-confirm-registration">
    <div class="confirm-registration-inner text-center">
      <h1 class="font-22">You have successfully verified your account!</h1>
      <MdButton
        v-if="hasVerify"
        class="n8-btn n8-btn-gray light-theme md-raised mt-5"
        href="/login"
      >
        <span class="btn-label">Click here to log in</span>
      </MdButton>
    </div>
  </div>
</template>
<script>
import MetaInfo from "@/mixins/MetaInfo";
import { mapActions } from "vuex";

export default {
  components: {},
  mixins: [MetaInfo],
  data() {
    return {
      title: "Confirm Registration",
      meta: [{ description: "this is meta description" }],
      hasVerify: false,
      userName: this.$route.query.u,
      code: this.$route.query.c,
      userId: this.$route.query.uid,
      firstName: this.$route.query.fname,
      lastName: this.$route.query.lname
    };
  },
  mounted() {
    this.verifyUser();
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    }),
    async verifyUser() {
      try {
        const data = {
          email: this.userName,
          code: this.code
        };
        const verified = await this.$store.dispatch("auth/confirmSignup", data);
        if (verified) {
          this.hasVerify = true;
          const payload = {
            id: this.userId,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.userName
          };
          await this.$store.dispatch("auth/verifyUser", payload);
        }
      } catch (err) {
        this.showError(err);
      }
    },
    showError(err) {
      this.$toast.add({
        severity: "error",
        summary: "Error when verifying user",
        detail: err.message,
        life: 3000
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";
.confirmregistration-container {
  margin-top: 65px !important;

  @include breakpoint("medium") {
    margin-top: 145px !important;
  }
  @include breakpoint("large") {
    margin: 0 auto;
  }
}
.bg-confirm-registration {
  border-radius: 25px;
  padding: 137px 52px;
  max-width: 410px;
  background-color: #24211f;
  border: 1px #ae8138 solid;

  @include breakpoint("medium") {
    width: 410px;
  }
}
h1 {
  font-family: "Opensans-regular" !important;
  color: $gold;
}
.confirm-registration-inner {
  // margin-top: 137px !important;
  // margin-bottom: 137px !important;
  @include breakpoint("large") {
    margin: 0 auto;
  }
}
</style>

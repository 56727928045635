<template>
  <div class="panel-login-signup">
    <div hidden class="info-title">
      <span class="font-weight-bold">Log In or Sign Up</span>
    </div>
    <div class="button-container">
      <MdButton
        v-if="!isLoggedIn"
        class="n8-btn n8-btn-gray light-theme md-raised"
        @click="toggleDisplayState('LOGIN')"
      >
        <span class="btn-label">Login</span>
      </MdButton>
      <div v-if="!isLoggedIn" class="line">or</div>
      <MdButton
        v-if="!isLoggedIn"
        class="n8-btn n8-btn-black gold-theme md-raised"
        @click="toggleDisplayState('SIGNUP')"
      >
        <span class="btn-label">Sign Up</span>
      </MdButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "LoginOrSignup",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toggleDisplayState(selectedName) {
      this.$store.dispatch({
        type: "homepage/SET_MEMBERSHIP_DISPLAY",
        data: selectedName
      });
    }
  },
  computed: {
    ...mapState({
      membershipDisplay: state => state.homepage.membershipDisplay
    }),
    ...mapGetters(["homepage/getMembershipDisplayName"]),
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn"
    })
  },
  props: {}
};
</script>

<template>
  <div class="search-page">
    <div class="row features">
      <div class="col-12">
        <div class="text-center">
          <h3 v-if="searchKey">
            <span class="searchTxt">Search Results for</span>
            &nbsp; <q>{{ searchKey }}</q>
          </h3>
        </div>
        <md-tabs class="tab-container" md-alignment="centered">
          <md-tab id="tab-shows" md-label="SHOWS">
            <shows-component
              :shows="result && result.hits ? result.hits : []"
              :searchBy="searchKey"
            ></shows-component>
          </md-tab>
          <md-tab id="tab-venues" md-label="VENUES">
            <venues-component
              :venues="result && result.hits ? result.hits : []"
              :searchBy="searchKey"
            ></venues-component>
          </md-tab>
        </md-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import showsComponent from "@/components/search/resultShows";
import venuesComponent from "@/components/search/resultVenues";
import MetaInfo from "@/mixins/MetaInfo";

export default {
  mixins: [MetaInfo],
  components: {
    showsComponent,
    venuesComponent
  },
  data() {
    var data = {
      title: "Search",
      meta: [{ description: "" }]
    };

    return data;
  },
  mounted() {
    this.$store.dispatch("search/SEARCH");
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  computed: {
    ...mapGetters(["search/getSearchKey", "search/getAllResult"]),
    ...mapState({
      result: state => state.search.result,
      searchKey: state => state.search.searchKey
    })
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    }),
    sData() {
      this.$store.dispatch("search/GET_ALL_VENUES").then(resp => {
        this.$store.dispatch({ type: "search/SET_ALL_VENUES", data: resp });
      });
    }
  }
};
</script>
<style>
.md-tabs-content,
.md-tab {
  height: auto !important;
  padding-top: 0 !important;
}
</style>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
</style>

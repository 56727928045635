const ui = {
  namespaced: true,
  state: {
    topDarkBgHeight: 600,
    bottomDarkBgHeight: 0,
    isBackgroundBlack: false
  },
  mutations: {
    setTopDarkBgHeight(state, newHeight) {
      state.topDarkBgHeight = newHeight;
    },
    setBottomDarkBgHeight(state, newHeight) {
      state.bottomDarkBgHeight = newHeight;
    },
    setBgBlack(state, isBgBlack){
      state.isBackgroundBlack = isBgBlack;
    }
  },
  actions: {
    SET_TOP_DARK_BG_HEIGHT({ commit }, newHeight) {
      commit("setTopDarkBgHeight", newHeight);
    },
    RESET_TOP_DARK_BG_HEIGHT({ commit }) {
      commit("setTopDarkBgHeight", 0);
    },
    SET_BOTTOM_DARK_BG_HEIGHT({ commit }, newHeight) {
      commit("setBottomDarkBgHeight", newHeight);
    },
    RESET_BOTTOM_DARK_BG_HEIGHT({ commit }) {
      commit("setBottomDarkBgHeight", 0);
    },
    SET_BACKGROUND_BLACK({ commit }, value) {
      commit("setBgBlack", value);
    }
  },
  getters: {
    getTopDarkBgHeight(state) {
      console.log("getsCalled");
      return state.topDarkBgHeight;
    },
    getBottomDarkBgHeight(state) {
      console.log("bottom darg bg called");
      return state.bottomDarkBgHeight;
    },
  },
};

export default ui;

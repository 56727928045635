export default class UrlHelper {

  constructor({
    stage = process.env.STAGE,
    assetsCdnBaseUrl = process.env.VUE_APP_ASSETS_CDN_BASE_URL,
  } = {}) {
    this.stage = stage;
    this.assetsCdnBaseUrl = assetsCdnBaseUrl;
    this.awsCdnDomain = ".amazonaws.com";
  }

  resolveCdnPath(path) {
    if (path && path.indexOf(this.awsCdnDomain) > -1) {

      const assetPath = path.substr(path.indexOf(this.awsCdnDomain) + this.awsCdnDomain.length);
      return this.assetsCdnBaseUrl + assetPath;
    }

    return path;
  }
}
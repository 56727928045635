<template>
  <div class="hero-slideshow-container" v-if="items.length > 0">
    <section class="desktop">
      <VueSlickCarousel
        v-if="items.length > 0"
        ref="slickDesktop"
        v-bind="settings"
        :key="dynamicKey"
        :arrows="true"
        :autoplay="true"
      >
        <div
          class="item image"
          v-for="(item, idx) in items"
          :key="dynamicKey + idx"
          :class="idx == 0 && trailer != `` ? `trailer-item` : ``"
          @click="() => idx == 0 && trailer != `` && playTrailer()"
        >
          <img :src="resolveCdnPath(item.value)" @error="loadDefaultImage" />
        </div>
      </VueSlickCarousel>
    </section>
    <section class="mobile">
      <VueSlickCarousel
        v-if="items.length > 0"
        ref="slickMobile"
        v-bind="settings"
        :arrows="false"
        :autoplay="true"
      >
        <div
          class="item image slider-container"
          v-for="(item, idx) in items"
          :key="dynamicKey + idx"
          :class="idx == 0 && trailer != `` ? `trailer-item` : ``"
          @click="() => idx == 0 && trailer != `` && playTrailer()"
        >
          <div class="hero-item">
            <div class="content-img" :style="getContentImageStyle(item)"></div>
            <div class="slider-title">
              <h1 class="page-heading mb-0">{{ name }}</h1>
              <h2 class="subheading">{{ location }}</h2>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </section>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: () => ""
    },
    location: {
      type: String,
      default: () => ""
    },
    trailer: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      settings: {
        dots: false,
        dotsClass: "slick-dots custom-dot-class",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        variableHeight: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              variableWidth: false,
              variableHeight: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: false,
              variableHeight: false
            }
          }
        ]
      },
      defaultImg: "/img/mock/hero-default.png",
      dynamicKey: Date.now()
    };
  },
  mounted() {
    this.$nextTick(() => {
      // slick unresponsive bug. force slick carousel to update
      setTimeout(() => (this.settings.dots = true), 200);
    });
  },
  methods: {
    getImgUrl(id) {
      var images = require.context("@/assets/images/mock/", false, /\.png$/);
      return images("./" + id + ".png");
    },
    playTrailer() {
      this.$emit("onPlayTrailer");
    },
    getContentImageStyle(item) {
      const resolvedCdnUrl = urlHelper.resolveCdnPath(item.value);
      return { backgroundImage: `url(${resolvedCdnUrl})` };
    },
    resolveCdnPath(path) {
      return urlHelper.resolveCdnPath(path);
    },
    loadDefaultImage(event) {
      event.target.src = this.defaultImg;
    }
  },
  components: {
    VueSlickCarousel
  }
};
</script>

<style lang="scss" scoped>
/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}
.slider-container {
  .hero-item {
    position: relative;
    width: 100%;
  }
  .slider-title {
    opacity: 1;
    position: absolute;
    bottom: 45px;
    padding-left: 3px;
    right: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    text-align: right;
    .page-heading {
      font-size: 32px;
    }
    .subheading {
      font-size: 22px;
    }
  }
}

.hero-slideshow-container .item {
  position: relative;
}
.trailer-item {
  cursor: pointer;
  &:before {
    background: rgba(0, 0, 0, 0.5);
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }
  &:after {
    content: "";
    width: 80px;
    height: 80px;
    background-image: url(~@/assets/images/icons/play-button-trailer.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    z-index: 2;
  }
}
</style>

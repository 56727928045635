<template>
  <span>
	<ol v-if="list.type == 'ol'" :class="(typeof list.style != 'undefined' && list.style != '') ? list.style : 'ol-number'">
		<li v-for="(item, index) in list.list" v-bind:key="`article-item-${index}`">
			<span class="article-item-title">{{ item.title }}</span>
			<div v-if="typeof item.content == 'object' && item.content.length > 0">
        <p v-for="(paragraph, index2) in item.content" v-bind:key="`article-item-paragraph-${index2}`" v-html="paragraph"></p>
			</div>
			<p v-if="typeof item.title != 'undefined' && item.title != ''" class="article-item-content" v-html="item.content"></p>
			<span v-if="typeof item.title == 'undefined' || item.title == ''" class="article-item-content" v-html="item.content"></span>
			<nestedList :list="item.sublist" v-if="typeof item.sublist != 'undefined' && item.sublist.list.length > 0" />
		</li>
	</ol>
	<ul v-if="list.type == 'ul'">
		<li v-for="(item, index) in list.list" v-bind:key="`article-item-${index}`">
			<span class="article-item-title">{{ item.title }}</span>
			<div v-if="typeof item.content == 'object' && item.content.length > 0">
        <p v-for="(paragraph, index2) in item.content" v-bind:key="`article-item-paragraph-${index2}`" v-html="paragraph"></p>
			</div>
			<p v-if="typeof item.title != 'undefined' && item.title != ''" class="article-item-content" v-html="item.content"></p>
			<span v-if="typeof item.title == 'undefined' || item.title == ''" class="article-item-content" v-html="item.content"></span>
			<nestedList :list="item.sublist" v-if="typeof item.sublist != 'undefined' && item.sublist.list.length > 0" />
		</li>
	</ul>
	<br>
	</span>
</template>
<script>
export default {
  name: "nestedList",
  props: {
    list: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style scoped lang="scss">
@import "~@/assets/sass/base/variables";
ol.ol-number {
  counter-reset: section;
  list-style-type: none;
  >li:before {
    counter-increment: section;
    content: counters(section, ".") ". ";
  }
}
ol.ol-letter {
  list-style-type: lower-alpha;
}
ul {
  list-style-type: disc;
}
.article-item-title {
  font-weight: bold;
  font-size: 1.1em;
}
.article-item-content {
}
</style>
import { mapGetters } from "vuex";

const taxRate = {
  computed: {
    ...mapGetters({
      vatRate: "purchaseSettings/vatTaxRate"
    })
  },
  methods: {
    includeTax: function (val) {
      const price = parseFloat(val);
      const taxPrice = price * this.vatRate;
      return parseFloat(price + taxPrice).toFixed(2);
    }
  }
};

export default taxRate;

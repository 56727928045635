// import axios from "@/axios-instance";
import http from "@/axios-instance";

const myDetails = {
  namespaced: true,
  state: {
    userDetails: null,
    orderHistory: [],
    countries: [],
  },
  mutations: {
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    setOrderHistory(state, payload) {
      // console.log('setOrderHistory', payload);
      state.orderHistory = payload;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
  },
  actions: {
    GET_ORDER_HISTORY({ commit, dispatch }, params) {
      console.log("GET_ORDER_HISTRORY params", params);
      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });
        http
          .get(`/order/customer/${params}`, {
            headers: {
              "x-api-key": process.env.VUE_APP_ORDER_API_KEY,
            },
          })
          .then((resp) => {
            let data = resp.data.data;
            if (data.length > 0) {
              data = data.sort(
                (a, b) =>
                  new Date(b.createdDateTime).getTime() -
                  new Date(a.createdDateTime).getTime()
              );
              // console.log('the resp ret', data);
            }
            commit("setOrderHistory", data);
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(resp);
          })
          .catch((error) => {
            reject(error);
          })
          .then(() =>
            dispatch("general/SET_DATALOADING", false, { root: true })
          );
      });
    },
    SET_ORDER_HISTORY({ commit }, params) {
      commit("setOrderHistory", params.data);
    },
    getCountryList({ commit }) {
      http.get("/user/countries").then(
        function (res) {
          commit("setCountries", res.data);
        },
        function (err) {
          console.log(err);
        }
      );
    },
    SEND_ORDER_RECEIPT({ dispatch }, params) {
      console.log("SEND_ORDER_RECEIPT params", params);
      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });
        http
          .get(`/order/send-receipt/${params}`, {
            headers: {
              "x-api-key": process.env.VUE_APP_ORDER_API_KEY,
            },
          })
          .then(() => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            dispatch("general/SET_NOTIFICATION",
              { showNotif: true, type: "success", message: "receipt has been sent to your email." },
              { root: true });
          })
          .catch((error) => {
            console.log('error happened', error);
            reject(error);
            dispatch("general/SET_NOTIFICATION",
              { showNotif: true, type: "error", message: "Something went wrong, please try again later." },
              { root: true });
          })
          .then(() => {
            dispatch("general/SET_DATALOADING", false, { root: true });
          });
      });
    },
  },
  getters: {
    getUserDetails(state) {
      return state.userDetails;
    },
    getOrderHistory(state) {
      return state.orderHistory;
    },
    getCountries(state) {
      return state.countries;
    },
  },
};

export default myDetails;

<template>
  <div>
    <div v-if="!listIsEmpty">
      <VueSlickCarousel
        v-bind="carouselSettings"
        ref="carouselDynamic"
        class="shows-slider"
        @init="onInitCarousel"
        @afterChange="detectIndex"
      >
        <div
          v-for="item in list"
          :key="item.id"
          @mouseenter="setActiveItem(item.id)"
          @mouseleave="unsetActiveItem"
          class="slider-container cursor-pointer"
          :class="selected == item.id ? 'active' : ''"
        >
          <div class="slider-item" v-if="item">
            <show-item v-if="type !== 'similar-shows'" :data="item" />
            <ShowItemSimilarShows v-else :data="item"></ShowItemSimilarShows>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div v-else>
      <VueSlickCarousel
        v-bind="carouselSettings"
        ref="carouselDynamic"
        @init="onInitCarousel"
      >
        <skeleton-box
          v-for="index in 4"
          :key="index"
          width="100%"
          height="500px"
        />
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
/*
 * DOC:
 * >> Expected List Structure:
 *    - TBD - (Make it generic via dynamic props)
 */

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ShowItem from "@/components/shows/ShowItem";
import SkeletonBox from "@/components/skeleton/SkeletonBox.vue";
import ShowItemSimilarShows from "@/components/shows/ShowDetailsSimilarShows";

export default {
  data() {
    return {
      carouselSettings: {
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        lazyLoad: "progressive",
        //focusOnSelect: true,
        responsive: [],
      },
      selected: null,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    customClasses: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
    responsive: {
      type: Array,
      default: () => [
        {
          breakpoint: 920,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  },
  components: {
    VueSlickCarousel,
    ShowItem,
    SkeletonBox,
    ShowItemSimilarShows,
  },
  computed: {
    listIsEmpty: {
      get() {
        return this.list.length < 1;
      },
    },
  },
  beforeMount() {
    // console.log("sliderData", this.list);
    if (this.type === "similar-shows") {
      this.carouselSettings.arrows = true;
      this.carouselSettings.responsive = [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
          },
        },
      ];
    }
  },
  mounted() {
    this.carouselSettings.responsive = this.responsive;
  },
  methods: {
    onInitCarousel() {
      //console.log('our carousel is ready');
    },
    setActiveItem(itemId) {
      this.selected = itemId;
    },
    unsetActiveItem() {
      this.selected = null;
    },
    detectIndex(currentSlide) {
      console.log("handleSwipe", currentSlide);
    },
  },
};
</script>
<style lang="scss" scoped>
.n8-gradient-black-transparent {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>

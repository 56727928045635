<template>
  <div class="terms">
    <h2 style="padding-bottom: 30px;">TERMS AND CONDITIONS</h2>
    <p>
      Please read these Terms carefully. BOXOFFICEVR provides (on the following terms) individuals
      using our website and/or app with access to virtual reality (‘VR’) content streamed over the
      Internet to their VR-enabled devices<br />
      Access to, and use of BoxOfficeVR content (“Content”), BoxOffice VR services (“Services”), and
      the BoxOfficeVR website https://boxofficevr.com/ (“Website”), including any of its content, is
      conditional on your agreement to these Terms. You must read, agree with, and accept all of the
      terms and conditions contained in these Terms. By creating an account, or by using or visiting
      our Website, you are bound to these Terms and you indicate your continued acceptance of these
      Terms
    </p>

    <ol>
      <li>
        <h3>INFORMATION ABOUT US AND HOW TO CONTACT US</h3>
        <ul>
          <li>
            Who we are. BoxOfficeVR is fully owned by Kabuki Drop Limited, a company registered in
            England and Wales. Our company number is SC685863 and our registered office is at 101
            Rose Street, South Lane, Edinburgh, EH2 3JG
          </li>
          <li>
            How to contact us. You can contact us by writing to us at hello@BoxOfficeVR.com or at
            the address above.
          </li>
          <li>
            How we may contact you. If we have to contact you, we will do so by writing to you at
            the email address in your profile on our website (which will usually be the email
            address which you provided to us when you signed up).
          </li>
          <li>
            ‘Writing’ includes emails. When we use the words ‘writing’ or ‘written’ in these terms,
            this includes emails.
          </li>
          <li>
            ‘Our content’. When we refer to ‘our content’, this means our VR content (e.g. VR
            recordings of theatre performances) and our app.
          </li>
        </ul>
      </li>
      <li>
        <h3>BoxOfficeVR Account</h3>
        <p>
          If you create an account on the Website, you are responsible for maintaining the security
          of your account, and you are fully responsible for all activities that occur under the
          account and any other actions taken in connection with the account. You agree to provide
          and maintain accurate, current and complete information, including your contact
          information for notices and other communications from us and your payment information. You
          may not use false or misleading information in connection to your account, or trade on the
          name or reputation of others, and BoxOfficeVR may change or remove any information that it
          considers inappropriate or unlawful, or otherwise likely to expose BoxOfficeVR to claims
          of third parties. You agree that we may take steps to verify the accuracy of information
          you have provided to us.
          <br />
          You are responsible for taking reasonable steps to maintain the confidentiality of your
          username and password. You must immediately notify BoxOfficeVR of any unauthorised uses of
          your information, your account or any other security breaches. BoxOfficeVR will not be
          liable for any acts or omissions by you, including any damages of any kind incurred as a
          result of such acts or omissions.
        </p>
      </li>
      <li>
        <h3>OUR CONTRACT WITH YOU</h3>
        <p>
          Our acceptanceof your order, which is a one time view of the purchased VR content will
          have a time limit from purchase to commencement of viewing (usually 14 days).Further you
          will have 72 hours to complete your viewing of the content once initial commencement of
          viewing the VR content has begun. You must be over 18. You must be over the age of 18 to
          view our VR content and – by signing up you confirm that you are over 18.How to tell us
          about problems. If you have any questions or complaints, please write to us at
          <a href="mailto:hello@boxofficevr.com">hello@BoxOfficeVR.com</a>
        </p>
      </li>
      <li>
        <h3>USAGE</h3>
        <p>
          To view on the web you will need a compatible VR Headset and PC with the necessary
          graphics card for VR, Internet connection and power.
          <br />
          For our app. You need a mobile device running our app in order to view our VR content. Our
          app is available to download from the Apple App Store, Google Play.
          <br />
          Your account. Your BOXOFFICEVR account (and your right to purchase access and view our VR
          content) is for your own personal and non-commercial use. You must not give anyone else
          your username and password or otherwise let anyone else use your account. Aside from your
          right to access and view our VR content in accordance with these terms, you do not have
          any other rights in or to our VR content.
        </p>
      </li>
      <li>
        <h3>OUR VR CONTENT</h3>
        <ul>
          <li>Size of downloads. Our VR content is stored in large files. Therefore:</li>
          <li>
            our VR content should be viewed on a connection with a download speed of at least 10
            mbps. Our VR content may not display properly on connections with lower speeds; and
          </li>
          <li>
            you should ensure that you are aware of (and are satisfied with) the data fees (if any)
            charged by your Internet connection provider.
          </li>
          <li>
            Appearance may vary. The appearance of our VR content may vary from user to user
            depending on a number of factors (for example, device and bandwidth).
          </li>
          <li>
            Changes to our VR content library. For various reasons, we will add and remove certain
            VR content from our platform from time to time.
          </li>
          <li>
            VR device. The device on which you install our app may need to be used with a VR headset
            in order to view our VR content properly. It is your responsibility to ensure that you
            have the necessary headset to view our VR content properly on your device.
          </li>
        </ul>
      </li>
      <li>
        <h3>PROVIDING THE VR CONTENT</h3>
        <ul>
          <li>
            We are not responsible for events outside our control. If the supply of our VR content
            is interrupted by, or deteriorates due to, an event outside our control then we will
            contact you as soon as possible to let you know and we will take steps to minimise the
            effect. Provided we do this, we will not be liable for the interruption(s) and/or
            deterioration caused by the event but – if there is a risk of substantial
            interruption(s) and/or deterioration – you may contact us to end the contract and
            receive a refund of any Credits unused at termination of the contract.
          </li>
        </ul>
      </li>
      <li>
        <h3>PRICE AND PAYMENT FOR CONTENT</h3>
        <ul>
          <li>
            Where to find the price for the VR Content. For VR Content, the price of the content
            (which includes VAT) will be the price stated on the website page when you placed your
            order.
          </li>
          <li>
            How and you must pay for VR Content. We accept payment by all commonly accepted credit
            and debit cards.
          </li>
          <li>
            Changes to the price. These will be shown on the website, including any discounts we may
            offer from time to time.
          </li>
        </ul>
      </li>
      <li>
        <h3>Responsibility of Users of the Website, Content, and/or Services</h3>
        <p>
          Your access to, and all of your use of the Website, Content, and/or Services must be
          lawful and must be in compliance with these Terms, and any other agreement between you and
          BoxOfficeVR.
          <br />
          When accessing or using the Website, Content, and/or Services, you must behave in a civil
          and respectful manner at all times. We specifically prohibit any commercial use of the
          Website, Content, and/or Services, and you agree not to use the Website, for any of the
          following:
        </p>
        <ul>
          <li>
            Engaging in conduct that would constitute a criminal offense, giving rise to civil
            liability or otherwise violate any city, state, national or international law or
            regulation that would fail to comply with accepted internet protocol;
          </li>
          <li>
            Communicating, transmitting, or posting material that is copyrighted or otherwise owned
            by a third party unless you are the copyright owner or have the permission of the owner
            to post it;
          </li>
          <li>
            Communicating, transmitting, or posting material that reveals trade secrets, unless you
            own them or have the permission of the owner;
          </li>
          <li>
            Communicating, transmitting, or posting material that infringes on any other
            intellectual property, privacy or publicity right of another;
          </li>
          <li>
            Attempting to interfere in any way with the Website, or our networks or network
            security, or attempting to use our Website to gain unauthorised access to any other
            computer system;
          </li>
          <li>
            Accessing data not intended for you, or logging on to a server or account, which you are
            not authorised to access;
          </li>
          <li>
            Attempting to probe, scan or test the vulnerability of a system or network or to breach
            security or authentication measures without proper authorization (or succeeding in such
            an attempt);
          </li>
          <li>
            Attempting to interfere or interfering with the operation of the Website, Content,
            and/or Services, or our provision of Services to any other users of the Website, our
            hosting provider or our network, including, without limitation, via means of submitting
            a virus to the Website, overloading, “flooding”, “mail bombing” or “crashing” the
            Website.
          </li>
          <li>
            In addition, if you operate an account, contribute to an account, post material to the
            Website, post links on the Website, or otherwise make material available by means of the
            Website (any such material, "Content"), you are solely responsible for the content of,
            and any harm and damages resulting from that Content. That is the case regardless of
            whether the Content in question constitutes text, graphics, an audio file, or computer
            software. By making Content available, you represent and warrant that:z
          </li>
          <li>
            the downloading, copying and use of the Content will not infringe the proprietary
            rights, including but not limited to the copyright, patent, trademark or trade secret
            rights, of any third party;
          </li>
          <li>
            if your employer has rights to intellectual property you create, you have either (i)
            received written permission from your employer to post or make available the Content,
            including but not limited to any software, or (ii) secured from your employer a written
            waiver as to all rights in or to the Content;
          </li>
          <li>
            you have fully complied with any third party licenses relating to the Content, and have
            done all things necessary to successfully pass through to end users any required terms;
          </li>
          <li>
            the Content does not contain or install any viruses, worms, malware, Trojan horses or
            other harmful or destructive content;
          </li>
          <li>
            the Content is not spam, and does not contain unethical or unwanted commercial content
            designed to drive traffic to third party sites or boost the search engine rankings of
            third party sites, or to further unethical or unlawful acts (such as phishing) or
            mislead recipients as to the source of the material (such as spoofing);
          </li>
          <li>
            the Content is not obscene, libelous, hateful or racially or ethnically objectionable,
            and does not violate the privacy or publicity rights of any third party.
          </li>
        </ul>
        <p>
          If you delete Content, BoxOfficeVR will use reasonable efforts to remove it from the
          Website and our servers, but you acknowledge that caching or references to the Content may
          not be made unavailable to the public immediately.
          <br />
          You are responsible for taking precautions as necessary to protect yourself and your
          computer systems from viruses, worms, Trojan horses, and other harmful or destructive
          content. BoxOfficeVR shall take reasonable precautions to prevent the transmission of
          harmful content from its technology systems to your technology systems.
          <br />
          BoxOfficeVR disclaims any liability for any harm or damages resulting from your access or
          use of the Website, Content, and/or Services, or access or use of non-BoxOfficeVR
          websites.
          <br />
          BoxOfficeVR has the right (though not the obligation) to (i) refuse or remove any Content
          that, in BoxOfficeVR’s reasonable opinion, violates any BoxOfficeVR policy or is in any
          way harmful or objectionable, or (ii) terminates or denies access to and use of the
          Website, Content, and/or Services, to any person for any reason, in BoxOfficeVR’s sole
          discretion.
        </p>
      </li>

      <li>
        <h3>Fees and Payments</h3>
        <p>
          By purchasing Content and/or Services, you agree to pay BoxOfficeVR the relevant fees
          indicated for such Product or Service. Payments will be due at time of purchase.
          <br />
          Configurations and prices of the Website, Content, and/or Services are subject to change
          at any time, and BoxOfficeVR shall at all times be entitled to modify configurations,
          fees, prices and quotations. All prices are exclusive of, and you shall pay all taxes,
          duties, levies or fees, or other similar charges imposed on BoxOfficeVR or yourself by any
          taxing authority (other than taxes imposed on BoxOfficeVR’s income), related to your
          order, unless you have provided BoxOfficeVR with an appropriate resale or exemption
          certificate for the delivery location, which is the location where the Content and/or
          Services are used or performed. In case of changes in law such that a tax is levied that
          is or becomes irrecoverable with a consequent increase to the costs to BoxOfficeVR of
          delivering the Content and/or Services, whereby and to such an extent BoxOfficeVR is
          entitled to increase its prices accordingly and retroactively.
        </p>
      </li>
      <li>
        <h3>Use of Third Party Content and Materials</h3>
        <p>
          BoxOfficeVR has not reviewed, and cannot review, all of the material, including computer
          software, posted to the Website, and cannot therefore be responsible for that material’s
          content, use or effects. By operating the Website, BoxOfficeVR does not represent or imply
          that it endorses the material there posted, or that it believes such material to be
          accurate, useful or non-harmful. The Website may contain content that is offensive,
          indecent, or otherwise objectionable, as well as content containing technical
          inaccuracies, typographical mistakes, and other errors. The Website may also contain
          material that violates the privacy or publicity rights, or infringes the intellectual
          property and other proprietary rights, of third parties, or the downloading, copying or
          use of which is subject to additional terms and conditions, stated or unstated.
          BoxOfficeVR disclaims any responsibility for any harm and/or damages resulting from the
          use or downloading of postings of other parties on the website.
        </p>
      </li>
      <li>
        <h3>Content Posted on Other Websites</h3>
        <p>
          We have not reviewed, and cannot review, all of the material, including computer software,
          made available through the websites and webpages to which BoxOfficeVR.com links, and that
          link to BoxOfficeVR.com. BoxOfficeVR does not have any control over those non-BoxOfficeVR
          websites and webpages, and is not responsible for their contents or their use. By linking
          to a non-BoxOfficeVR website or webpage, BoxOfficeVR does not represent or imply that it
          endorses such website or webpage.
        </p>
      </li>
      <li>
        <h3>Copyright Infringement</h3>
        <p>
          As BoxOfficeVR requires others to respect its intellectual property rights, it respects
          the intellectual property rights of others. If you believe that material located on or
          linked to by the Website violates your copyright, you are encouraged to notify
          BoxOfficeVR. BoxOfficeVR will, as it is able, respond to all such notices, including as
          required or appropriate by removing the infringing material or disabling all links to the
          infringing material. In order to bring infringing material to our attention, you must
          provide the following information: (a) an electronic or physical signature of the person
          authorised to act on behalf of the owner of the copyrighted work; (b) an identification of
          the copyrighted work and the location on the Website of the allegedly infringing work; (c)
          a written statement that you have a good faith belief that the disputed use is not
          authorised by the owner, its agent or the law; (d) your name and contact information,
          including telephone number and email address; and (e) a statement by you that the above
          information in your notice is accurate and, under penalty of perjury, that you are the
          copyright owner or authorised to act on the copyright owner’s behalf.
          <br />
          The contact information of our representative is attn.: Kabuki Drop (BoxOfficeVR) , 101
          Rose Street South Lane, Edinburgh, EH2 3JG email:
          <a href="mailto:hello@BoxOfficeVR.com">hello@BoxOfficeVR.com</a>.
          <br />
          In the case of a user who may infringe or repeatedly infringes upon the copyrights or
          other intellectual property rights of BoxOfficeVR or others, BoxOfficeVR may, in its
          discretion, terminate or deny access to and use of the Website, Content, and/or Services.
          In the case of such termination, BoxOfficeVR will have no obligation to provide a refund
          of any amounts previously paid to BoxOfficeVR to any person in respect of any such
          termination
        </p>
      </li>
      <li>
        <h3>Trademarks</h3>
        <p>
          BoxOfficeVR, the BoxOfficeVR logo, and all other trademarks, service marks, graphics and
          logos used in connection with the Website, Content, and Services, are trademarks or
          registered trademarks of BoxOfficeVR or BoxOfficeVR’s licensors. Other trademarks, service
          marks, graphics and logos used in connection with the Website, Content, and Services, may
          be the trademarks of other third parties in which case such license is for the exclusive
          benefit and use of us unless otherwise stated, or may be the property of their respective
          owners. Your use of the Website grants you no right or license to reproduce or otherwise
          use any BoxOfficeVR or third party trademarks. Likewise, you grant no right or license to
          reproduce or otherwise use any of your trademarks, service marks, graphics and/or logos,
          unless expressly authorised by you.
        </p>
      </li>
      <li>
        <h3>Termination</h3>
        <p>
          You may terminate your agreement and close your account with BoxOfficeVR at any time, , by
          sending an email to <a href="mailto:hello@BoxOfficeVR.com">hello@BoxOfficeVR.com</a> or
          letter to Kabuki Drop, 101 Rose Street South Lane, Edinburgh, EH2 3JG
          <br />
          BoxOfficeVR may terminate its relationship with you, or may terminate or suspend the
          accessibility to the Website, Content, and/or Services at any time, including the use of
          any software, (i) if you breach these Terms and/or any other agreement with BoxOfficeVR;
          (ii) if BoxOfficeVR reasonably suspects that you are using the Website, Content, and/or
          Services to breach the law or infringe third party rights; (iii) if BoxOfficeVR reasonably
          suspects that you are trying to unfairly exploit or misuse BoxOfficeVR’s policies; (iv) if
          BoxOfficeVR reasonably suspects that you are using the Website, Content, and/or Services
          fraudulently, or that Content or Services provided to you are being used by a third party
          fraudulently; (v) if you fail to pay any amounts due to BoxOfficeVR; (vi) you violate any
          applicable law or regulation. Upon termination of your BoxOfficeVR account for the above
          reasons, there will be no refund of fees and you will be denied access to the Website,
          Content and/or the Services, including all of its data.
          <br />
          BoxOfficeVR may terminate any agreement and access to your account, if the Services or any
          part thereof, are no longer legally available in your jurisdiction, or are no longer
          commercially viable, at BoxOfficeVR’s sole discretion. In case of termination or closing
          of your account by you because of a material breach by BoxOfficeVR, without any default by
          you, or in case of a force majeure on the side of BoxOfficeVR, BoxOfficeVR will refund any
          fees or expenses paid by you.
          <br />
          If you believe that BoxOfficeVR has failed to perform or the Services are defective, you
          must notify BoxOfficeVR in writing and allow fourteen (14) days for BoxOfficeVR to cure
          the defect. If BoxOfficeVR cures the defect within this cure period, BoxOfficeVR will not
          be in default and cannot be held liable for any damages and/or losses in connection to
          such default.
        </p>
      </li>
      <li>
        <h3>Changes</h3>
        <p>
          The configurations and specifications of the Website, including without limitation all
          content there available, the Content, and the Services may be amended and/or updated from
          time to time, at the sole discretion of BoxOfficeVR. You are bound by any such changes or
          updates, unless such changes materially diminish the functionality and value of the
          Website, Content and/or Services.
        </p>
      </li>
      <li>
        <h3>Limitation of Warranties of BoxOfficeVR,</h3>
        <p>
          BoxOfficeVR warrants to BoxOfficeVR customers of paid Content and/or services, provided
          that such customers have paid all fees due, and are not otherwise defaulting any
          obligations towards BoxOfficeVR, an availability of the Content and/or Services (“uptime”)
          of ninety-eight percent (98%) per month.
          <br />
          BoxOfficeVR and its licensors make no warranties or representations whatsoever with
          respect to the Website, Content, and Services, or any linked site or its content,
          including the content, information and materials on it or the accuracy, completeness, or
          timeliness of the content, information and materials. We also do not warrant or represent
          that your access to or use of the Website, Content, and/or Services, or any linked site
          will be uninterrupted or free of errors or omissions, that defects will be corrected, or
          that the Website, Content, and/or Services, or any linked site is free of computer viruses
          or other harmful components. We assume no responsibility, and shall not be liable for any
          damages to, or viruses that may
          <br />
          infect, your computer equipment or other property on account of your use of the Content or
          Services, or your access to, use of, or browsing of the Website, or your downloading or
          uploading of any Content from or to the Website. If you are dissatisfied with the Website,
          your sole remedy is to discontinue using the Website.
          <br />
          No advice, results or information, whether oral or written, obtained by you from
          BoxOfficeVR, or through the Website, shall create any warranty not expressly made herein.
          BoxOfficeVR does not necessarily endorse, support, sanction, encourage or agree with any
          content or any user content, or any opinion, recommendation, content, link, data or advice
          expressed or implied therein, and BoxOfficeVR expressly disclaims any and all liability in
          connection with user content and any other content, materials or information available on
          or through the Website, Content, and/or Services, created or provided by users or other
          third parties.
          <br />
          Please note that some jurisdictions may not allow the exclusion of implied warranties, so
          some of the above exclusions may not apply to you. Check your local laws for any
          restrictions or limitations regarding the exclusion of implied warranties.
        </p>
      </li>
      <li>
        <h3>Limitation of Liability of BoxOfficeVR, its Suppliers</h3>
        <p>
          Under no circumstances shall any party, its subsidiaries and affiliates, their respective
          directors, officers, employees or agents, and other representatives, be liable for any
          indirect, consequential, incidental, special, or punitive damages, including but not
          limited to lost profits and business interruption, whether in contract or in tort,
          including negligence, arising in any way from the use of the Website, Content, Services,
          and/or the Contents thereof, or of any hyperlinked website even if such party is expressly
          advised of the possibility of such damages. With the exception of damages related to
          legally proven or admitted intellectual property infringement caused by Content and/or
          Services as delivered by a party without any third party content, in no event shall a
          party’s liability exceed the total sums received by BoxOfficeVR from you during the twelve
          (12) month period immediately prior to the date the damages first occurred.
        </p>
      </li>
      <li>
        <h3>Your Representations and Warranties</h3>
        <p>
          You represent and warrant that your use of the Website, Content, and/or Services will be
          in accordance with any agreement between you and BoxOfficeVR, the BoxOfficeVR Privacy
          Policy these Terms, and with any applicable laws and regulations, including without
          limitation any local laws or regulations in your country, state, city, or other
          governmental area, regarding online conduct and acceptable content, and including all
          applicable laws regarding the transmission of technical data exported from the country in
          which you reside, and with any other applicable policy or terms and conditions.
        </p>
      </li>
      <li>
        <h3>Indemnification</h3>
        <p>
          Subject to the limitations set forth herein, the Parties agree to defend, indemnify, and
          hold each other harmless, including its subsidiaries and affiliates, their respective
          directors, officers, employees or agents, and other representatives, from and against all
          claims, losses, damages, liabilities, and costs (including but not limited to reasonable
          legal fees and court costs), arising out of, relating to or in connection with (i) a
          material violation of these Terms, or any agreement between the Parties, or (ii) any
          allegation that any information or material (including any Content) violates any rights of
          any third party.
          <br />
          You understand and agree that, by using the Content and/or Services, you are solely
          responsible for any data, including personally identifiable information, collected or
          processed via our Content and/or Services. You will defend, indemnify, and hold
          BoxOfficeVR harmless, without any limitation, for all damages in connection to (alleged)
          violations of any privacy laws through the use of the Content and/or Services under your
          account.
        </p>
      </li>
      <li>
        <h3>Miscellaneous</h3>
        <p>
          Each party shall take out adequate insurance in order to cover its risks hereunder,
          including but not limited to a general and product liability insurance. Regarding the
          security, confidentiality and integrity of data, each party is responsible for maintaining
          appropriate technical and organizational measures for the protection of data processed on
          their own systems and on third party systems that are in use by the involved party.
          <br />
          BoxOfficeVR will not be liable for any delay in performing or failure to perform any of
          its obligations to you caused by events beyond its reasonable control. BoxOfficeVR will
          notify you promptly in writing of the reasons for the delay or stoppage (and the likely
          duration) and will take all reasonable steps to overcome the delay or stoppage.
          <br />
          Any cause of action against a party, regardless whether in contract, tort or otherwise,
          must commence within one (1) year after the cause of action accrues. otherwise, such cause
          of action is permanently barred.
          <br />
          If any part of these Terms is held invalid or unenforceable, that part will be construed
          to reflect the Parties’ original intent, and the remaining portions will remain in full
          force and effect. A waiver by either party of any term or condition of these Terms or any
          breach thereof, in any one instance, will not waive such term or condition or any
          subsequent breach thereof. You may only assign your rights under these Terms to any party
          that consents to, and agrees to be bound by, the terms hereof in writing. BoxOfficeVR may
          assign its rights under these Terms at its sole discretion. These Terms will be binding
          upon and will inure to the benefit of the parties, their successors and permitted assigns.
          You agree that no joint venture, partnership, employment, or agency relationship exists
          between you and us as a result of the Terms, or your use of the Website, Content, and/or
          Services.
        </p>
      </li>
      <li>
        <h3>OTHER IMPORTANT TERMS</h3>
        <ul>
          <li>
            Changes to the terms. From time to time, we may make changes to these terms and/or our
            platform.
          </li>
          <li>
            We may transfer this contract to someone else. We may transfer our rights and
            obligations under these to another organisation. We will inform you in writing of any
            changes and when they will take effect at least one whole month in advance.
          </li>
          <li>
            You need our consent to transfer your rights to someone else (except that you can always
            transfer our guarantee). You may only transfer your rights or your obligations under
            these terms to another person if we agree to this in writing.
          </li>
          <li>
            Nobody else has any rights under this contract. This contract is between you and us. No
            other person shall have any rights to enforce any of its terms. Neither of us will need
            to get the agreement of any other person in order to end the contract or make any
            changes to these terms.
          </li>
          <li>
            If a court finds part of this contract illegal, the rest will continue in force. Each
            clause of these terms operates separately. If any court or relevant authority decides
            that any clause is unlawful, the remaining clauses will remain in full force and effect.
          </li>
          <li>
            Even if we delay in enforcing this contract, we can still enforce it later. If we do not
            insist immediately that you do anything you are required to do under these terms, or if
            we delay in taking steps against you in respect of your breaking this contract, that
            will not mean that you do not have to do those things and it will not prevent us taking
            steps against you at a later date.
          </li>
          <li>
            Which laws apply to this contract and where you may bring legal proceedings. These terms
            are governed by Scottish law and you can bring legal proceedings in respect of the
            headset (where applicable) and our services in either the Scottish courts or the courts
            of the country where you normally live.
          </li>
        </ul>
      </li>
    </ol>
    <strong>A Special Note About Children</strong>
    <p>
      The Website is not designed or intended for use by children under the age of 16, and our
      Content and Services may not be purchased by children under the age of 16. We do not
      intentionally gather personal information from visitors who are under the age of 16. If you
      are under the age of 16, you are not permitted to submit any personal information to us. If
      you are under the age of 16, you should use the Website only with consent of a parent or
      guardian
    </p>
  </div>
</template>
<script>
import MetaInfo from "@/mixins/MetaInfo";
import { mapActions } from "vuex";
export default {
  mixins: [MetaInfo],
  data() {
    return {
      title: "Terms and Conditions"
    };
  },
  mounted() {
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: ""
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS"
    })
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/sass/base/variables";
.terms {
  max-width: 1010px;
  margin: 0 auto;
}
h2 {
  color: $gold;
  text-align: center;
}
ol {
  padding-left: 20px;
  & > li {
    margin-bottom: 15px;
  }
  h3 {
    font-family: inherit;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>

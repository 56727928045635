<template>
  <div class="signup-form-container">
    <div class="inner">
      <SignupForm></SignupForm>
    </div>
  </div>
</template>
<script>
import SignupForm from "@/components/membership/SignupForm";
import MetaInfo from "@/mixins/MetaInfo";
import { mapActions } from "vuex";

export default {
  mixins: [MetaInfo],
  components: {
    SignupForm,
  },
  data() {
    return {
      title: "Signup",
      meta: [{ description: "this is meta description" }],
    };
  },
  computed: {},
  mounted() {
    this.setMetaTags({
      pageTitle: this.title,
      metaTitle: "",
      metaDescription: "",
    });
  },
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS",
    }),
  },
};
</script>

<style lang="scss" scoped>
.signup-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
}
</style>

/* eslint-disable prefer-promise-reject-errors */
import AWS from "aws-sdk";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoRefreshToken
} from "amazon-cognito-identity-js";
import AuthTokens from "../models/AuthTokens";

export default class CognitoApiProxy {
  constructor({
    awsRegion = process.env.VUE_APP_COGNITO_AWS_REGION,
    cognitoUserPoolId = process.env.VUE_APP_COGNITO_USER_POOL_ID,
    cognitoIdentityPoolId = process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    cognitoClientId = process.env.VUE_APP_COGNITO_CLIENT_APP_ID
  } = {}) {
    if (!cognitoClientId) throw new Error("Cognito Client App ID is required");
    if (!awsRegion) throw new Error("AWS Cognito Region is required");
    if (!cognitoUserPoolId) throw new Error("Cognito User Pool ID is required");
    if (!cognitoIdentityPoolId) throw new Error("Cognito Identity Pool ID is required");

    this.awsRegion = awsRegion;
    this.cognitoUserPoolId = cognitoUserPoolId;
    this.cognitoClientId = cognitoClientId;
    this.cognitoIdentityPoolId = cognitoIdentityPoolId;

    this.poolInfo = {
      UserPoolId: this.cognitoUserPoolId,
      ClientId: this.cognitoClientId
    };

    this.cognitoAttributeList = [];

    this.attributes = (key, value) => ({
      Name: key,
      Value: value
    });

    AWS.config.region = this.awsRegion;
    this.initAWS();
  }

  checkAndRenewTokenForExpiration() {
    return new Promise((resolve, reject) => {
      let tokens = null;
      let cognitoUser = null;
      let refreshToken = null;

      try {
        tokens = new AuthTokens(JSON.parse(localStorage.getItem("tokens")));

        refreshToken = new CognitoRefreshToken({
          RefreshToken: tokens.refreshToken
        });

        cognitoUser = this.getCognitoUser(tokens.claims.email);
      } catch {
        reject(new Error("401"));
      }

      cognitoUser.refreshSession(refreshToken, (err, session) => {
        console.log(err, session);

        if (err || !session) {
          console.log(err);
          reject(new Error("401"));
        } else {
          const newTokens = this.getTokens(session);
          AWS.config.credentials = this.getCognitoIdentityCredentials(newTokens);

          resolve(new AuthTokens(newTokens));
        }
      });
    });
  }

  getTokens(session) {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
      idTokenExpiry: session.getIdToken().decodePayload().exp,
      claims: session.getIdToken().decodePayload()
    };
  }

  getCognitoIdentityCredentials(tokens) {
    const loginInfo = {};
    loginInfo[`cognito-idp.${this.awsRegion}.amazonaws.com/${this.cognitoUserPoolId}`] =
      tokens.idToken;
    const params = {
      IdentityPoolId: this.cognitoIdentityPoolId,
      Logins: loginInfo
    };
    return new AWS.CognitoIdentityCredentials(params);
  }

  setCognitoAttributeList(email, attrs) {
    // eslint-disable-next-line prefer-const
    let attributeList = [];
    //attributeList.push(this.attributes("email", email));
    attrs.map(attr =>
      attributeList.push(this.attributes(Object.keys(attr)[0], Object.values(attr)[0]))
    );

    attributeList.map(attr => this.cognitoAttributeList.push(new CognitoUserAttribute(attr)));
  }

  getUserPool() {
    return new CognitoUserPool(this.poolInfo);
  }

  getCognitoUser(email) {
    const userData = {
      Username: email,
      Pool: this.getUserPool()
    };
    return new CognitoUser(userData);
  }

  getCognitoUserUsingId(userId) {
    const userData = {
      Username: userId,
      Pool: this.getUserPool()
    };
    return new CognitoUser(userData);
  }

  getAuthDetails(email, password) {
    const authenticationData = {
      Username: email,
      Password: password
    };

    return new AuthenticationDetails(authenticationData);
  }

  initAWS() {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: this.cognitoIdentityPoolId
    });
  }

  // function decodeJWTToken(token) {
  //   const {  email, exp, authTime , tokenUse, sub} = jwtDecode(token.idToken);
  //   return {  token, email, exp, uid: sub, authTime, tokenUse };
  // }

  signUp(userName, password, attributes) {
    return new Promise((resolve, reject) => {
      this.setCognitoAttributeList(userName, attributes);

      this.getUserPool().signUp(
        userName,
        password,
        this.cognitoAttributeList,
        null,
        (err, result) => {
          if (err) {
            reject({ statusCode: 422, response: err });
          }
          if (result) {
            const response = {
              username: result.user.username,
              userConfirmed: result.userConfirmed,
              userAgent: result.user.client.userAgent,
              cognitoUserId: result.userSub
            };

            resolve({ statusCode: 201, response });
          }
        }
      );
    });
  }

  verify(email, code) {
    return new Promise((resolve, reject) => {
      this.getCognitoUser(email).confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject({ statusCode: 422, response: err });
        }
        resolve({ statusCode: 201, response: result });
      });
    });
  }

  signIn(userName, password) {
    return new Promise((resolve, reject) => {
      const authDetail = this.getAuthDetails(userName, password);

      this.getCognitoUser(userName).authenticateUser(authDetail, {
        onSuccess: result => {
          const response = {
            accessToken: result.getAccessToken().getJwtToken(),
            idToken: result.getIdToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
            idTokenExpiry: result.getIdToken().getExpiration(),
            claims: result.getIdToken().payload
          };

          const logins = {};
          logins[
            `cognito-idp.${this.awsRegion}.amazonaws.com/${this.cognitoUserPoolId}`
          ] = result.getIdToken().getJwtToken();

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: this.cognitoIdentityPoolId,
            Logins: logins
          });

          AWS.config.credentials.refresh(error => {
            if (error) {
              console.error(error);
            } else {
              console.log("Successfully logged!");
            }
          });

          // this.getCognitoUser(userName).setDeviceStatusRemembered({
          //   onSuccess: function(result) {
          //     console.log(result);
          //   },

          //   onFailure: function(err) {
          //     console.log(err);
          //   },
          // });

          resolve({ statusCode: 200, data: response });
        },
        onFailure: err => reject(err)
      });
    });
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      this.getCognitoUser(email).forgotPassword({
        onSuccess: result => {
          console.log("cognito api forgot pwd", result);
          if (!result) {
            reject(result);
          }
          resolve(result);
        },
        onFailure: err => reject(err)
      });
    });
  }

  confirmPassword(email, verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
      this.getCognitoUser(email).confirmPassword(verificationCode, newPassword, {
        //If success just return resolve because aws doesn't provide anything in response
        onSuccess: () => resolve(),
        onFailure: err => reject(err)
      });
    });
  }
  signOut(email) {
    return new Promise((resolve, reject) => {
      try {
        this.getCognitoUser(email).signOut();
        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      try {
        const user = this.getUserPool().getCurrentUser();
        resolve(user);
      } catch (error) {
        reject(error);
      }
    });
  }
  isAuthenticated(cb) {
    let cognitoUser = this.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          return cb(err, false);
        }
        return cb(session, true);
      });
    } else {
      cb(null, false);
    }
  }
  changePassword(userId, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      let user = this.getCognitoUserUsingId(userId);
      if (!user) reject("User not found");
      user.getSession((error, session) => {
        if (error) console.log("Error when get session", error);
        if (session) {
          user.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
              console.log("error change password", err);
              reject({ statusCode: 422, response: err });
            }
            if (result) {
              console.log("success change password", result);
              resolve({ statusCode: 201, result });
            }
          });
        }
      });
    });
  }
  updateUserAttribute(email, userId, userAttributes) {
    return new Promise((resolve, reject) => {
      let user = this.getCognitoUserUsingId(userId);
      if (!user) reject("User not found");
      this.setCognitoAttributeList(email, userAttributes);

      user.getSession((error, session) => {
        if (error) console.log("Error when get session", error);
        if (session) {
          user.updateAttributes(this.cognitoAttributeList, (err, result) => {
            if (err) {
              console.log("cognito error update", err);
              reject({ statusCode: 422, response: err });
            }
            if (result) {
              console.log("cognito success update", result);
              resolve({ statusCode: 201, result });
            }
          });
        }
      });
    });
  }
  deleteUser(userId, userName, password) {
    return new Promise((resolve, reject) => {
      const authDetails = this.getAuthDetails(userName, password);
      let user = this.getCognitoUser(userName);
      user.authenticateUser(authDetails, {
        onSuccess: result => {
          console.log(result);
          if (result.getIdToken().getJwtToken()) {
            user.deleteUser((error, result) => {
              if (error) {
                console.log("Cognito error delete user", error);
                reject({ response: error });
              }
              if (result) {
                console.log("Cognito success delete user", result);
                resolve({ statusCode: 200, result });
              }
            });
          }
        },
        onFailure: error => {
          console.log("error authenticating user", error);
          reject(error);
        }
      });
    });
  }
}

<template>
  <div class="unity-controlbar">
    <div class="inner">
      <vue-slider
        ref="timescrubber"
        v-model="timeScrubber"
        class="time-scrubber"
        :min="0"
        :max="videoLength"
        :interval="0.1"
        :lazy="true"
        :tooltip-formatter="secondToHMS"
      ></vue-slider>
      <button class="btn bg-transparent" :disabled="isPlay" @click="play">
        <i class="fa fa-play"></i>
      </button>
      <button class="btn bg-transparent" :disabled="!isPlay" @click="pause">
        <i class="fa fa-pause"></i>
      </button>
      <div class="time-container d-inline-block">
        <span class="gold">{{ timeCurrent }}</span>
        <span> / {{ totalTime }}</span>
      </div>
      <div class="video-title">{{ title }}</div>
      <div class="d-flex pull-right">
        <div class="quality-selector" v-on-clickaway="hideQuality" v-if="!isTrailer">
          <span class="selected" @click="toggleQuality">
            {{ quality }}
          </span>
          <div class="quality-options" v-if="showQuality">
            <div
              v-for="(qualityOption, i) in qualities"
              :key="i"
              :class="qualityOption == quality.toUpperCase() ? `active` : ``"
              @click="setQuality(qualityOption)"
            >
              {{ qualityOption }}
            </div>
          </div>
        </div>
        <div class="volume-container">
          <vue-slider
            v-model="volumeLeveller"
            class="volume-level"
            direction="btt"
            :min="0"
            :max="1"
            :interval="0.01"
            :tooltip-formatter="floatToPercent"
            v-if="showVolume"
            v-on-clickaway="toggleVolume"
          ></vue-slider>
          <button class="btn bg-transparent" @click="toggleVolume">
            <i class="fas fa-volume-up"></i>
          </button>
        </div>
        <button class="btn bg-transparent" @click="vr" :disabled="!isVRSupported">
          <i class="fas fa-vr-cardboard"></i>
        </button>
        <button class="btn bg-transparent" @click="showHelpBox">
          <i class="bovr-ico-support" />
        </button>
        <div v-if="showHelp" class="player-help-popup pull-right">
          <a @click="openVideoPlayerHelp">Video Player Help</a>
          <a @click="openAboutBOVR">About Box Office VR</a>
        </div>
        <button class="btn bg-transparent" @click="fullscreen">
          <i class="fas fa-expand"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import moment from "moment";
import { mixin as clickaway } from "vue-clickaway";
import "vue-slider-component/theme/default.css";
import { mapState } from "vuex";

export default {
  mixins: [clickaway],
  name: "unityControlbar",
  props: {
    videoMetadata: {
      type: Object,
      default: () => {}
    },
    videoLength: {
      type: Number, // seconds
      default: 10
    },
    isTrailer: {
      type: Boolean,
      default: false
    },
    isPlay: {
      type: Boolean,
      default: false
    },
    videoCurrentPlay: {
      type: Number,
      default: 0
    },
    volumeLevel: {
      type: Number,
      default: 0
    },
    isVRSupported: {
      type: Boolean,
      default: false
    },
    quality: {
      type: String,
      default: "MED"
    }
  },
  components: {
    VueSlider
  },
  data() {
    return {
      showVolume: false,
      jsonObj: {},
      qualities: ["HIGH", "MED", "LOW"],
      showQuality: false,
      secondToHMS: v => moment.utc(v * 1000).format("HH:mm:ss"),
      floatToPercent: v => `${parseInt(v * 100)}%`,
      showHelp: false
    };
  },
  computed: {
    ...mapState({
      mainVideoJson: state => state.showDetails.mainVideoJson
    }),
    timeScrubber: {
      get() {
        return this.videoCurrentPlay;
      },
      set(val) {
        this.$emit("onPlayAt", val);
      }
    },
    volumeLeveller: {
      get() {
        return this.volumeLevel.toFixed(2);
      },
      set(val) {
        this.$emit("onSetVolume", val);
      }
    },
    timeCurrent() {
      return moment.utc(this.videoCurrentPlay * 1000).format("HH:mm:ss");
    },
    totalTime() {
      return moment.utc(this.videoLength * 1000).format("HH:mm:ss");
    },
    title() {
      return this.mainVideoJson && this.mainVideoJson.Title ? this.mainVideoJson.Title : "";
    }
  },
  methods: {
    play() {
      this.$emit("onPlay");
    },
    pause() {
      this.$emit("onPause");
    },
    vr() {
      this.$emit("onVR");
    },
    toggleVolume() {
      this.showVolume = !this.showVolume;
    },
    setVolume() {
      this.$emit("onSetVolume", this.volumeLevel);
    },
    hideQuality() {
      this.showQuality = false;
    },
    toggleQuality() {
      this.showQuality = !this.showQuality;
    },
    setQuality(quality = "MED") {
      this.$emit("onSetQuality", quality);
      this.toggleQuality();
    },
    fullscreen() {
      this.$emit("onFullscreen");
    },
    showHelpBox() {
      this.showHelp = !this.showHelp;
    },
    openVideoPlayerHelp() {
      const routeData = this.$router.resolve({ name: "faq" });
      window.open(routeData.href, "_blank");
    },
    openAboutBOVR() {
      const routeData = this.$router.resolve({ name: "about" });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.unity-controlbar {
  // background: rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, #000000 100%);
  width: 100%;
  color: white;
  .inner {
    padding: 10px 20px 30px;
    @include breakpoint("medium") {
      padding: 40px 70px 20px;
    }
  }
  .video-title {
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    max-width: 70%;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .btn {
    color: white;
    &:hover {
      color: $gold;
    }

    &:disabled {
      opacity: 0.2;
      &:hover {
        color: inherit;
      }
    }

    .bovr-ico-support {
      font-size: 22px;
    }
  }
}
.time-scrubber {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.vue-slider-process {
  background: $gold;
}

.vue-slider-dot {
  border: 5px solid $gold;
  border-radius: 50%;
}

.time-container {
  display: none;
  margin-right: 10px;
  vertical-align: middle;
  @include breakpoint("large") {
    display: inline-block;
  }
}
.volume-container {
  position: relative;
  .volume-level {
    height: 100px !important;
    position: absolute;
    bottom: 35px;
    left: 7px;

    .vue-slider-rail {
      width: 11px;
      background-color: #e9e9e9;
      border-radius: 15px;
      border: 3px solid;
    }
  }
}
.quality-selector {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 38px;
  position: relative;

  span.selected {
    cursor: pointer;
    display: inline-block;
    margin: 0 10px;
  }

  .quality-options {
    position: absolute;
    bottom: -5px;
    padding: 5px 20px;
    background: black;
    text-align: center;
    z-index: 1;
    opacity: 0.9;
    left: -12px;
    & > div {
      cursor: pointer;
    }
    .active {
      color: $gold;
    }
  }
}
.player-help-popup {
  position: absolute;
  background: #000000;
  color: #ffffff;
  padding: 15px;
  border: 2px $gold solid;
  bottom: 110px;
  right: 30px;

  @include breakpoint("large") {
    right: 80px;
  }

  @include breakpoint("medium") {
    bottom: 100px;
    right: 80px;
  }

  @include breakpoint("extrasmall") {
    right: 110px;
  }

  a {
    display: block;
    &:hover {
      cursor: pointer;
    }
  }

  a:nth-child(2) {
    margin-top: 5px;
  }
}
</style>

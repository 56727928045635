<template>
  <div
    class="hero-image"
    :class="trailer != '' ? `trailer-item` : ``"
    @click="() => trailer != `` && playTrailer()"
  >
    <img :src="resolveCdnPath(item[0].value)" alt="" v-if="item.length" />
  </div>
</template>

<script>
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

export default {
  props: {
    item: {
      type: Array,
      default: () => []
    },
    trailer: {
      type: String,
      default: () => ""
    }
  },
  methods: {
    getImgUrl(id) {
      var images = require.context("@/assets/images/mock/", false, /\.png$/);
      return images("./" + id + ".png");
    },
    playTrailer() {
      this.$emit("onPlayTrailer");
    },
    resolveCdnPath(path) {
      return urlHelper.resolveCdnPath(path);
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-container",class:_vm.fromSignupPage ? 'bg-signup' : ''},[_c('div',{staticClass:"panel-switch-signup"},[(_vm.fromSignupPage)?_c('h1',{staticClass:"mb-4 font-weight-bold"},[_vm._v("Sign Up")]):_c('div',{staticClass:"text-left info-title font-weight-bold"},[_c('span',[_vm._v("Sign Up")])]),_c('div',[_c('validation-observer',{ref:"signupFrm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''},[_c('label',{attrs:{"for":"input-firstName"}},[_vm._v("First Name*")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"text","name":"input-firstName","placeholder":"First name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''},[_c('label',{attrs:{"for":"input-lastName"}},[_vm._v("Last Name*")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"text","name":"input-lastName","placeholder":"Last name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)])]),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''},[_c('label',{attrs:{"for":"input-email"}},[_vm._v("Email address*")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"email","name":"input-email","aria-describedby":"emailHelp","placeholder":"Email address"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|minPassword:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:errors[0] || (invalid && dirty & changed) ? 'md-invalid' : '',attrs:{"md-toggle-password":false}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v("Password*")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"password","name":"input-password","aria-describedby":"emailHelp","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('MdButton',{staticClass:"n8-btn gold-theme n8-btn-black md-raised mt-4",class:invalid || _vm.loading ? "disabled" : "",attrs:{"disabled":_vm.loading},on:{"click":_vm.signup}},[_c('span',{staticClass:"btn-label"},[_vm._v(_vm._s(_vm.loading ? "loading..." : "Sign Up"))])])]}}])}),(_vm.errorMsg)?_c('span',{staticClass:"md-error error"},[_vm._v(_vm._s(_vm.errorMsg))]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store"

// Global Layout
import DefaultLayout from "@/layouts/DefaultLayout";
import BlankLayout from "@/layouts/BlankLayout";
import EmptyLayout from "@/layouts/EmptyLayout";

import Home from "@/pages/homepage";
import venuesListingPage from "@/pages/venues/ListingPage";
import faqPage from "@/pages/faq";
import aboutPage from "@/pages/about";
import privacyPolicyPage from "@/pages/privacypolicy";
import cookiePolicyPage from "@/pages/cookiepolicy";
import termsConditionsPage from "@/pages/termsconditions";
import searchPage from "@/pages/search";
import maintenancePage from "@/pages/maintenance";
import venuesDetailPage from "@/pages/venues/detail";
import showDetails from "@/pages/showdetails";
import watch from "@/pages/watch";
// import VideoContainerPage from "@/components/videoplayer/VideoContainerPage";
import myOrderPage from "@/pages/myorder";
import comingSoon from "@/pages/comingsoon";
import emailInquiry from "@/pages/emailinquiry";
import notFound from "@/pages/error/notfound";
import confirmRegistration from "@/pages/auth/confirmregistration";
import login from "@/pages/auth/login";
import forgotPassword from "@/pages/auth/forgotpassword";
import confirmPassword from "@/pages/auth/confirmpassword";
import signup from "@/pages/auth/signup";
import myDetails from "@/pages/mydetails";
import changePassword from "@/pages/auth/changepassword";

Vue.use(VueRouter);

// Might need to use it later in future
// const isUserLoggedIn = async () => await store.getters["auth/isLoggedIn"];

const routes = [
  {
    path: "",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "homepage",
        component: Home,
        meta: {
          pageType: "landingPage",
          reload: false
        }
      },

      {
        path: "/venues",
        name: "venuesListing",
        component: venuesListingPage,
        props: true,
        meta: {
          pageType: "landingPage"
        }
      },
      {
        path: "/venues/:id",
        name: "venuesDetail",
        component: venuesDetailPage,
        props: true,
        meta: {
          pageType: "detailPage",
          parentPath: "/venues"
        }
      },
      {
        path: "/faq",
        name: "faq",
        component: faqPage,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/about",
        name: "about",
        component: aboutPage,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: privacyPolicyPage,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/cookie-policy",
        name: "cookie-policy",
        component: cookiePolicyPage,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/terms-conditions",
        name: "terms-conditions",
        component: termsConditionsPage,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/search",
        name: "search",
        component: searchPage,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/shows/:id",
        name: "showdetails",
        component: showDetails,
        props: true,
        meta: {
          pageType: "detailPage",
          cssClass: "shows-detail-page",
          parentPath: "/shows",
          reload: false
        }
      },
      {
        path: "/myorder",
        name: "myorder",
        component: myOrderPage,
        props: true,
        meta: {
          pageType: "standardPage",
          parentPath: "/myaccount"
        }
      },
      {
        path: "/emailenquiry",
        name: "emailEnquiry",
        component: emailInquiry,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/confirm-registration",
        name: "confirmRegistration",
        component: confirmRegistration,
        props: true,
        meta: {
          pageType: "standardPage",
          cssClass: "bg-black"
        }
      },
      {
        path: "/confirm-password",
        name: "confirmPassword",
        component: confirmPassword,
        props: true
      },
      {
        path: "/mydetails",
        name: "myDetails",
        component: myDetails,
        props: true
      }
    ]
  },
  {
    path: "",
    component: BlankLayout,
    children: [
      {
        path: "/comingsoon",
        name: "comingSoon",
        component: comingSoon,
        props: true,
        meta: {
          pageType: "standardPage"
        }
      },
      {
        path: "/maintenance",
        name: "maintenance",
        component: maintenancePage,
        props: true
      },
      {
        path: "/login",
        name: "login",
        component: login,
        props: true,
        meta: {
          cssClass: "bg-black"
        }
      },
      {
        path: "/signup",
        name: "signup",
        component: signup,
        props: true,
        meta: {
          cssClass: "bg-black"
        }
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        component: forgotPassword,
        props: true,
        meta: {
          cssClass: "bg-black"
        }
      },
      {
        path: "/change-password",
        name: "changePassword",
        component: changePassword,
        props: true,
        meta: {
          cssClass: "bg-black"
        }
      },
      {
        path: "/not-found",
        name: "notFound",
        component: notFound,
        props: true
      }
    ]
  },
  {
    path: "",
    component: EmptyLayout,
    // beforeEnter(to, from, next) {
    //   if (isUserLoggedIn()) {
    //     next();
    //   } else {
    //     next({ name: "login" });
    //   }
    // },
    children: [
      {
        path: "/watch/:id",
        name: "watch",
        component: watch,
        props: true,
        meta: {
          pageType: "standardPage",
          cssClass: "watch-page",
          reload: false
        }
      },
      // {
      //   path: "/shows/video/:id",
      //   name: "videopage",
      //   component: VideoContainerPage,
      //   props: true
      // }
    ]
  }
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;

<template>
  <div class="maintenance-container text-center">
    <img src="@/assets/images/maintenance.png" alt="We’ll be back shortly!" />
    <h1>
      Sorry, we're currently undergoing scheduled maintenance.
      <br />
      Be back soon!
    </h1>
  </div>
</template>

<script>
export default {};
</script>

<template>
  <md-dialog :md-active="isShow" class="vr-payment-dialog">
    <md-dialog-actions class="dialog-action">
      <span class="icon-close fa fa-times" @click="closeDialog()"></span>
    </md-dialog-actions>
    <md-dialog-content class="content text-center">
      <div class="inner">
        <h3 class="text-gold mb-3">Sorry, purchasing a show in VR mode is not available.</h3>
        <p>
          Please go to Box Office VR on your desktop or mobile web browser to make payments, then
          you can enjoy shows on this device.
        </p>
      </div>
    </md-dialog-content>
  </md-dialog>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    closeDialog() {
      this.$emit("onCloseModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.vr-payment-dialog {
  .inner {
    padding: 80px 40px;
    color: white;
  }
  h3 {
    font-family: "Opensans-regular", Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 22px;
  }
}
.dialog-action {
  .icon-close {
    color: white;
    padding: 15px;
    font-size: 24px;
  }
}
</style>

<template>
  <div class="forgot-password-container">
    <div class="col-12 text-left">
      <h1 class="font-22 text-gold font-weight-bold mb-4">Forgot your password?</h1>
      <p class="sub-heading">
        Enter your email address and we'll send you a link to reset your password.
      </p>
      <validation-observer ref="forgotPasswordForm" #default="{ invalid }">
        <div class="form-group">
          <ValidationProvider
            name="Email address"
            rules="required|email"
            v-slot="{ errors, invalid, dirty, changed }"
          >
            <md-field :class="errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''">
              <label for="input-email">Email address *</label>
              <md-input
                type="email"
                class="form-control n8-text"
                id="input-email"
                aria-describedby="emailHelp"
                placeholder="Email address"
                v-model="email"
                v-on:keyup.enter="submit"
              />
              <span class="md-error">{{ errors[0] }}</span>
            </md-field>
          </ValidationProvider>
        </div>
        <MdButton
          class="n8-btn n8-btn-gray light-theme md-raised mt-5"
          :class="invalid ? `disabled` : ``"
          @click="submit"
        >
          <span class="btn-label">Reset password</span>
        </MdButton>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: null,
      message: null,
      notificationType: null,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {},
  methods: {
    async submit() {
      try {
        const success = await this.$refs.forgotPasswordForm.validate();
        if (success) {
          const response = await this.$store.dispatch("auth/forgotPassword", this.email);
          console.log("forgot password", response);
          this.$store.dispatch("general/SET_NOTIFICATION", {
            showNotif: true,
            type: "success",
            message: "Success. Please check your email.",
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 3000);
        }
      } catch (error) {
        console.log("Error when forgot password", error.message);
        this.$store.dispatch("general/SET_NOTIFICATION", {
          showNotif: true,
          type: "error",
          message: error.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Opensans-bold";
}
.sub-heading {
  font-family: "Opensans-regular";
  font-size: 14px;
  margin-bottom: 30px;
}
</style>

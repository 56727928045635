<template>
  <div class="payment-container" :class="postPaymentSuccess ? `success` : ``">
    <div class="panel-switch-payment">
      <div class="row">
        <div class="col-lg-6 col-md-6 info-show-column">
          <div>
            <div class="img-container">
              <img class="img-show" :src="resolveCdnPath(hlItem.artworks.featured_sm)" />
            </div>
            <div class="info">
              <template v-if="!postPaymentSuccess && !postPaymentFailed">
                <div class="show-title mb-2">{{ hlItem.title }}</div>
                <div class="price mb-2">
                  {{ currency }} {{ includeTax(hlItem.price) | priceFormat }}
                </div>
              </template>

              <div class="expiry-info">
                Expiry:<i>Valid for {{ watchingLifetimeInHours }} hours after viewing starts</i>
              </div>
              <div class="cta-buttons" v-if="postPaymentSuccess" style="padding: 25px 50px;">
                <MdButton class="md-button n8-btn md-raised md-theme-default" @click="watchMovie">
                  <span class="btn-label icon">Watch Now</span>
                </MdButton>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 info-payment-column">
          <div class="info-payment payment" v-if="!postPaymentSuccess && !postPaymentFailed">
            <h3 class="mb-4">Card Payment Details</h3>

            <div class="cell payinfo" id="example-2">
              <form>
                <div data-locale-reversible>
                  <div class="n8-row">
                    <div
                      class="field"
                      :class="errorMessagePayeeName && !payeeName ? 'not-valid' : ''"
                    >
                      <input
                        v-model="payeeName"
                        id="payinfo-full-name"
                        class="input empty"
                        type="text"
                        placeholder="Full name"
                        required=""
                        autocomplete="Full name"
                      />
                      <label for="payinfo-full-name">Name on card</label>
                      <div class="baseline"></div>
                    </div>
                    <span v-if="errorMessagePayeeName && !payeeName" class="error-msg">{{
                      errorMessagePayeeName
                    }}</span>
                  </div>
                </div>
                <div class="n8-row">
                  <div class="field">
                    <div ref="cNumber" id="payinfo-card-number" class="input empty"></div>
                    <label for="payinfo-card-number">Card number</label>
                    <div class="baseline"></div>
                  </div>
                  <span v-if="errorMessageCardNumber" class="error-msg">{{
                    errorMessageCardNumber
                  }}</span>
                </div>
                <div class="n8-row">
                  <div
                    class="field half-width"
                    :class="errorMessagePayeeName && !payeeName ? 'not-valid' : ''"
                  >
                    <div id="payinfo-card-expiry" class="input empty"></div>
                    <label for="payinfo-card-expiry">Expiry</label>
                    <div class="baseline"></div>
                  </div>
                  <div class="field half-width">
                    <div id="payinfo-card-cvc" class="input empty"></div>
                    <label for="payinfo-card-cvc">Cvc</label>
                    <div class="baseline"></div>
                  </div>
                  <div>
                    <span v-if="errorMessageExpiry" class="error-msg">{{
                      errorMessageExpiry
                    }}</span>
                  </div>
                  <div>
                    <span v-if="errorMessageCVC" class="error-msg">{{ errorMessageCVC }}</span>
                  </div>
                </div>
              </form>
            </div>
            <div class="n8-row info price-info mt-4 mb-4">
              <div class="field half-width">Order total:</div>
              <div class="field half-width">
                {{ currency }} {{ includeTax(hlItem.price) | priceFormat }}
              </div>
            </div>
            <div hidden class="info price-info">
              Order total: {{ currency }} {{ includeTax(hlItem.price) | priceFormat }}
            </div>
            <div class="expiry-info">
              Expiry:<i>Valid for {{ watchingLifetimeInHours }} hours after viewing starts</i>
            </div>
            <!--:class="!numberValid || !cvvValid || !expiryValid ? `grey disabled` : ``" :disabled="!numberValid || !cvvValid || !expiryValid"-->
            <MdButton class="n8-btn n8-btn-gold md-raised mt-5 mx-0" @click="purchase()">
              <span>Pay Now</span>
            </MdButton>
            <div class="process-screen" v-if="onProcess">
              <small-loader></small-loader>
            </div>
          </div>
          <div class="post-payment success" v-if="postPaymentSuccess && !postPaymentFailed">
            <div class="content">
              <div class="event-sign">
                <div class="event-image m-2"></div>
                <div class="event-text m-3">
                  <span class="title">Payment Complete</span>
                  <span class="note">
                    Thank you for your order! A confirmation email has been sent to your email.
                  </span>
                </div>
              </div>
              <div class="event-detail">
                <div class="sections">
                  <span class="label" :class="isFromDetailPage ? `d-block` : ``"
                    >Order Number:</span
                  >
                  <span class="value">{{ `${successData.orderItem.id.slice(-8)}` }}</span>
                </div>
                <div class="sections">
                  <span class="label" :class="isFromDetailPage ? `d-block` : ``">Order Date:</span>
                  <span class="value">{{ formatDate(successData.orderItem.createdDateTime) }}</span>
                </div>
                <div class="sections">
                  <span class="label" :class="isFromDetailPage ? `d-block` : ``">Expiry:</span>
                  <span class="value"
                    >Valid for
                    {{ successData.orderItem.meta.showExpiration.timeToWatchAfterPlayed }}
                    hours after first click</span
                  >
                </div>
                <div class="sections">
                  <span class="label" :class="isFromDetailPage ? `d-block` : ``">Venue:</span>
                  <span class="value">{{ successData.orderItem.meta.venue.name }} </span>
                </div>
                <div class="sections">
                  <span class="label" :class="isFromDetailPage ? `d-block` : ``">Order Total:</span>
                  <span class="value">{{
                    formatPrice(
                      successData.orderItem.meta.payment.currency,
                      successData.orderItem.meta.payment.totalPrice
                    )
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <div v-if="postPaymentFailed" class="post-payment failed">
            <div class="content">
              <div class="event-sign">
                <i class="fa fa-times-circle"></i>
                <div class="event-text">
                  <span class="title">Payment Failed</span>
                  <span class="note">
                    <i class="fas fa-info-circle" v-if="message"></i> {{ message }}
                  </span>
                </div>
              </div>
            </div>
            <div class="cta-buttons">
              <MdButton class="md-button n8-btn md-raised md-theme-default" @click="closePayment()">
                <i class="icon-n8 icon-back" /><span>Back</span>
              </MdButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "@/axios-instance";
import getSymbolFromCurrency from "currency-symbol-map";
import smallLoader from "@/components/SmallLoader.vue";
import moment from "moment";
import UrlHelper from "../../helper/url-helper";

const urlHelper = new UrlHelper();

let stripeStyle = {
  base: {
    color: "#ffffff",
    fontWeight: 500,
    fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
    fontSize: "16px",
    fontSmoothing: "antialiased",

    "::placeholder": {
      color: "#8f8f8f",
      fontStyle: "italic"
    },
    ":-webkit-autofill": {
      color: "#5e5e5e"
    }
  },
  invalid: {
    color: "#ff6161",

    "::placeholder": {
      color: "#ff6161"
    }
  }
};

let elementClasses = {
  focus: "focused",
  empty: "empty",
  invalid: "invalid"
};

let stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_PUBLIC_KEY}`);
let elements = stripe.elements();
let cardNumber = elements.create("cardNumber", {
  style: stripeStyle,
  classes: elementClasses
});
let cardExpiry = elements.create("cardExpiry", {
  style: stripeStyle,
  classes: elementClasses
});
let cardCvc = elements.create("cardCvc", {
  style: stripeStyle,
  classes: elementClasses
});

export default {
  name: "PaymentForm",
  data() {
    return {
      message: "",
      payeeName: "",
      numberValid: false,
      cvvValid: false,
      expiryValid: false,
      postPaymentSuccess: false,
      postPaymentFailed: false,
      onProcess: false,
      successData: {},
      errorMessageCardNumber: "",
      errorMessageCVC: "",
      errorMessageExpiry: "",
      expiryTimestamp: Date.now(),
      showExpiration: Date.now(),
      errorMessagePayeeName: "",
      isEligible: false,
      playOnClose: false
    };
  },
  mounted() {
    this.clearData();

    // console.log("horizontaColapseData", this.hlItem);
    // console.log("is isLoggedIn", this["auth/isLoggedIn"]);
    // console.log("is getUser", this["auth/getUser"]);
    // console.log("is Details", this["auth/getUserDetails"]);
    // console.log("Just buy videos", this["homepage/getJustBuyVideo"]);

    cardNumber.unmount();
    cardExpiry.unmount();
    cardCvc.unmount();
    cardNumber.mount("#payinfo-card-number");
    cardExpiry.mount("#payinfo-card-expiry");
    cardCvc.mount("#payinfo-card-cvc");

    var self = this;
    cardNumber.on("change", function(event) {
      if (event.error) {
        self.numberValid = false;
        self.errorMessageCardNumber = event.error.message;
      } else {
        self.numberValid = true;
        self.errorMessageCardNumber = "";
      }
    });

    cardExpiry.on("change", function(event) {
      if (event.error) {
        self.cvvValid = false;
        self.errorMessageExpiry = event.error.message;
      } else {
        self.cvvValid = true;
        self.errorMessageExpiry = "";
      }
    });

    cardCvc.on("change", function(event) {
      if (event.error) {
        self.expiryValid = false;
        self.errorMessageCVC = event.error.message;
      } else {
        self.expiryValid = true;
        self.errorMessageCVC = "";
      }
    });

    var inputs = document.querySelectorAll(".cell.payinfo .input");
    Array.prototype.forEach.call(inputs, function(input) {
      input.addEventListener("focus", function() {
        input.classList.add("focused");
      });
      input.addEventListener("blur", function() {
        input.classList.remove("focused");
      });
      input.addEventListener("keyup", function() {
        if (input && input.value && input.value.length === 0) {
          input.classList.add("empty");
        } else {
          input.classList.remove("empty");
        }
      });
    });

    // // check if user already bought
    // const justBuyItem = this["homepage/getJustBuyVideo"].filter(
    //   (x) =>
    //     x.orderItem.productId === this.hlItem.id &&
    //     x.orderItem.venueId === this.hlItem.venue.id
    // );

    // console.log("justBuyItem", justBuyItem);

    // if (justBuyItem && justBuyItem.length > 0) {
    //   this.successData = justBuyItem[0];
    //   this.postPaymentSuccess = true;
    //   this.postPaymentFailed = false;
    // }
  },
  methods: {
    purchase() {
      if (!this["auth/isLoggedIn"]) {
        throw new Error("401");
      }
      const emptyFieldMessage = "This field is required";
      if (!this.numberValid || !this.cvvValid || !this.expiryValid) {
        this.errorMessageExpiry = emptyFieldMessage;
        this.errorMessageCardNumber = emptyFieldMessage;
        this.errorMessageCVC = emptyFieldMessage;
        this.errorMessagePayeeName = emptyFieldMessage;
        return;
      } else this.clearCardData();

      const firstName = this.payeeName
        .split(" ")
        .slice(0, -1)
        .join(" ");
      const lastName = this.payeeName
        .split(" ")
        .slice(-1)
        .join(" ");
      this.postPaymentSuccess = false;
      this.postPaymentFailed = false;

      console.log("is isLoggedIn", this["auth/isLoggedIn"]);
      console.log("is getUser", this["auth/getUser"]);
      console.log("is Details", this["auth/getUserDetails"]);

      this.message = "";
      this.onProcess = true;
      var purchase = {
        data: {
          customer: {
            id: this["auth/getUser"].sub,
            meta: {
              email: this["auth/getUser"].email,
              firstName: firstName !== "" ? firstName : this.payeeName,
              lastName: lastName
            }
          },
          product: {
            id: this.hlItem.id,
            key: "VIDEO",
            meta: this.hlItem
          },
          venue: {
            id: this.hlItem.venue.id,
            meta: this.hlItem.venue
          },
          payment: {
            key: "CREDIT_CARD",
            meta: {
              basePrice: this.hlItem.price,
              totalPrice: this.includeTax(this.hlItem.price) //price + tax price
            }
          }
        }
      };

      // console.log("paymentFullname", this.payeeName, process.env.VUE_APP_STRIPE_PUBLIC_KEY);

      if (this.payeeName && this.payeeName.length < 1) {
        return;
      }

      if (!this.numberValid || !this.expiryValid || !this.cvvValid) {
        return;
      }

      this.message = "Loading...";
      const orderServiceReqHeader = {
        "Content-Type": "application/json",
        "x-api-key": process.env.VUE_APP_ORDER_API_KEY
      };
      // console.log("purchase", purchase);
      var self = this;
      axios
        .post("/order/checkout/", purchase, { headers: orderServiceReqHeader })
        .then(function(tokenResult) {
          // console.log("tokenResult", tokenResult);

          stripe
            .confirmCardPayment(tokenResult.data.token, {
              payment_method: {
                card: cardNumber,
                billing_details: {
                  name: self.payeeName
                }
              }
            })

            .then(function(paymentResult) {
              // console.log("paymentResult", paymentResult);

              if (paymentResult.error) {
                self.message = paymentResult.error.message;
                self.postPaymentFailed = true;
                self.onProcess = false;
                axios.post(
                  "/order/confirm-failed/",
                  {
                    data: {
                      id: tokenResult.data.orderId
                    }
                  },
                  { headers: orderServiceReqHeader }
                );
                /*.then(function(failureMessage) {
                    console.log("failureMessage", failureMessage);
                    const payload = {
                      email: this["auth/getUser"].email,
                      tags: `FAILED-PAYMENT`,
                    };
                    self.$store.dispatch(
                      "activeCampaign/ADD_CONTACT_TAGS",
                      payload,
                      { root: true }
                    );
                  });*/
              } else {
                // console.log("resultPayment succeeded");
                self.isEligible = true;
                self.message = `Payment ${paymentResult.paymentIntent.status}`;
                axios
                  .post(
                    "/order/confirm-success/",
                    {
                      data: {
                        id: tokenResult.data.orderId
                      }
                    },
                    { headers: orderServiceReqHeader }
                  )
                  .then(function(resp) {
                    // console.log("successMessage", resp);
                    self.successData = resp.data.data;
                    self.postPaymentSuccess = true;
                    self.onProcess = false;
                    const orderItem = resp.data.data.orderItem;
                    self.expiryTimestamp =
                      Date.now() -
                      60000 +
                      orderItem.meta.showExpiration.timeToWatchAfterPurchased * 3600 * 1000;
                    self.showExpiration = orderItem.meta.showExpiration;

                    self.$store.dispatch(
                      "play/UPDATE_ELIGIBLE_SESSIONS",
                      {
                        videoId: orderItem.productId,
                        venueId: orderItem.venueId,
                        expiryTimestamp: self.expiryTimestamp,
                        showExpiration: orderItem.meta.showExpiration
                      },
                      { root: true }
                    );

                    // self.$store.dispatch("play/GET_ELIGIBLE_SESSIONS", null, {
                    // 	root: true,
                    // });

                    // let justBuysData = self["homepage/getJustBuyVideo"];
                    // justBuysData.push(successData.data.data);

                    // self.$store.dispatch({
                    //   type: "homepage/SET_JUST_BUY_VIDEO",
                    //   data: justBuysData,
                    // });
                  })
                  .catch(err => {
                    console.log(err);
                    this.onProcess = false;
                  });
              }
            });
        })
        .catch(err => {
          console.log(err);
          this.postPaymentFailed = true;

          // console.log("postPaymentFailed", this.postPaymentFailed);
          this.onProcess = false;
          this.message = "Something went wrong, please try again later.";
        });
    },
    async watchMovie() {
      if (this.isFromDetailPage) {
        this.playOnClose = true;
        await this.$emit("closeFormSection", { play: true });
      } else {
        const path = `/shows/${this.hlItem.slug}`;
        if (this.$route.path !== path) this.$router.push(path);
        else this.$router.go(this.$router.currentRoute);
      }

      await this.$store.dispatch("play/GET_ELIGIBLE_SESSIONS", null, {
        root: true
      });
      this.clearData();
    },
    async closePayment() {
      this.clearData();
      await this.$emit("closeFormSection", { play: this.playOnClose });
      this.$store.dispatch({
        type: "homepage/SET_WATCH_PANEL_DISPLAY",
        data: null
      });
      this.$router.push("/");
    },
    formatDate(date) {
      return moment
        .utc(date)
        .local()
        .format("DD/MMM/YYYY HH:mm A");
    },
    formatPrice(currency, price) {
      if (!currency) {
        currency = "GBP";
      }
      return `${getSymbolFromCurrency(currency)} ${price}`;
    },
    clearData() {
      this.message = "";
      this.payeeName = "";
      this.numberValid = false;
      this.cvvValid = false;
      this.expiryValid = false;
      this.postPaymentSuccess = false;
      this.postPaymentFailed = false;
      this.onProcess = false;
      this.successData = {};
      this.errorMessageCardNumber = "";
      this.errorMessageCVC = "";
      this.errorMessageExpiry = "";
    },
    resolveCdnPath(path) {
      return urlHelper.resolveCdnPath(path);
    },
    clearCardData() {
      this.errorMessageCardNumber = "";
      this.errorMessageCVC = "";
      this.errorMessageExpiry = "";
      this.errorMessagePayeeName = "";
    }
  },
  async beforeDestroy() {
    this.clearData();

    await this.$emit("closeFormSection", { play: this.playOnClose });

    this.$store.dispatch({
      type: "homepage/SET_WATCH_PANEL_DISPLAY",
      data: null
    });
  },
  computed: {
    ...mapState({
      highlightIndex: state => state.homepage.highlightIndex
    }),
    ...mapGetters([
      "auth/isLoggedIn",
      "auth/getUser",
      "auth/getUserDetails",
      // "homepage/getJustBuyVideo",
      "purchaseSettings/getLifetime"
    ]),
    currency() {
      return getSymbolFromCurrency("GBP");
    },
    purchaseLifetimeInDays() {
      return Math.floor(this["purchaseSettings/getLifetime"].purchaseLifetimeInHours / 24);
    },
    watchingLifetimeInHours() {
      return this["purchaseSettings/getLifetime"].watchingLifetimeInHours;
    }
  },
  props: {
    hlItem: {
      type: Object,
      default: () => {}
    },
    isFromDetailPage: {
      type: Boolean,
      default: false
    }
  },
  components: {
    smallLoader
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.payment-container {
  .info-payment.payment {
    background: transparent;

    .process-screen {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 20px;
      z-index: 5;
    }
  }

  .post-payment {
    .content {
      display: unset;
      .event-sign,
      .event-detail {
        width: 100%;
        .sections .value {
          white-space: pre;
        }
      }
      .event-sign {
        & .event-image {
          width: 60px;
          height: 60px;
          background-image: url(~@/assets/images/icons/success-icon-gold.svg);
        }
        & .event-text {
          .title {
            color: $gold;
          }
        }
      }
    }
    &.hide-mobile {
      @include breakpoint("small") {
        display: none;
      }
    }
    &:not(.hide-mobile) {
      @include breakpoint("medium") {
        // display: none;
      }
    }
    .content {
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 991px) {
        flex-direction: column;
      }

      .event-sign {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        @media only screen and (max-width: 991px) {
          width: 100%;
        }

        .event-image {
          width: 60px;
          height: 60px;
          background-size: cover;
        }

        .event-text {
          .title {
            font-family: "Opensans-bold";
            font-size: 24px;
            line-height: 33px;
          }
          .note {
            font-family: "Playfair-regular";
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      .event-detail {
        padding: 17px 21px;
        border-radius: 15px;
        background-color: $medium-brown;
        margin-top: 15px;

        @media only screen and (max-width: 991px) {
          width: 100%;
        }

        .sections {
          display: flex;
          justify-content: space-between;
          margin: 8px 0;

          .label {
            font-family: "Opensans-regular";
            font-size: 14px;
            color: white;
            font-weight: 700;
          }
          .value {
            font-family: "Opensans-regular";
            font-size: 14px;
            color: white;
            font-weight: 400;
          }
        }
      }
    }

    &.success {
      .event-image {
        background-image: url(~@/assets/images/icons/success-icon.svg);
      }
    }

    &.failed {
      .content {
        padding-top: 65px;
        .event-sign {
          width: 100%;
        }
      }

      .fa-times-circle {
        color: $error-red;
        font-size: 69px;
        margin-bottom: 10px;
      }
    }

    .cta-buttons {
      display: flex;
      margin-top: 25px;

      .n8-btn {
        margin: 0 15px;
        display: flex;
        justify-content: space-between;
      }

      .md-button-content {
        display: flex;
        align-items: center;
        .icon-n8 {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .message {
    margin: 10px 0;
    font-size: 14px;
  }
  .error-msg {
    font-size: 12px;
    color: #ff6161;
  }
  .price-info {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .expiry-info {
    font-size: 14px;
    line-height: 1.2em;
  }

  /* MOBILE ONLY FIX */
  .info-payment.payment {
    @include breakpoint("small") {
      margin-top: 30px;
    }
  }

  .panel-switch-payment {
    & > .row {
      @include breakpoint("small") {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  .img-container {
    @include breakpoint("small") {
      background: transparent url(/img/thumbnail-default.png) center center no-repeat;
      background-size: cover;
      position: relative;
      padding-top: 56%;
      margin-bottom: 20px;
      margin-top: 20px;
      overflow: hidden;
    }

    img {
      @include breakpoint("small") {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>

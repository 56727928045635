<template>
  <div class="player-defered">
    <button class="btn back-button pl-0" @click="closePage">
      <i class="icon-n8 icon-back" /><span>Back</span>
    </button>

    <div class="play-button" @click="playVideo"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  methods: {
    ...mapActions({
      setMetaTags: "general/SET_META_TAGS",
      setVideoDeferred: "showDetails/SET_VIDEO_DEFERRED"
    }),
    closePage() {
      this.setVideoDeferred(false);
      this.$router.go(-1);
    },
    playVideo() {
      this.$emit("onPlayVideo");
    }
  }
};
</script>

<style lang="scss" scoped>
.player-defered {
  background-color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  .play-button {
    background-image: url(~@/assets/images/icons/play-button-trailer.svg);
    cursor: pointer;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

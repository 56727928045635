<template>
  <div class="description-container">
    <div class="row">
      <div class="col-12 title-container">
        <h1 class="page-heading gold mb-4">{{ detail.title }}</h1>
      </div>
      <div class="col-10 show-spec-container">
        <div class="row">
          <div class="mb-3 col-2 order-1 order-lg-1">
            <h2>Runtime</h2>
            <p>{{ detail.duration | humanize }}</p>
          </div>
          <div class="d-lg-flex mb-3 col-8 order-2 order-lg-2">
            <div v-if="detail.genre" class="mr-4 mb-3">
              <h2>Genre</h2>
              <div class="badge-container">
                <span class="badge badge-pill badge-grey">
                  {{ detail.genre }}
                </span>
              </div>
            </div>
            <div v-if="detail.tags && detail.tags.length > 0" class="mr-4 mb-3">
              <h2>Tags</h2>
              <div class="badge-container">
                <span class="badge badge-pill badge-grey" v-for="item in detail.tags" :key="item">{{
                  item
                }}</span>
              </div>
            </div>
            <div v-if="detail.categories && detail.categories.length > 0">
              <h2>Categories</h2>
              <div class="badge-container">
                <span
                  class="badge badge-pill badge-grey"
                  v-for="item in detail.categories"
                  :key="item"
                  >{{ item }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 col-lg-4 synopsis-container">
        <h2 class="d-none d-lg-block">Description</h2>
        <p ref="synopsis" class="synopsis-paragraph" :class="expanded ? 'expand' : ''">
          {{ detail.synopsis }}
        </p>
        <span v-if="showReadmore" class="cursor-pointer text-uppercase" @click="expandSynopsis">
          <u>
            {{ expanded ? "Read less" : "Read more" }}
          </u>
        </span>
      </div>
      <div class="col-md-5 starring-container" :class="displayFormLogin ? `col-lg-4` : `col-lg-4`">
        <div v-if="detail.director" class="mb-3 col-12 order-1 order-lg-1">
          <h2>Director</h2>
          <p>{{ detail.director }}</p>
        </div>
        <div
          v-if="detail.actors && detail.actors.length > 0"
          class="mb-3 col-12 order-2 order-lg-2"
        >
          <h2>Starring</h2>
          <p>
            <span v-for="(actor, index) in detail.actors" :key="actor"
              >{{ actor }}<span v-if="index != detail.actors.length - 1">, </span>
            </span>
          </p>
        </div>
      </div>
      <div class="col-md-5 genre-container" :class="displayFormLogin ? `col-lg-4` : `col-lg-4`">
        <div class="row">
          <div class="mb-3 col-12 order-3 order-lg-1">
            <h2>Runtime</h2>
            <p>{{ detail.duration | humanize }}</p>
          </div>

          <div class="d-lg-flex mb-3 col-12 order-4 order-lg-2">
            <div v-if="detail.genre" class="mr-4 mb-3">
              <h2>Genre</h2>
              <div class="badge-container">
                <span class="badge badge-pill badge-grey">
                  {{ detail.genre }}
                </span>
              </div>
            </div>
            <div v-if="detail.tags">
              <h2>Tags</h2>
              <div class="badge-container">
                <span class="badge badge-pill badge-grey" v-for="item in detail.tags" :key="item">{{
                  item
                }}</span>
              </div>
            </div>
          </div>
          <div v-if="detail.categories" class="mb-3 col-12 order-5 order-lg-3">
            <h2>Categories</h2>
            <div class="badge-container">
              <span
                class="badge badge-pill badge-grey"
                v-for="item in detail.categories"
                :key="item"
                >{{ item }}</span
              >
            </div>
          </div>
          <div v-if="detail.director" class="mb-3 col-12 order-1 order-lg-4">
            <h2>Director</h2>
            <p>{{ detail.director }}</p>
          </div>
          <div v-if="detail.actors" class="mb-3 col-12 order-2 order-lg-5">
            <h2>Starring</h2>
            <p>
              <span v-for="(actor, index) in detail.actors" :key="actor"
                >{{ actor }}<span v-if="index != detail.actors.length - 1">, </span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-4 text-md-right">
        <slot />
      </div>
      <div v-if="displayFormLogin">
        <md-dialog
          :md-active="displayFormLogin"
          class="registration-container hide-mobile"
          :class="membershipDisplay == 'PAYMENT' ? 'payment-dialog' : ''"
          v-if="!isMobile"
        >
          <md-dialog-actions class="login-popup-action">
            <span class="icon-close fa fa-times" @click="closeFormSection"></span>
          </md-dialog-actions>
          <md-dialog-content class="login-popup-content">
            <MembershipForm
              :hl-item="detail"
              :isActive="displayFormLogin"
              :isFromDetailPage="true"
              @closeFormSection="closeFormSection($event)"
            ></MembershipForm>
          </md-dialog-content>
        </md-dialog>

        <div
          v-if="isMobile"
          class="d-md-none content registration-container"
          :class="registrationContainerClass"
        >
          <i class="icon-close fa fa-times" @click="closeFormSection"></i>
          <MembershipForm
            :hl-item="detail"
            :isActive="displayFormLogin"
            :isFromDetailPage="true"
            @closeFormSection="closeFormSection($event)"
          ></MembershipForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import MembershipForm from "@/components/membership/index";
import { mapState } from "vuex";
import MobileView from "@/mixins/MobileView";

export default {
  mixins: [MobileView],
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    displayFormLogin: {
      type: Boolean,
      default: false
    },
    isPaymentFormActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: false,
      showReadmore: false
    };
  },
  watch: {
    detail(value) {
      if (value && value.synopsis) {
        this.$nextTick(() => {
          this.resolveSynopsisHeight();
        });
      }
    }
  },
  components: {
    MembershipForm
  },
  filters: {
    toHour(val) {
      // minutes to hour
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const label = hours >= 1 ? (minutes > 0 ? "hours" : "hour") : "minutes";

      return label === "hours"
        ? `${hours}.${minutes} ${label}`
        : label === "hour"
        ? `${hours} ${label}`
        : `${minutes} ${label}`;
    },
    humanize(numMinutes) {
      return moment.duration(numMinutes, "minutes").format("h [hrs], m [min]");
    }
  },
  computed: {
    registrationContainerClass() {
      return this.displayFormLogin ? "animated slideInRight" : "animated slideInLeft";
    },
    ...mapState({
      membershipDisplay: state => state.homepage.membershipDisplay
    })
  },
  methods: {
    async closeFormSection(arg1) {
      await this.$emit("closeFormSection", arg1);
    },
    expandSynopsis() {
      this.expanded = !this.expanded;
    },
    resolveSynopsisHeight() {
      const elementP = this.$refs.synopsis;
      if (elementP) {
        if (elementP.offsetHeight < elementP.scrollHeight) {
          this.showReadmore = true;
        } else this.showReadmore = false;
      } else this.showReadmore = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/mixins";
@import "~@/assets/sass/base/variables";
.registration-container {
  overflow-y: hidden !important;
}
.hide-mobile {
  @include breakpoint("small") {
    display: none !important;
  }
}
</style>

<template>
  <div class="expiry-info text-left">
    <div
      class="text-container cursor-pointer"
      @mouseover="toggleBoxInfo(true)"
      @mouseleave="toggleBoxInfo(false)"
    >
      <div class="desktop d-none d-lg-block">
        <div>
          <strong>Expires in:</strong> <i class="fas fa-info-circle"></i>
        </div>
        <div>{{ expiryHumanized }}</div>
      </div>
      <div class="mobile d-inline-flex d-lg-none text-right">
        <strong><i class="fas fa-info-circle"></i> Expires in:</strong>
        {{ expiryHumanized }}
      </div>
    </div>
    <transition name="fade">
      <div class="box-info" v-if="showBoxInfo">
        <div class="close d-block d-lg-none" @click="toggleBoxInfo(false)">
          <i class="fa fa-times"></i>
        </div>
        <div class="inner">
          <div class="gold"><strong>Expiry rules</strong></div>
          Rental includes {{ this.expiryRule.timeToWatchAfterPurchased }} to start watching this show and  {{ this.expiryRule.timeToWatchAfterPlayed }} to
          finish once the show has been initially started.
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export default {
  props: {
    showId: null,
    epochNow: {
      type: Number,
      default: 0,
    },
    showExpirationRule: {
      type: Object,
      default: function () {
        return {
          timeToWatchAfterPlayed: 0,
          timeToWatchAfterPurchased: 0,
        }
      }
    }
  },
  data() {
    return {
      showBoxInfo: false,
    };
  },
  computed: {
    expiryHumanized() {
      const session = this.$store.getters["play/checkEligibility"](this.showId || this.$route.params.id);

      if (session) {
        return moment
        .duration(session.expiryTimestamp - this.epochNow)
        .format("d [days], h [hrs], m [min]");
      }

      return "n/a";
    },
    expiryRule() {
      return {
        timeToWatchAfterPlayed: `${this.showExpirationRule.timeToWatchAfterPlayed} hours`,
        timeToWatchAfterPurchased: this.showExpirationRule.timeToWatchAfterPurchased < 24 ? `${this.showExpirationRule.timeToWatchAfterPurchased} hours`
          :`${Math.round(this.showExpirationRule.timeToWatchAfterPurchased/24)} days`
      }
    }
  },
  methods: {
    toggleBoxInfo(val = false) {
      this.showBoxInfo = val;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";

.expiry-info {
  color: white;
  font-size: 10px;
  margin: 5px 0;
  padding: 0 5px;
  position: relative;
  @include breakpoint("large") {
    font-size: 13px;
    margin: 20px 0;
    padding: 0 14px;
  }

  .text-container {
    .mobile {
      float: right;
    }
  }
}
.box-info {
  background: black;
  border: 1px solid $gold;
  width: 200px;
  position: absolute;
  bottom: 125%;
  z-index: 10;
  right: 0;
  @include breakpoint("large") {
    left: -10px;
    right: initial;
  }
  .close {
    background-color: transparent;
    color: white;
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    position: absolute;
    right: 0;
    top: 10px;
    opacity: 1;
    font-size: 14px;
  }
  .inner {
    font-size: 13px;
    padding: 18px 20px 20px;
  }
}
</style>

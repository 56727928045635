<template>
  <div class="login-container" :class="fromLoginPage ? 'background-login' : ''">
    <div class="panel-switch-login">
      <div v-if="!fromLoginPage" class="text-left info-title font-weight-bold">
        <span>Log In</span>
      </div>
      <h1 class="mb-4 font-weight-bold" v-else>Log In</h1>
      <validation-observer ref="loginForm" #default="{ invalid }">
        <div>
          <div class="form-group">
            <ValidationProvider
              name="Email address"
              rules="required|email"
              v-slot="{ errors, invalid, dirty, changed }"
            >
              <md-field :class="errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''">
                <label for="input-email">Email address *</label>
                <md-input
                  type="email"
                  class="form-control n8-text"
                  name="input-email"
                  aria-describedby="emailHelp"
                  placeholder="Email address"
                  v-model="email"
                  v-on:keyup.enter="logIn"
                />
                <span class="md-error">{{ errors[0] }}</span>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="form-group mb-0">
            <ValidationProvider
              name="Password"
              rules="required"
              v-slot="{ errors, invalid, dirty, changed }"
            >
              <md-field
                :md-toggle-password="false"
                :class="errors[0] || (invalid && dirty & changed) ? 'md-invalid' : ''"
              >
                <label for="input-password">Password *</label>
                <md-input
                  type="password"
                  class="form-control n8-text"
                  name="input-password"
                  aria-describedby="emailHelp"
                  placeholder="Password"
                  v-model="password"
                  v-on:keyup.enter="logIn"
                />
                <span class="md-error">{{ errors[0] }}</span>
              </md-field>
            </ValidationProvider>
            <span class="error" v-if="errorMsg">{{ errorMsg }}</span>
          </div>
          <div class="text-left lnkForgotPassword mt-3" @click="toggleDisplayState('FORGET')">
            <span>Forgot Your Password?</span>
          </div>

          <MdButton
            class="n8-btn n8-btn-gray light-theme md-raised"
            :class="invalid || loading ? `disabled` : ``"
            @click="logIn"
            v-on:keyup.enter="logIn"
            :disabled="loading"
          >
            <span class="btn-label">{{ loading ? "loading..." : "Log In" }}</span>
          </MdButton>
          <template v-if="fromLoginPage">
            <div class="line">or</div>
            <MdButton class="n8-btn n8-btn-black gold-theme md-raised" href="/signup">
              <span class="btn-label">Sign Up</span>
            </MdButton>
          </template>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
export default {
  name: "LoginForm",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    fromLoginPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      email: null,
      password: null,
      errorMsg: null,
      hasEmailInput: false,
      hasPasswordInput: false,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.general.dataLoading,
    }),
  },
  methods: {
    async logIn() {
      let data = {
        userName: this.email,
        password: this.password,
      };
      try {
        const success = await this.$refs.loginForm.validate();
        const test = this.$refs.loginForm;
        console.log(test);

        if (success) {
          this.$store.dispatch("general/SET_DATALOADING", true, {
            root: true,
          });
          await this.$store.dispatch("auth/signIn", data);
          if (this.$route.path == "/login") {
            this.$router.push("/");
          } else {
            //Assuming they login from homepage
            this.$store.dispatch({
              type: "homepage/SET_MEMBERSHIP_DISPLAY",
              data: "",
            });
            this.$store.dispatch({
              type: "homepage/SET_WATCH_PANEL_DISPLAY",
              data: false,
            });
            // assuming login from shows detail page
            this.$store.dispatch("showDetails/SHOW_FORM_LOGIN", false);
          }
          this.$store.dispatch("general/SET_DATALOADING", false, {
            root: true,
          });
        }
      } catch (err) {
        console.log("error: ", err.message);
        this.errorMsg = err.message;
        this.$store.dispatch("general/SET_DATALOADING", false, { root: true });
        setTimeout(() => {
          this.errorMsg = null;
        }, 8000);
      }
    },
    toggleDisplayState(selectedName) {
      if (!this.fromLoginPage) {
        this.$store.dispatch({
          type: "homepage/SET_MEMBERSHIP_DISPLAY",
          data: selectedName,
        });
      } else this.$router.push("/forgot-password");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";
.error {
  color: #ff6161;
  font-size: 12px;
}
.lnkForgotPassword {
  font-size: 10px;
  font-style: italic;
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.signuptext {
  font-size: 14px;
}
.lnkForgotPassword:hover {
  color: #ae8138 !important;
  cursor: pointer;
}
h1 {
  // color: $gold;
  font-family: "Opensans-bold";
  font-size: 22px;
}
.background-login {
  border-radius: 25px;
  padding: 40px;
  max-width: 410px;
  background-color: #24211f;
  border: 1px #ae8138 solid;

  @include breakpoint("medium") {
    width: 410px;
  }
}
.info-title {
  font-size: 22px;
}
</style>

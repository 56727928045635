<template>
  <div>
    <div class="change-password-container bg-change-password">
      <ChangePassword></ChangePassword>
    </div>
  </div>
</template>
<script>
import ChangePassword from "@/components/membership/ChangePassword";
export default {
  components: {
    ChangePassword
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
@import "~@/assets/sass/base/variables";
@import "~@/assets/sass/base/mixins";
.change-password-container {
  margin: 80px auto;
}
.bg-change-password {
  border-radius: 25px;
  padding: 40px;
  max-width: 410px;
  background-color: #24211f;
  border: 1px #ae8138 solid;

  @include breakpoint("medium") {
    width: 410px;
  }
}
</style>

<template>
  <div class="deleteaccount-container">
    <span class="description"
      >Please enter your email address and password to continue deleting your account.
      <br />Warning: <b>This action is not reversible. Your purchase history will be lost.</b></span
    >
    <validation-observer ref="deleteAccForm" #default="{ invalid }">
      <div class="form-group">
        <ValidationProvider
          name="Email address"
          rules="required|email"
          v-slot="{ errors, invalid, dirty, changed }"
        >
          <md-field :class="invalid && dirty & changed ? 'md-invalid' : ''">
            <label>Email address *</label>
            <md-input
              type="text"
              class="form-control n8-text"
              placeholder="Email address"
              v-model="email"
              autocomplete="off"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <div class="form-group">
        <ValidationProvider
          name="Password"
          rules="required"
          v-slot="{ errors, invalid, dirty, changed }"
        >
          <md-field
            :md-toggle-password="false"
            :class="invalid && dirty & changed ? 'md-invalid' : ''"
          >
            <label>Password *</label>
            <md-input
              type="password"
              class="form-control n8-text"
              placeholder="Password"
              v-model="password"
              autocomplete="new-password"
            />
            <span class="md-error">{{ errors[0] }}</span>
          </md-field>
        </ValidationProvider>
      </div>
      <MdButton
        class="n8-btn light-theme md-raised"
        @click="submit"
        :class="invalid ? `disabled` : ``"
      >
        <span class="btn-label">Delete account</span>
      </MdButton>
    </validation-observer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "DeleteAccount",
  props: ["userId"],
  data() {
    return {
      email: null,
      password: null
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      userInfo: "auth/getUserDetails",
      user: "auth/getUser"
    })
  },
  mounted() {},
  methods: {
    async submit() {
      const success = await this.$refs.deleteAccForm.validate();
      if (success) {
        if (this.email !== this.userInfo.email) {
          //Show validation error
          this.$store.dispatch("general/SET_NOTIFICATION", {
            showNotif: true,
            type: "error",
            message: "Incorrect username or password."
          });
          return;
        }
        let payload = {
          email: this.email,
          password: this.password,
          userId: this.userId
        };
        console.log("delete payload", payload);
        this.$store
          .dispatch("auth/deleteUserFromCognito", payload)
          .then(res => {
            console.log("result delete acc", res);
            if (res) {
              this.softDeleteFromDynamo();
            }
          })
          .catch(err => {
            console.log("delete error", err);

            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "error",
              message: err.message
            });
          });
      }
    },
    softDeleteFromDynamo() {
      this.$store
        .dispatch("auth/deleteUserFromDynamoDb", this.userId)
        .then(res => {
          if (res) {
            this.$store.dispatch("general/SET_NOTIFICATION", {
              showNotif: true,
              type: "success",
              message: "Success"
            });
            console.log("Success soft delete", res);
            this.$store.dispatch("auth/signOut");
            setTimeout(() => {
              this.$router.push("/");
            }, 3000);
          }
        })
        .catch(err => {
          console.log("error soft delete", err);
          this.$store.dispatch("general/SET_NOTIFICATION", {
            showNotif: true,
            type: "error",
            message: err
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.description {
  color: white;
}
</style>

<template>
  <div class="container-fluid" :class="bodyClass">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex flex-column min-vh-100">
            <div class="navbar-brand mt-3">
              <router-link to="/">
                <img src="/img/logo/boxoffice.svg" alt="Box Office VR" />
              </router-link>
            </div>
            <router-view class="flex-grow-1" :key="$route.fullPath" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    bodyClass() {
      return this.$route.meta.cssClass ? this.$route.meta.cssClass : "";
    }
  }
};
</script>

<style scoped>
.navbar-brand img {
  width: 60px;
  height: auto;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"changepassword-container"},[_c('h1',{staticClass:"font-22 text-gold font-weight-bold"},[_vm._v("Change Password")]),_vm._v(" Please enter your old password and new password "),_c('validation-observer',{ref:"changePasswordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"OldPassword","rules":"required","vid":"oldPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:invalid && dirty & changed ? 'md-invalid' : '',attrs:{"md-toggle-password":false}},[_c('label',[_vm._v("Old Password *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"password","placeholder":"Old password"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"NewPassword","rules":"required|minPassword:8","vid":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var dirty = ref.dirty;
var changed = ref.changed;
return [_c('md-field',{class:invalid && dirty & changed ? 'md-invalid' : '',attrs:{"md-toggle-password":false}},[_c('label',[_vm._v("New Password *")]),_c('md-input',{staticClass:"form-control n8-text",attrs:{"type":"password","placeholder":"New password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('span',{staticClass:"md-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('MdButton',{staticClass:"n8-btn light-theme md-raised mt-5",class:invalid ? "disabled" : "",on:{"click":_vm.submit}},[_c('span',{staticClass:"btn-label"},[_vm._v("Submit")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
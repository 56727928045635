import axios from "@/axios-instance"
import moment from "moment"

const orderServiceReqHeaders = {
  'x-api-key': process.env.VUE_APP_ORDER_API_KEY
};

const orders = {
  namespaced: true,
  state: {
    customerOrders: [],
    currentOrdersList: [],
    pastOrdersList: [],
  },
  actions: {
    GET_CUSTOMER_ORDERS({ commit, dispatch }, userId) {
      const url = `/order/customer/${userId}`;
      return new Promise((resolve, reject) => {
        dispatch("general/SET_DATALOADING", true, { root: true });
        axios
          .get(url, { headers: orderServiceReqHeaders })
          .then((resp) => {
            commit("setCurrentPastOrders", resp.data.data)
            dispatch("general/SET_DATALOADING", false, { root: true });
            resolve(resp.data);
          })
          .catch((error) => {
            dispatch("general/SET_DATALOADING", false, { root: true });
            reject(error);
          });
      });
    },
  },
  mutations: {
    setCurrentPastOrders(state, payload) {
      let currentOrders = [];
      let pastOrders = [];
      payload.forEach(order => {
        if (moment(order.validUntilDateTime).isBefore(new Date(), "day")) {
          pastOrders.push(order);
        } else if (moment(order.watchingExpiry).isBefore(moment())) {
          pastOrders.push(order);
        } else {
          currentOrders.push(order);
        }
      })
      state.currentOrdersList = currentOrders;
      state.pastOrdersList = pastOrders;
    },
  },
  getters: {
    getCustomerOrders(state) {
      return state.customerOrders;
    },
    getCurrentOrders(state) {
      return state.currentOrdersList;
    },
    getPastOrders(state) {
      return state.pastOrdersList;
    },
  },
};

export default orders;
